import React,{useState,useEffect} from 'react'
import {Container, Toast, Table, Button, Modal, Form, Row, Col, DropdownButton, Dropdown} from 'react-bootstrap'
import FooterComp from '../Footer';
import DashboardNavBar from '../../Dashboard/Navbar/Dashboard_Navabar'
import AddUserRoleAccessModalPopup from  './adduserrole';
import EditUserRoleAccessModalPopup from './edituserrole';
import { toast } from 'react-toastify';
import firebase from '../../service/firebaseConfig';

function UserSettings() {

    // ADMIN PORTAL
    let pageHeadingMappingObj = {};
    pageHeadingMappingObj["home"]="Price Update";
    pageHeadingMappingObj["listpurchase"]="Purchase/GRN";
    pageHeadingMappingObj["productDumpList"]="Product Dump";
    pageHeadingMappingObj["listStockTake"]="Stock Take";
    // pageHeadingMappingObj["inventoryListing"]="System Inventory";
    pageHeadingMappingObj["create_notifications"]="Notifications";
    pageHeadingMappingObj["offersMaster"]="Offers";
    pageHeadingMappingObj["stockConversionListing"]="Stock Conversion";
    pageHeadingMappingObj["grt"]="GRT Quality Analysis";
    pageHeadingMappingObj["purchaseorder"]="Purchase Order";
    pageHeadingMappingObj["popular"]="Popular Products";
    pageHeadingMappingObj["manageUsers"]="Admin";
    pageHeadingMappingObj["custom_portal_index_update"]="Internal";
    pageHeadingMappingObj["damagedQr"]="Damaged QR";


    let subPageMappingToMainPageObj={};
    //Price Update
    subPageMappingToMainPageObj["home"]={parent:"home",name:"Products Home"};
    subPageMappingToMainPageObj["addProd"]={parent:"home",name:"Add Products"};
    subPageMappingToMainPageObj["bulk"]={parent:"home",name:"Add Bulk Products"};
    subPageMappingToMainPageObj["priceupdates"]={parent:"home",name:"Purchase Price Update"};
    subPageMappingToMainPageObj["priceanalysis"]={parent:"home",name:"Price Analysis"};
    subPageMappingToMainPageObj["mtPrice"]={parent:"home",name:"Modern Trade"};
    subPageMappingToMainPageObj["priceupdatehistory"]={parent:"home",name:"Price Update History"};
    subPageMappingToMainPageObj["stockEdit"]={parent:"home",name:"Stock Avalability"};

    //Purchase/GRN
    subPageMappingToMainPageObj["listpurchase"]={parent:"listpurchase",name:"Purchase List"};
    subPageMappingToMainPageObj["addPurchaseDetails"]={parent:"listpurchase",name:"Add Purchase"};
    subPageMappingToMainPageObj["purchaseReport"]={parent:"listpurchase",name:"Purchase Report"};
    subPageMappingToMainPageObj["addsupplier"]={parent:"listpurchase",name:"Add Supplier"};
    subPageMappingToMainPageObj["financialBillListing"]={parent:"listpurchase",name:"Invoice Approval"};
    subPageMappingToMainPageObj["billOfMaterials"]={parent:"listpurchase",name:"Bill of Material"};
    subPageMappingToMainPageObj["SampleEntry"]={parent:"listpurchase",name:"Sample Entry"};
    subPageMappingToMainPageObj["workOrder"]={parent:"listpurchase",name:"Work Order"};

    // Damaged QR
     subPageMappingToMainPageObj["damagedQr"]={parent:"damagedQr",name:"Damaged QR"}

    //Product Dump
    subPageMappingToMainPageObj["productDumpList"]={parent:"productDumpList",name:"Product Dump List"};
    subPageMappingToMainPageObj["addProductDump"]={parent:"productDumpList",name:"Add Product Dump"};
    subPageMappingToMainPageObj["productDumpReport"]={parent:"productDumpList",name:"Product Dump Report"};

    //Stock Take
    subPageMappingToMainPageObj["listStockTake"]={parent:"listStockTake",name:"Stock Take List"};
    subPageMappingToMainPageObj["addStockTake"]={parent:"listStockTake",name:"Add Stock Take"};
    subPageMappingToMainPageObj["stockTakeReport"]={parent:"listStockTake",name:"Stock Take Report"};
    subPageMappingToMainPageObj["uploadstocktake"]={parent:"listStockTake",name:"Stock Take Upload"};

    //System Inventory
    // subPageMappingToMainPageObj["inventoryListing"]={parent:"inventoryListing",name:"Inventory List"};
    // subPageMappingToMainPageObj["inventoryListItems"]={parent:"inventoryListing",name:"Inventory Details"};
    // subPageMappingToMainPageObj["inventoryAnalysis"]={parent:"inventoryListing",name:"Inventory Analysis"};


    //Notifications
    subPageMappingToMainPageObj["create_notifications"]={parent:"create_notifications",name:"Create Notifications"};
    subPageMappingToMainPageObj["series_listing"]={parent:"create_notifications",name:"Notifications List"};

    //Offers
    subPageMappingToMainPageObj["offersMaster"]={parent:"offersMaster",name:"Create Offer"};
    subPageMappingToMainPageObj["offersListing"]={parent:"offersMaster",name:"Offers List"};

    //Stock Conversion
    subPageMappingToMainPageObj["stockConversionListing"]={parent:"stockConversionListing",name:"Home"};
    subPageMappingToMainPageObj["stockConversion"]={parent:"stockConversionListing",name:"Stock Conversion List"};
    subPageMappingToMainPageObj["addStockConversion"]={parent:"stockConversionListing",name:"Add Stock Conversion"};
    subPageMappingToMainPageObj["convertedList"]={parent:"stockConversionListing",name:"Package List"};

    //GRT Quality Analysis
    subPageMappingToMainPageObj["grt"]={parent:"grt",name:"Home"};
    subPageMappingToMainPageObj["grtproducts"]={parent:"grt",name:"Product List"};
    subPageMappingToMainPageObj["grtProductsAnalysis"]={parent:"grt",name:"Analyse"};

    //Purchase Order
    subPageMappingToMainPageObj["purchaseorder"]={parent:"purchaseorder",name:"Purchase Order List"};

    //Admin
    subPageMappingToMainPageObj["manageUsers"]={parent:"manageUsers",name:"Manage Users"};
    subPageMappingToMainPageObj["settings"]={parent:"manageUsers",name:"Manage User Roles"};

    //Internal Custom
    subPageMappingToMainPageObj["custom_portal_index_update"]={parent:"custom_portal_index_update",name:"Internal Custom"};

    //Popular
    subPageMappingToMainPageObj["popular"]={parent:"popular",name:"Popular"};

    //DELIVERY PORTAL
    let deliveryPageHeadingMappingObj = {};
    deliveryPageHeadingMappingObj["routing"]="Delivery Details";
    deliveryPageHeadingMappingObj["orders"]="Planned Orders";
    deliveryPageHeadingMappingObj["orderlist"]="Listing of Orders";
    deliveryPageHeadingMappingObj["productsummary"]="Product Summary";
    deliveryPageHeadingMappingObj["inventory-movements"]="Inventory";
    deliveryPageHeadingMappingObj["payment-management"]="payment-management";
    let deliverySubPageMappingToMainPageObj={};

    //Delivery Details
    deliverySubPageMappingToMainPageObj["routing"]={parent:"routing",name:"Routing"};
    deliverySubPageMappingToMainPageObj["delivery-summary"]={parent:"routing",name:"Delivery Summary"};
    deliverySubPageMappingToMainPageObj["vehicle-details"]={parent:"routing",name:"Vehicle Details"};
    deliverySubPageMappingToMainPageObj["driver-performance"]={parent:"routing",name:"Delivery Performance"};
    deliverySubPageMappingToMainPageObj["Fresh"]={parent:"routing",name:"Fresh"};
    deliverySubPageMappingToMainPageObj["transporter-payments"]={parent:"routing",name:"Transporter Payments"};


    //Order Details
    deliverySubPageMappingToMainPageObj["orders"]={parent:"orders",name:"Delivery Orders"};
    deliverySubPageMappingToMainPageObj["orderReport"]={parent:"orders",name:"Delivery Report"};
    deliverySubPageMappingToMainPageObj["addreturnadjustmentorders"]={parent:"orders",name:"Gate Pass / Customer Returns Orders"};
    deliverySubPageMappingToMainPageObj["viewreturnorders"]={parent:"orders",name:"View Returns Orders"};
    deliverySubPageMappingToMainPageObj["deliveryschedules"]={parent:"orders",name:"Delivery Schedules"};
    deliverySubPageMappingToMainPageObj["orderBulkPrint"]={parent:"orders",name:"Bulk Print"};
    deliverySubPageMappingToMainPageObj["orderPrintDetails"]={parent:"orders",name:"Print"};
    deliverySubPageMappingToMainPageObj["superkredit-orders"]={parent:"orders",name:"SuperKredit Orders"};
    deliverySubPageMappingToMainPageObj["superkredit-reports"]={parent:"orders",name:"Superkredit Reports"};

    //Listing of Orders
    deliverySubPageMappingToMainPageObj["orderlist"]={parent:"orderlist",name:"Order List"};
    deliverySubPageMappingToMainPageObj["returnedorders"]={parent:"orderlist",name:"Returned Orders"};
    deliverySubPageMappingToMainPageObj["orderdetails"]={parent:"orderlist",name:"Print"};
    deliverySubPageMappingToMainPageObj["bulkorderdetails"]={parent:"orderlist",name:"Bulk Print"};

    //Pre Delivery
    deliverySubPageMappingToMainPageObj["productsummary"]={parent:"productsummary",name:"Product Summary"};
    deliverySubPageMappingToMainPageObj["picking"]={parent:"productsummary",name:"Picking"};
    deliverySubPageMappingToMainPageObj["loosepackorders"]={parent:"productsummary",name:"Loose Pack"};

    //Inventory
    deliverySubPageMappingToMainPageObj["inventory-movements"]={parent:"inventory-movements",name:"Inventory"};
    deliverySubPageMappingToMainPageObj["transit-stock-transfer"]={parent:"inventory-movements",name:"Transit Transfer"};
    deliverySubPageMappingToMainPageObj["transit-stock-return"]={parent:"inventory-movements",name:"Transit Return"};
    deliverySubPageMappingToMainPageObj["stock-correction"]={parent:"inventory-movements",name:"Stock Correction"};
    deliverySubPageMappingToMainPageObj["stock-correction-report"]={parent:"inventory-movements",name:"Stock Correction Report"};
    deliverySubPageMappingToMainPageObj["stock-discrepancy"]={parent:"inventory-movements",name:"Stock Discrepancy"};
    deliverySubPageMappingToMainPageObj["inventory-reports"]={parent:"inventory-movements",name:"Report"};

    //Payment Management
    deliverySubPageMappingToMainPageObj["payment-management"]={parent:"payment-management",name:"Payment Management"};
    deliverySubPageMappingToMainPageObj["cash-management"]={parent:"payment-management",name:"Cash Management"};
    deliverySubPageMappingToMainPageObj["cheque-management"]={parent:"payment-management",name:"Cheque Management"};
    deliverySubPageMappingToMainPageObj["payment-pending"]={parent:"payment-management",name:"Payment Pending"};
    deliverySubPageMappingToMainPageObj["super-kredit"]={parent:"payment-management",name:"SuperKredit Management"};
    deliverySubPageMappingToMainPageObj["scratch-card-transactions"]={parent:"payment-management",name:"Scratch Card Transactions"};
    deliverySubPageMappingToMainPageObj["Rupifi"]={parent:"payment-management",name:"Rupifi"};
    deliverySubPageMappingToMainPageObj["Rupifiapprove"]={parent:"payment-management",name:"Rupifi Approval"};
    deliverySubPageMappingToMainPageObj["Finaleap"]={parent:"payment-management",name:"Finaleap"};




    //SUPPORT PORTAL
    let supportPageHeadingMappingObj = {};
    supportPageHeadingMappingObj["retailer_dashboard"]="Retailers"; //
    supportPageHeadingMappingObj["productsordered"]="Product";
    supportPageHeadingMappingObj["margin"]="Margin";
    supportPageHeadingMappingObj["shortsupplyorders"]="Place Short/Free Orders";
    supportPageHeadingMappingObj["orderdiscount"]="Apply Offers";
    supportPageHeadingMappingObj["routing"]="Routing";
    supportPageHeadingMappingObj["beatarea"]="Sales Team";
    supportPageHeadingMappingObj["salesAttendance"]="Attendance";
    supportPageHeadingMappingObj["dailysalesreport"]="Daily Sales Report";
    supportPageHeadingMappingObj["kpi"]="Sales KPI";
    supportPageHeadingMappingObj["society"]="Society";
    supportPageHeadingMappingObj["groupretailers_loadgroupretailerspage"]="Group Retailers";  //
    supportPageHeadingMappingObj["updates_bulkupdates"]="Bulk Updates"; //
    supportPageHeadingMappingObj["syncmasterdata"]="Sync Master Data";

    let supportSubPageMappingToMainPageObj={};
    //Retailers
    supportSubPageMappingToMainPageObj["retailer_dashboard"]={parent:"retailer_dashboard",name:"Retailers Dashboard"};
    supportSubPageMappingToMainPageObj["retailer_orders"]={parent:"retailer_dashboard",name:"Order Details"};
    supportSubPageMappingToMainPageObj["customerenquiries"]={parent:"retailer_dashboard",name:"Customer Enquiries"};
    supportSubPageMappingToMainPageObj["discrepancyorders"]={parent:"retailer_dashboard",name:"Discrepancy Orders"};
    supportSubPageMappingToMainPageObj["dcwiselapyogroceryorders"]={parent:"retailer_dashboard",name:"Other Orders"};
    supportSubPageMappingToMainPageObj["unapproved-retailers"]={parent:"retailer_dashboard",name:"UnApproved Retailers"};
    supportSubPageMappingToMainPageObj["approved-retailers"]={parent:"retailer_dashboard",name:"Approved Retailers"};
    supportSubPageMappingToMainPageObj["orderfeedback"]={parent:"retailer_dashboard",name:"Retailers Dashboard"};
    supportSubPageMappingToMainPageObj["retailer_dashboard"]={parent:"retailer_dashboard",name:"Order Feedback"};

    //Product
    supportSubPageMappingToMainPageObj["compareproductsales"]={parent:"productsordered",name:"Compare Product Sales"};
    supportSubPageMappingToMainPageObj["productsordered"]={parent:"productsordered",name:"Products Ordered"};
    supportSubPageMappingToMainPageObj["productsummary"]={parent:"productsordered",name:"Product Summary"};
    
    //Margin
    supportSubPageMappingToMainPageObj["margin"]={parent:"margin",name:"Margin"};


    // short/free orders
    supportSubPageMappingToMainPageObj["shortsupplyorders"]={parent:"shortsupplyorders",name:"Place Short/Free Order"};
    supportSubPageMappingToMainPageObj["freegiftorders"]={parent:"shortsupplyorders",name:"Free Gift Orders"};

    //Apply Offers
    supportSubPageMappingToMainPageObj["orderdiscount"]={parent:"orderdiscount",name:"Apply Offers"};
    supportSubPageMappingToMainPageObj["offers"]={parent:"offers",name:"Offers List"};

    //Routing
    supportSubPageMappingToMainPageObj["routing"]={parent:"routing",name:"Routing"};
    supportSubPageMappingToMainPageObj["vehiclerouting"]={parent:"routing",name:"Vehicle Routing"};
    supportSubPageMappingToMainPageObj["vehicleorderpriority"]={parent:"routing",name:"Vehicle Order Priority"};
    supportSubPageMappingToMainPageObj["deliveryorders"]={parent:"routing",name:"Delivery Orders"};

    // Sales Team
    supportSubPageMappingToMainPageObj["beatarea"]={parent:"beatarea",name:"Sales Team"};
    supportSubPageMappingToMainPageObj["salesman"]={parent:"beatarea",name:"SalesMan List"};
    supportSubPageMappingToMainPageObj["kpi"]={parent:"kpi",name:"Sales KPI"};
    supportSubPageMappingToMainPageObj["society"]={parent:"society",name:"Society"};
    supportSubPageMappingToMainPageObj["groupretailers_loadgroupretailerspage"]={parent:"groupretailers_loadgroupretailerspage",name:"Group Retailers"};
    supportSubPageMappingToMainPageObj["updates_bulkupdates"]={parent:"updates_bulkupdates",name:"Bulk Updates"};
    supportSubPageMappingToMainPageObj["syncmasterdata"]={parent:"syncmasterdata",name:"Sync Master"};

    //Attendance
    supportSubPageMappingToMainPageObj["salesAttendance"]={parent:"salesAttendance",name:"Sales Attendance"};

    //Daily Sales Report
    supportSubPageMappingToMainPageObj["dailysalesreport"]={parent:"dailysalesreport",name:"Daily Sales Report"};
    supportSubPageMappingToMainPageObj["SEdailyvisit"]={parent:"dailysalesreport",name:"Daily Sales Target"};



    const [selectedPortal,setSelectedPortal] = useState("AdminPortal");
    const [usersList,setUsersList] = useState([]);
    const [selectedUser,setSelectedUser] = useState({});
    const [rowIndex,setRowIndex] = useState();
    const [deletedUser,setDeletedUser] = useState({});
    const [addUserModalShow,setAddUserModalShow] = useState(false);
    const [editUserModalShow,setEditUserModalShow] = useState(false);
    const [pageHeadingMap,setPageHeadingMap]=useState(pageHeadingMappingObj);
    const [subPageToMainPageMap,setSubPageToMainPageMap] = useState(subPageMappingToMainPageObj);
    const [deliveryPageHeadingMap,setDeliveryPageHeadingMap]=useState(deliveryPageHeadingMappingObj);
    const [deliverySubPageToMainPageMap,setDeliverySubPageToMainPageMap] = useState(deliverySubPageMappingToMainPageObj);
    const [supportPageHeadingMap,setSupportPageHeadingMap]=useState(supportPageHeadingMappingObj);
    const [supportSubPageToMainPageMap,setSupportSubPageToMainPageMap] = useState(supportSubPageMappingToMainPageObj);


    useEffect(() => {
      console.log(selectedPortal);
      firebase.database().ref(`User_Role_Access/${selectedPortal}`).once('value').then((snapshot) => {
        if(snapshot.hasChildren()){
          const userdata = snapshot.val();
          console.log(userdata);
          let userTableData=[];

          Object.keys(userdata).map((userkey,index)=>{
            let ele=userdata[userkey];
            ele['usertype']=userkey;
            userTableData.push(ele);
          });
          console.log(userTableData);

          setUsersList(userTableData);
        }else{
          setUsersList([]);
        }
      });
    }, [selectedPortal])

    const UsersTableHeader = () => {
        return (
            <thead className="thead-light">
                <tr>
                    <td>User Role</td>
                    <td>Allowed Pages</td>
                    <td>Modifed By</td>
                    <td>Last modified date</td>
                    <td>Edit</td>
                </tr>
            </thead>
        );
    }

    const renderAllowedRoutes = (userAllowedRoutes) => {
      // console.log(userAllowedRoutes);

      if(selectedPortal=="AdminPortal"){
        let allowedPageHeadingMappingObj={};
        for(let i=0;i<userAllowedRoutes.length;i++){
          let route=userAllowedRoutes[i];
          if(subPageMappingToMainPageObj[route]){
            if(subPageMappingToMainPageObj[route].parent==route){
              allowedPageHeadingMappingObj[route]=pageHeadingMappingObj[route];
            }
          }
        }
        console.log("allowedPageHeadingMappingObj");
      
        console.log(allowedPageHeadingMappingObj);

        let rows = Object.keys(allowedPageHeadingMappingObj).map((mainPage,index)=>{
          // console.log(mainPage);
          return (
            <>
            <h5 style={{position: "relative",left: "-50px"}}>{allowedPageHeadingMappingObj[mainPage]}</h5>
            <ul>
              {userAllowedRoutes.map((route,rindex)=>{
                if(subPageMappingToMainPageObj[route]){
                  if(subPageMappingToMainPageObj[route].parent==mainPage){
                    return (
                      <li key={rindex}>
                        <span style={{position: "relative",left: "-30px"}}>{subPageMappingToMainPageObj[route].name}</span>
                      </li>
                    )
                  }
                }


              })}
            </ul>
            </>
          )
        });

        return (<><div>{rows}</div></>)
      }else if(selectedPortal=="DeliveryPortal"){

        let allowedPageHeadingMappingObj={};
        for(let i=0;i<userAllowedRoutes.length;i++){
          let route=userAllowedRoutes[i];
          if(deliverySubPageMappingToMainPageObj[route]){
            if(deliverySubPageMappingToMainPageObj[route].parent==route){
              allowedPageHeadingMappingObj[route]=deliveryPageHeadingMappingObj[route];
            }
          }
        }
        console.log("allowedPageHeadingMappingObj");
        console.log(allowedPageHeadingMappingObj);

        let rows = Object.keys(allowedPageHeadingMappingObj).map((mainPage,index)=>{
          // console.log(mainPage);
          return (
            <>
            <h5 style={{position: "relative",left: "-50px"}}>{allowedPageHeadingMappingObj[mainPage]}</h5>
            <ul>
              {userAllowedRoutes.map((route,rindex)=>{
                if(deliverySubPageMappingToMainPageObj[route]){
                  if(deliverySubPageMappingToMainPageObj[route].parent==mainPage){
                    return (
                      <li key={rindex}>
                        <span style={{position: "relative",left: "-30px"}}>{deliverySubPageMappingToMainPageObj[route].name}</span>
                      </li>
                    )
                  }
                }


              })}
            </ul>
            </>
          )
        });

        return (<><div>{rows}</div></>)
      }else if(selectedPortal=="SupportPortal"){
        let allowedPageHeadingMappingObj={};
        for(let i=0;i<userAllowedRoutes.length;i++){
          let route=userAllowedRoutes[i];
          if(supportSubPageToMainPageMap[route]){
            if(supportSubPageToMainPageMap[route].parent==route){
              allowedPageHeadingMappingObj[route]=supportPageHeadingMap[route];
            }
          }
        }
        console.log("allowedPageHeadingMappingObj");
        console.log(allowedPageHeadingMappingObj);

        let rows = Object.keys(allowedPageHeadingMappingObj).map((mainPage,index)=>{
          // console.log(mainPage);
          return (
            <>
            <h5 style={{position: "relative",left: "-50px"}}>{allowedPageHeadingMappingObj[mainPage]}</h5>
            <ul>
              {userAllowedRoutes.map((route,rindex)=>{
                if(supportSubPageToMainPageMap[route]){
                  if(supportSubPageToMainPageMap[route].parent==mainPage){
                    return (
                      <li key={rindex}>
                        <span style={{position: "relative",left: "-30px"}}>{supportSubPageToMainPageMap[route].name}</span>
                      </li>
                    )
                  }
                }


              })}
            </ul>
            </>
          )
        });

        return (<><div>{rows}</div></>)
      }
    }

    const UsersTableBody = () => {
      let rows = usersList.map((user, index) => {
          let userAllowedRoutes=user.allowed_routes?user.allowed_routes:[];
          return (
              <tr key={index}>
                  <td>{user.usertype}</td>
                  <td>{renderAllowedRoutes(userAllowedRoutes)}</td>
                  <td>{user.modified_by}</td>
                  <td>{user.last_modified_at}</td>
                  <td><Button variant="primary" onClick={()=>{setEditUserModalShow(true);setSelectedUser(user);setRowIndex(index); }}>Edit</Button></td>
              </tr>
          );
      });

      return (<><tbody>{rows}</tbody></>);
    }

    const UsersTable = () => {
      return (
          <table className="table" style={{marginTop:10}}>
              <UsersTableHeader />
              <UsersTableBody />
          </table>
      );
    }

    const addUser = (newUserData) => {
      console.log(newUserData);
      if(newUserData){
        let newUserList=[];
        newUserList.push(newUserData);

        setUsersList(usersList.concat(newUserList))
      }
    }

    const editUser = (updatedData) => {
      console.log('editUser');
      console.log(updatedData);
      let updatedUsersList = [...usersList];
      updatedUsersList[rowIndex]['usertype'] = updatedData['usertype'];
      updatedUsersList[rowIndex]['allowed_routes'] = updatedData['allowed_routes'];
      updatedUsersList[rowIndex]['last_modified_at'] = updatedData['last_modified_at'];
      updatedUsersList[rowIndex]['modified_by'] = updatedData['modified_by'];

      setUsersList(updatedUsersList);
    };

    return (
        <>
        <DashboardNavBar />
        <Container style={{paddingTop:'100px'}}>
            <Row>
                <Col xs={2}>
                <DropdownButton
                alignRight
                title={selectedPortal}
                id="dropdown-menu-select-portal"
                >
                <Dropdown.Item href="#" name="AdminPortal" onClick={(e)=>{e.preventDefault();setSelectedPortal(e.target.name)}}>Admin Portal</Dropdown.Item>
                <Dropdown.Item href="#" name="DeliveryPortal" onClick={(e)=>{e.preventDefault();setSelectedPortal(e.target.name)}}>Delivery Portal</Dropdown.Item>
                <Dropdown.Item href="#" name="SupportPortal" onClick={(e)=>{e.preventDefault();setSelectedPortal(e.target.name)}}>Support Portal</Dropdown.Item>
                <Dropdown.Item href="#" name="SuperKreditPortal" onClick={(e)=>{e.preventDefault();setSelectedPortal(e.target.name)}}>SuperKredit Portal</Dropdown.Item>
                </DropdownButton>
                </Col>
            </Row>

            {(selectedPortal=="AdminPortal" || selectedPortal=="DeliveryPortal" || selectedPortal=="SupportPortal" || selectedPortal=="SuperKreditPortal" )?(
              <>
              <center><h5 style={{paddingTop:30}}>Manage UserRoleAccess</h5></center>
              <Button variant="success" style={{float:'right'}} onClick={()=>{setAddUserModalShow(true); }}>Add User Role</Button>

              {(usersList.length>0) ? <UsersTable /> : (<div style={{margin:40}}>No user role present</div>) }

              {addUserModalShow && <AddUserRoleAccessModalPopup
                  show={addUserModalShow}
                  onHide={() => setAddUserModalShow(false)}
                  selectedPortal = {selectedPortal}
                  usersList = {usersList}
                  pageHeadingMap = {pageHeadingMap}
                  subPageToMainPageMap = {subPageToMainPageMap}
                  deliveryPageHeadingMap={deliveryPageHeadingMap}
                  deliverySubPageToMainPageMap={deliverySubPageToMainPageMap}
                  supportPageHeadingMap={supportPageHeadingMap}
                  supportSubPageToMainPageMap={supportSubPageToMainPageMap}
                  callback={addUser}
              />}

              {editUserModalShow && <EditUserRoleAccessModalPopup
                  show={editUserModalShow}
                  onHide={() => setEditUserModalShow(false)}
                  selectedPortal = {selectedPortal}
                  selectedUser = {selectedUser}
                  usersList = {usersList}
                  pageHeadingMap = {pageHeadingMap}
                  subPageToMainPageMap = {subPageToMainPageMap}
                  deliveryPageHeadingMap={deliveryPageHeadingMap}
                  deliverySubPageToMainPageMap={deliverySubPageToMainPageMap}
                  supportPageHeadingMap={supportPageHeadingMap}
                  supportSubPageToMainPageMap={supportSubPageToMainPageMap}
                  callback={editUser}
              />}
              </>
            ):null}
        </Container>

        <FooterComp />
        </>
    )
}

export default UserSettings
