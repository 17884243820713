import {ALL_REGIONS_LIST,REGION_BOM_A,TRANSPORT_FACTOR} from '../constants/allConstants';

const PriceCalculations = (priceFromDB, prodData, showMaterial, showWastage) => {
    let selectedRegion = REGION_BOM_A;
    const resObj = {};

    if(priceFromDB.material_cost_factor) {
        showMaterial = true;
    }
    resObj.material_cost = priceFromDB.material_cost;
    if (!showMaterial) {
        resObj.material_cost_factor = "0";
    } else {
        resObj.material_cost_factor=priceFromDB.material_cost_factor;
    } 
    
    if (!showWastage) {
        resObj.wastage_per = "0";
    } else {
        resObj.wastage_per=priceFromDB.wastage_per;
    }

    const isValid = (data) => {
        if (data === undefined || data === null || data === "" || data.length === 0) {
            return 0;
        }
        return data;
    };

    const parseToNo = (string) => {
        return parseFloat(isValid(string));
    };

    const getAbsFromPer = (data) => {
        return parseFloat(parseToNo(data) / 100);
    };

    const getRoundNo = (data) => {
        return parseToNo(data).toFixed(2);
    };

    const getRoundNoThree = (data) => {
        return parseToNo(data).toFixed(3);
    };

    
    const calcDealerPrice = () => {
        ALL_REGIONS_LIST.forEach(region => {
           calculateCharges(region);
        });
    };

    const calCashDiscountAmount = () => {
        resObj.cd_amount = priceFromDB.source_type && priceFromDB.source_type.toLowerCase()=="mill"?(getAbsFromPer(priceFromDB.cd_per) * parseToNo(priceFromDB.base_price)):0;
        resObj.cd_amount = getRoundNo(resObj.cd_amount);
    }

    const calMillTransportValue = () => {
        resObj.mill_transport_value = (priceFromDB.source_type && priceFromDB.source_type.toLowerCase()=="mill" && priceFromDB.mill_transport && priceFromDB.mill_transport.toLowerCase()=="excluded")?priceFromDB.mill_transport_value: 0;
        resObj.mill_transport_value = getRoundNo(resObj.mill_transport_value);
    }

    const calSecondaryPMCost = () => {
        // resObj.package_material_cost = getRoundNo(priceFromDB.package_material_cost);
        if (priceFromDB.material_cost_factor == 0 ) { //&& !showMaterial) {
            resObj.package_material_cost = getRoundNo(priceFromDB.package_material_cost);
        } else {
            resObj.package_material_cost = getRoundNo(resObj.material_cost);
        }
    }

    const calPrimaryPMCost = () => {
        resObj.primary_pm_cost = (priceFromDB.source_type && priceFromDB.source_type.toLowerCase()=="mill")?priceFromDB.primary_pm_cost: 0;
        resObj.primary_pm_cost = getRoundNo(resObj.primary_pm_cost);
    }

    const calApmcCharges = () => {
        resObj.apmc_charge = getAbsFromPer(priceFromDB.apmc_per) * parseToNo(priceFromDB.base_price);
        resObj.apmc_charge = getRoundNoThree(resObj.apmc_charge);
    };

    const calBuyingGstCharge = () => {
        resObj.buying_gst_charge = (parseToNo(priceFromDB.base_price) - parseToNo(resObj.cd_amount) + parseToNo(resObj.apmc_charge)) * getAbsFromPer(priceFromDB.gst_per);
        resObj.buying_gst_charge = getRoundNoThree(resObj.buying_gst_charge);
    }

    const calBuyingPriceExGst = () => {
        resObj.buying_price_ex_gst = parseToNo(priceFromDB.base_price) - parseToNo(resObj.cd_amount) + parseToNo(resObj.apmc_charge) + parseToNo(resObj.mill_transport_value)  + parseToNo(resObj.primary_pm_cost);
        resObj.buying_price_ex_gst = getRoundNo(resObj.buying_price_ex_gst);
    }

    const calFinalBuyingPrice = () => {
        resObj.final_buying_price = parseToNo(resObj.buying_price_ex_gst) + parseToNo(resObj.buying_gst_charge);
        resObj.final_buying_price = getRoundNo(resObj.final_buying_price);
    }


    const calGrossCost = () => {
        if(priceFromDB.source_type=="local_pick"){
            resObj.gross_cost = parseToNo(resObj.buying_price_ex_gst) + parseToNo(priceFromDB.local_transport) + parseToNo(priceFromDB.apmc_loading);
          }else{
            resObj.gross_cost = parseToNo(resObj.buying_price_ex_gst);
          }
          resObj.gross_cost = getRoundNo(resObj.gross_cost);

          if((prodData.weight).includes('gm')) {
            let weightVal = prodData.weight.split(' ')[0];
            resObj.gross_cost = resObj.gross_cost * (Number(weightVal) /1000 );
          }
    }
    const calLandingCost = () => {
        // if(priceFromDB.source_type=="local_pick"){
        //   resObj.landing_cost = parseToNo(resObj.buying_price_ex_gst) + parseToNo(priceFromDB.local_transport) + parseToNo(priceFromDB.apmc_loading);
        // }else{
        //   resObj.landing_cost = parseToNo(resObj.buying_price_ex_gst);
        // }
        // resObj.landing_cost = parseToNo(resObj.gross_cost) + parseToNo(resObj.conversion_charges) + parseToNo(resObj.material_cost) + parseToNo(resObj.wastage) + parseToNo(resObj.package_material_cost);
        resObj.landing_cost = parseToNo(resObj.gross_cost) + parseToNo(resObj.conversion_charges) + parseToNo(resObj.wastage) + parseToNo(resObj.package_material_cost);
        resObj.landing_cost = getRoundNo(resObj.landing_cost);
    }


    const calMaterialCost = () => {
        resObj.material_cost = parseToNo(resObj.material_cost_factor) / parseToNo(priceFromDB.lot_size);
        resObj.material_cost = getRoundNo(resObj.material_cost);
    };

    const calWastageCost = () => {
        // resObj.wastage = getAbsFromPer(resObj.wastage_per) * parseToNo(resObj.landing_cost);
        resObj.wastage = getAbsFromPer(resObj.wastage_per) * parseToNo(resObj.gross_cost);
        resObj.wastage = getRoundNo(resObj.wastage);
    };

    const calSZMarginCharges = () => {
        resObj.sz_margin_charges = getAbsFromPer(priceFromDB.sz_margin_per) * parseToNo(priceFromDB.base_price);
        resObj.sz_margin_charges = getRoundNo(resObj.sz_margin_charges);
    };

    const calConversionCharges = () => {
        resObj.conversion_charges = getAbsFromPer(priceFromDB.conversion_per) * parseToNo(resObj.gross_cost);
        resObj.conversion_charges = getRoundNo(resObj.conversion_charges);
    };

    const calExtraMarginCharges = () => {
        resObj.extra_margin_charges = getAbsFromPer(priceFromDB.extra_margin_per) * parseToNo(resObj.landing_cost);
        resObj.extra_margin_charges = getRoundNoThree(resObj.extra_margin_charges);
    };

    const calExtraMarginDistributorCharges = () => {
        resObj.dist_extra_margin_charges = getAbsFromPer(priceFromDB.extra_margin_dist_per) * parseToNo(resObj.landing_cost);
        resObj.dist_extra_margin_charges = getRoundNoThree(resObj.dist_extra_margin_charges);
    };


    const calculateCharges = (region) => {
        let extra_margin = getRoundNoThree(resObj.extra_margin_charges);
        if(priceFromDB['extra_margin_per_'+region]){
            extra_margin = getAbsFromPer(priceFromDB['extra_margin_per_'+region]) * parseToNo(resObj.landing_cost);
            extra_margin = getRoundNoThree(extra_margin);
        }
        let transport_factor = 0;
        if (TRANSPORT_FACTOR[selectedRegion] > 0) {
            transport_factor = (parseFloat(priceFromDB.transport_charge) / TRANSPORT_FACTOR[selectedRegion]) * TRANSPORT_FACTOR[region];
        }
        // resObj['total_price_wo_rounding_'+region] =  parseToNo(resObj.wastage) + parseToNo(resObj.material_cost) +parseToNo(resObj.conversion_charges) + parseToNo(resObj.landing_cost) + parseToNo(extra_margin) + parseToNo(priceFromDB.package_material_cost) + transport_factor;
        resObj['total_price_wo_rounding_'+region] =  parseToNo(resObj.landing_cost) + parseToNo(extra_margin) + transport_factor;
        resObj['total_price_'+region] = getRoundNo(resObj['total_price_wo_rounding_'+region]);
        resObj['total_bag_price_'+region] = (parseToNo(resObj['total_price_wo_rounding_'+region]) * parseToNo(priceFromDB.lot_size));
        resObj['total_bag_price_'+region] = getRoundNo(resObj['total_bag_price_'+region]); 

        if (parseToNo(prodData?.market_price) > 0 || prodData.long_description?.replace(/[^A-Z0-9]/ig, "").includes("Atta5kg")) {    
            resObj['dealer_price_'+region] = parseToNo(resObj['total_price_'+region]) + "";
        } else {
            resObj['dealer_price_'+region] = parseToNo(resObj['total_bag_price_'+region]) + "";
        } 
        if(region == 'A') {
            resObj['dealer_price'] = resObj.dealer_price_A;
            resObj['total_bag_price'] = resObj.total_bag_price_A;
            resObj['total_price'] = resObj.total_price_A;
            resObj['total_price_wo_rounding'] = resObj.total_price_wo_rounding_A;
            resObj['total_bag_price_wo_rounding'] = resObj.total_bag_price_wo_rounding_A;
        }
    }

    const calGstCharges = () => {
        resObj.gst_charge = getAbsFromPer(priceFromDB.gst_per) * parseToNo(resObj.total_price_wo_rounding);
        resObj.gst_charge = getRoundNoThree(resObj.gst_charge);
        resObj.gst_abs_per = getAbsFromPer(priceFromDB.gst_per);
    };

    const calFinalPrice = () => {
        resObj.final_price_wo_rounding = parseToNo(resObj.total_price_wo_rounding_A) + parseToNo(resObj.gst_charge);
        resObj.final_price = getRoundNo(resObj.final_price_wo_rounding);
    };

    const calFinalPriceExGst = () => {
        resObj.final_price_ex_gst = parseToNo(resObj.final_price_wo_rounding) - parseToNo(resObj.gst_charge);
        resObj.final_price_ex_gst = getRoundNo(resObj.final_price_ex_gst);
    };

    const calRetailerMargin = () => {
        resObj.retailer_margin_per = (parseToNo(prodData.market_price) - parseToNo(resObj.final_price_wo_rounding)) / parseToNo(resObj.final_price_wo_rounding);
        resObj.retailer_margin_per = getRoundNo(resObj.retailer_margin_per) *100.0;
    }

    const calFinalBagPrice = () => {
        resObj.final_bag_price = parseToNo(resObj.final_price_wo_rounding) * parseToNo(priceFromDB.lot_size);
        resObj.final_bag_price = getRoundNo(resObj.final_bag_price);
    };





    const calGrossRgm = () => {
        // resObj.gross_rgm = parseToNo(resObj.final_price_wo_rounding) - parseToNo(resObj.gst_charge) - parseToNo(resObj.buying_price_ex_gst);
        resObj.gross_rgm = parseToNo(resObj.final_price_wo_rounding) - parseToNo(resObj.gst_charge) - parseToNo(resObj.gross_cost);
        resObj.gross_rgm = getRoundNo(resObj.gross_rgm);
    }

    const calGrossGmPer = () => {
        resObj.gross_gm_per = (parseToNo(resObj.gross_rgm) / (parseToNo(resObj.final_price_wo_rounding) - parseToNo(resObj.gst_charge)))*100;
        resObj.gross_gm_per = getRoundNo(resObj.gross_gm_per);
    }

    const calNetRgm = () => {
        resObj.net_rgm = parseToNo(resObj.final_price_wo_rounding) - parseToNo(resObj.gst_charge) - parseToNo(resObj.landing_cost);
        resObj.net_rgm = getRoundNo(resObj.net_rgm);
    }

    const calNetGmPer = () => {
        resObj.net_gm_per = (parseToNo(resObj.net_rgm) / (parseToNo(resObj.final_price_wo_rounding) - parseToNo(resObj.gst_charge)))*100;
        resObj.net_gm_per = getRoundNo(resObj.net_gm_per);
    }

    calMaterialCost();

    calSecondaryPMCost();

    calApmcCharges();
    calMillTransportValue();
    calCashDiscountAmount();
    calPrimaryPMCost();
    // calSecondaryPMCost();
    calBuyingPriceExGst();
    calBuyingGstCharge();
    calFinalBuyingPrice();

    calGrossCost();
    // calMaterialCost();
    calWastageCost();
    calConversionCharges();
    calLandingCost();
    calSZMarginCharges();
    calExtraMarginCharges();
    calExtraMarginDistributorCharges();

    calcDealerPrice();

    calGstCharges();
    calFinalPrice();
    calRetailerMargin();
    calFinalPriceExGst();
    calFinalBagPrice();
    calGrossRgm();
    calGrossGmPer();
    calNetRgm();
    calNetGmPer();

    

    return resObj;
};

export default PriceCalculations;


