import React, { useEffect, useState, useRef } from 'react';
import {
  Navbar,
  Button,
  Form,
  Table,
  Modal,
  Container,
  Row,
  Col
} from "react-bootstrap";
import database from "../../service/firebaseConfig";
import { toast } from "react-toastify";
import ReactToPrint from "react-to-print";
import QRCode from "react-qr-code";
import DatePicker from "react-datepicker";
import NavBar from "../../Dashboard/Navbar/Dashboard_Navabar";
import {
  DELIVERY_BASE_URL,
  deliveryCommercialServer,
} from "../../service/servicesConfig";

import Spinner from 'react-bootstrap/Spinner';

const productsMasterDB = database.database().ref(`Product_Master_New/A`);

toast.configure({
  autoClose: 4000,
  draggable: true,
  pauseOnHover: true,
  position: toast.POSITION.TOP_LEFT,
});

function DamagedQrCode() {
  const [dateRange, setDateRange] = useState(new Date());
  const [noDataAvailable,setNoDataAvailable] = useState(true)
  const [fetchFromProductMaster, setFetchFromProductMaster] = useState(true);
  const [qrModal, setQrModal] = useState(false);
  const [qrRenderData, setQRrenderData] = useState([]);
  const [productIdWiseBrandNames, setProductIdWiseBrandNames] = useState({});
  const [printDamagedQrResults, setPrintDamagedQrResults] = useState([])
  const [printQrModalInternal, setPrintQrModalInternal]=useState(false)

  const CustomDateInput = React.forwardRef(({ value, onClick }, ref) => (
    <input
      ref={ref}
      value={value}
      onClick={onClick}
      style={{ border: "solid 1px black" }}
      readOnly
    />
  ));

  useEffect(() => {
    const fetchData = async () => {

      if (fetchFromProductMaster) {
        try {
          const productSnapshot = await productsMasterDB.once("value");
          const productItems = productSnapshot.val();
          const productIdWiseBrandName = {};

          if (productItems) {
            Object.entries(productItems).forEach(([productId, product]) => {
              if (product) {
                productIdWiseBrandName[productId] = product.brand || product.item_description1;
              }
            });
          }

          setProductIdWiseBrandNames(productIdWiseBrandName);
          setFetchFromProductMaster(false);
        } catch (error) {
          console.error("Error fetching product data:", error);
          toast.error("Failed to fetch product data");
        }
      }

      try {
        
        const formatddate = dateRange.toISOString().split("T")[0]
        const getUrl = `/api/getdamagedqrsbasedondate?grn_start_date=${formatddate}&grn_end_date=${formatddate}`;

        const response = await deliveryCommercialServer.get(getUrl);
        const QR_tableValues = response.data.data || [];

        if(response.data?.data)
        {
          setNoDataAvailable(false)
        }
     
        setQRrenderData(QR_tableValues);
      } catch (error) {
        console.error("Error fetching QR data:", error);
        toast.error("Failed to fetch QR data");
      }
    };

    fetchData();
  }, [dateRange, fetchFromProductMaster]);

  const DamageQRModal = ({ show, onHide }) => {

    const today = new Date();
    const formattedDate = today.toISOString().split('T')[0]

    const [itemId, setItemId] = useState('');
    const [date, setDate] = useState(formattedDate);
    const [quantity, setQuantity] = useState(0);

    const printRef = useRef();


    const damagedQrCodeResponse = async (itemId,quantity,date) => {
      try {

        const requestOptions = {
          method: "POST",
          redirect: "follow"
        };
        
        const response = await fetch(`${DELIVERY_BASE_URL}/api/add-damaged-qr?qty=${quantity}&item_id=${itemId}&date=${date}`,requestOptions);
        
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        
        const result = await response.json();
        
        if (result.success && result.data) {
          toast.success("QR Generated Successfully");
          setTimeout(() => {
            window.location.reload();
          }, 2000);
        } else {
          throw new Error(result.message || 'Unknown error occurred');
        }
      } catch (error) {
        console.error('Error:', error);
        toast.error(`Print QR Failed - ${error.message}`);
      }
    };

    return (
      <>
        <Modal show={printQrModalInternal} dialogClassName="custom-modal" onHide={() => setPrintQrModalInternal(false)}>
          <Modal.Header closeButton>
            <Modal.Title>Print QR Code</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Container ref={printRef}>
              {printDamagedQrResults.length > 0 && printDamagedQrResults.map((item, i) => (
                <div key={i} style={{ pageBreakAfter: "always", marginTop: "150px", marginLeft: "5px" }}>
                  <Row>
                    <Col>
                      <QRCode
                        value={item.qr_id}
                        size={500}
                        style={{
                          height: "auto",
                          maxWidth: "100%",
                          width: "auto",
                        }}
                        viewBox={`0 0 200 200`}
                      />
                    </Col>
                    <Col>
                    <h2 style={{ "font-size": "110px" }}>
                        <b>
                          <u>{productIdWiseBrandNames[item.item_id] ? productIdWiseBrandNames[item.item_id].toUpperCase() : productIdWiseBrandNames[item.item_id]}</u>
                        </b>
                      </h2>
                    </Col>
                  </Row>
                  <Row>
                  <h3 style={{ "font-size": "110px" }}>
                      {item.qr_id}
                  </h3>
                  </Row>
                </div>
              ))}
            </Container>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setPrintQrModalInternal(false)}>
              Close
            </Button>
            <style type="text/css" media="print">
            {
              "\
              @page { size: 50mm 25mm  }\
            "
            }
          </style>
            <ReactToPrint
              trigger={() => <Button>Print</Button>}
              content={() => printRef.current}
            />
          </Modal.Footer>
        </Modal>

        <Modal show={show} onHide={onHide}>
          <Modal.Header closeButton>
            <Modal.Title>Add QR Details</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Form.Group controlId="formItemID">
                <Form.Label>Item ID</Form.Label>
                <Form.Control 
                  required
                  type="text" 
                  value={itemId}
                  onChange={(e) => setItemId(e.target.value)}
                />
              </Form.Group>
    
              <Form.Group >
                        <Form.Label>Quantity</Form.Label>
                        <Form.Control 
                          required
                          type="number" 
                          value={quantity}
                          onChange={(e) => setQuantity(e.target.value)}
                        />
              </Form.Group>

                    <Form.Group >
                        <Form.Label>Date</Form.Label>
                        <Form.Control 
                        required
                          type="date" 
                          value={date}
                          onChange={(e) => {
                            const dateFormat = e.target.value
                            
                            setDate(dateFormat)
                          }}
                        />
                      </Form.Group>
    
              <Button 
                variant="primary"
                onClick={() => damagedQrCodeResponse(itemId,quantity,date)}
              >
                Generate QR
              </Button>
            </Form>
          </Modal.Body>
        </Modal>
      </>
    );
  };


  return (
    <div>
      <NavBar />
      
    {(noDataAvailable) ? 
      <>
      <div style={{
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100vw',
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        zIndex: 9999,
      }}>
        <Spinner
          animation="border"
          role="status"
          style={{ width: '5rem', height: '5rem', marginBottom: '1rem' }}
        />
        <div style={{ color: 'white', fontSize: '1.2rem' }}>
          Loading Data Please Wait...
        </div>
      </div>
      </>
      :
      <>
      <Navbar style={{ paddingTop: 100 }}>
        <DatePicker
          selected={dateRange}
          onChange={(date) => {
            setDateRange(date)
            setNoDataAvailable(true)}}
          minDate={new Date("2017/01/01")}
          maxDate={new Date()}
          dateFormat="dd/MM/yyyy"
          className="red-border"
          customInput={<CustomDateInput />}
        />

      <Button
        size="sm"
        style={{ margin: 10 }}
        variant="success"
        onClick={() => setQrModal(true)}
      >
        + Damaged QR
      </Button>

      </Navbar>



      <Form>
        <Table striped bordered>
          <thead>
          <tr>

            <td>
                <center>Date</center>
            </td>

              <td>
                <center>GRN number</center>
              </td>

              <td>
                <center>Item Id</center>
              </td>

              <td>
                <center>Item Name (Print)</center>
              </td>

              <td>
              <center>Quantity</center>
              </td>


              <td>
                <center>Print QR</center>
              </td>
            </tr>
          </thead>
          <tbody>
          {(qrRenderData && qrRenderData.length<=0) ? <><tr><td colSpan={6}><center>No Data Available</center></td></tr></> 
          :
          <>
          {qrRenderData && (qrRenderData.map((object)=>{
           
            const onPrintQRclick = (qr_object) => {
              setPrintDamagedQrResults(qr_object);
              setPrintQrModalInternal(true)
            }

            return (
            
            <>
            <tr>

            <td>
              <center>{object.value[0].grn_date}</center>
            </td>

            <td>
              <center>{object.key}</center>
            </td>

            <td>
              <center>{object.value[0].item_id}</center>
            </td>

            <td>
              <center>{productIdWiseBrandNames[object.value[0].item_id]}</center>
            </td>

            <td>
              <center>{object.value.length}</center>
            </td>


            <td>
              <center><Button onClick={()=>{onPrintQRclick(object.value,productIdWiseBrandNames[object.value[0].item_id])}}>Print QR</Button></center>
            </td>

            </tr>
            
            </>
            )

          }))}
          </>
        }
          </tbody>
        </Table>
      </Form>

      <DamageQRModal
        show={qrModal}
        onHide={() => setQrModal(false)}
      />
      </>}
    </div>
  );
}

export default DamagedQrCode;