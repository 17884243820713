import React, { useState } from 'react';
import database from '../../../service/firebaseConfig';
import moment from "moment";
import { ORDERING_BASE_URL } from '../../../service/servicesConfig';

const Retailers_Data = database.database().ref(`Retailer_Master_New`);
const Super_Coin_Transactions = database.database().ref(`Super_Coin_Transactions`);
const Superzop_Kredit_Retailers = database.database().ref(`Superzop_Kredit_Retailers`);

function RetailerSecondaryPhoneUpdate() {
    const [originalPhoneNo, setOriginalPhoneNo] = useState("");
    const [secondaryPhoneNo, setSecondaryPhoneNo] = useState("");

    const checkIfPhoneNumberExists = async (phoneNumber) => {
        try {
            const response = await fetch(
                ORDERING_BASE_URL+'/api/superzop/admin/getRetailerPhoneNumberDetails?phone_number='+phoneNumber
            );
            const data = await response.json();
            return data?.data || false;  // Return true if the phone number is registered
        } catch (error) {
            return false;  // Return false if there's an error
        }
    };

    const pushRetailer = async (e) => {
        e.preventDefault();

        if (!originalPhoneNo || !secondaryPhoneNo) {
            alert('Please enter both primary and secondary phone numbers.');
            return;
        }

        try {
            // Check if the secondary phone number is already present in old_phone
            const isPhoneRegistered = await checkIfPhoneNumberExists(secondaryPhoneNo);

            if (isPhoneRegistered) {
                alert('Number already registered previously');
                return;
            }
            
            const retailerSnapshot = await Retailers_Data.child(originalPhoneNo).once('value');
            const retailerDetails = retailerSnapshot.val();

            const secondaryPhoneNoSnapshot = await Retailers_Data.child(secondaryPhoneNo).once('value');
            const secondaryPhoneNumberDetails = secondaryPhoneNoSnapshot.val();

            if (!retailerDetails) {
                alert(`Retailer not found for phone number: ${originalPhoneNo}`);
                return;
            }

            if (retailerDetails.retailer_id === 0 || retailerDetails.verified === 'N') {
                alert('Retailer ID is not verified for: ' + originalPhoneNo);
                return;
            }

            const secondaryRetailerDetails = {
                phone: originalPhoneNo,
                secondary_number: secondaryPhoneNo,
                email: retailerDetails.email || "",
                shop_name: retailerDetails.shop_name || "",
                new_user: retailerDetails.new_user || "",
                retailer_id: retailerDetails.retailer_id || "",
                retailer_name: retailerDetails.retailer_name || "",
                store_series: retailerDetails.store_series || "",
                verified: retailerDetails.verified || "",
                last_updated: moment(new Date()).toString(),
                updated_from:"Admin Portal"

            };

            // const superZopSnapshot = await Superzop_Kredit_Retailers.child(originalPhoneNo).once('value');
            // const superZopDetails = superZopSnapshot.val();

            if(secondaryPhoneNumberDetails) {
                alert("Secondary Phone number already registered with another retailer");
                return;
            }

            if(retailerDetails?.secondary_number==originalPhoneNo){
                alert("Secondary Phone number already registered");
                return;
            }

            if (retailerDetails?.super_kredit) {
                alert('The phone number has kredit enabled. cannot update the secondary phone number for: ' + originalPhoneNo);
                return;
            }

            if (retailerDetails?.blacklist_flag === 'Y') {
                alert('The Retailer is blacklisted, cannot update the secondary phone number for: ' + originalPhoneNo);
                return;
            }

            await Retailers_Data.child(originalPhoneNo).set({
                ...retailerDetails,
                secondary_number: secondaryPhoneNo,
                last_updated: moment(new Date()).toString(),
                updated_from:"Admin Portal"
            });

            await Retailers_Data.child(secondaryPhoneNo).set(secondaryRetailerDetails);

            alert('Successfully updated secondary phone number: ' + secondaryPhoneNo);

            // Clear input fields after successful update
            setOriginalPhoneNo("");
            setSecondaryPhoneNo("");
        } catch (error) {
            alert('Error updating data: ' + error.message);
        }
    };

    return (
        <div className='container'>
            <form onSubmit={pushRetailer}>
                <h3 className='text-center'><b>Update Retailer Secondary Phone Number</b></h3>
                <div className='form-group'>
                    <label htmlFor='old_phone_number'>Enter Primary Phone Number</label>
                    <input
                        className='form-control'
                        id='old_phone_number'
                        value={originalPhoneNo} // Controlled input
                        // onChange={(e) => setOriginalPhoneNo(e.target.value)}
                        onChange={(e) => {
                            const input = e.target.value;
                            // Allow only digits and restrict to 10 characters
                            if (/^\d{0,10}$/.test(input)) {
                                setOriginalPhoneNo(input);
                            }
                        }}
                        maxLength="10"
                    />
                </div>
                <div className='form-group'>
                    <label htmlFor='new_phone_number'>Enter Secondary Phone Number</label>
                    <input
                        className='form-control'
                        id='new_phone_number'
                        value={secondaryPhoneNo} // Controlled input
                        // onChange={(e) => setSecondaryPhoneNo(e.target.value)}
                        onChange={(e) => {
                            const input = e.target.value;
                            // Allow only digits and restrict to 10 characters
                            if (/^\d{0,10}$/.test(input)) {
                                setSecondaryPhoneNo(input);
                            }
                        }}
                        maxLength="10"
                    />
                </div>
                <div>
                    <button type="submit" className="btn btn-info">Submit</button>
                </div>
            </form>
        </div>
    );
}

export default RetailerSecondaryPhoneUpdate;
