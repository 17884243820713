import React,{useEffect,useState} from 'react';
import { Form,Modal,Button,Table } from "react-bootstrap";
import { Select,MultiSelect } from '@mantine/core';
import database from '../../service/firebaseConfig';
import { toast } from "react-toastify";
import { LOGGED_USER_TYPE } from '../../constants/allConstants';
import moment from "moment";
const DB_REF = database.database().ref(`App_Version`);

const RegisterRetailer = ({updateRegRet,updateRetailerDD,...props}) => {
    
    let [Last_ret_id,setRetId] = useState(0);
    const [MapUrl, setMapUrl] = useState('');
    const [MT_Products,setMtProducts] = useState([]);
    const [allMTdata, setAllMTdata] = useState({});
    const [SelectedProducts,setSelectedProducts] = useState([]);

    // validation 
    const [updateFunctionsCalled, setUpdateFunctionsCalled] = useState(false);
    const [Phone,setPhone] = useState(0);
    const [defRetID,setDefRetID] = useState(0);
    const [ShopName, setShopName] = useState('');
    const [numStores,setNumStores] = useState(1);
    const [gstNumber, setGSTNumber] = useState('');
    const [isValidGST, setIsValidGST] = useState(true);
    const [fssaiNumber,setFssaiNumber] = useState('');
    const [isValidFsNum,setIsValidFsNum] = useState(true);
    const [Address1, setAddress1] = useState('');
    const [Address2, setAddress2] = useState('');
    const [areaList,setAreaList] = useState({});
    const [Cities, setCities]  = useState([]);
    const [stateList, setStateList] = useState([]);
    const [selectedCity,setSelectedCity] = useState('');
    const [area,setArea] = useState([]);
    const [selectedArea,setSelectedArea] = useState('');
    // multiple stores data
    const [stores, setStores] = useState(Array.from({ length: numStores }, () => ({
        store: '',
        phone: '',
        address1: '',
        address2: '',
        mapUrl: '',
        latitude: '',
        longitude: '',
        isValidUrl: true,
        isPhoneValid:true
      })));

      const [phoneErrors,setPhoneErrors] = useState([]);
    
    //   valid url true coz on page load jsx gives validation error

    useEffect(() => {
    fetchRetID();
    fetchProducts();
    fetchCityArea();
    fetchStateList();
    },[])


    let fetchRetID =  async () => {
        try{
          
          const snapshot = await DB_REF.once("value");
          const retVal = Object.values(snapshot.val());
           let defaultPhone = 0;
           let default_ret_id = 0;
           let mt_id = 0;
          retVal.forEach((item)=>{
                mt_id = item.last_retailer_id_mt;
                default_ret_id = Number(mt_id) + 1;
                defaultPhone = "99000"+default_ret_id;
          })        
          
         setRetId(default_ret_id);
         setDefRetID(default_ret_id);
         setPhone(defaultPhone);
        //  stae updates are async, so 
         updateStoresWithPhone(defaultPhone);
  
         } catch (error){
          console.error('Error fetching retailer details:', error);
         
      }
    }


    
    const fetchProducts = async () => {
        const DB_REF_mtProds = database.database().ref(`Product_Master_New/O`);
        try{
          
            const snapshot = await DB_REF_mtProds.once('value');
            const prodVal = snapshot.val();
            setAllMTdata(prodVal)

            let Mt_products = Object.values(prodVal);
           
               
                let productData = [{ label: "", value: ""}];
                Mt_products.forEach((item) => {
                  
                    const product = {
                        label: `${item.item_id}-${item.item_description1}-${item.brand}`,
                        value: item.item_id
                    }
                    productData.push(product);
                
                })
                setMtProducts(productData);
           
        } 
        catch (error){
            console.error('Error fetching Product details:', error);
        }
    };

    let fetchStateList = async () =>{
      try {
        const stateListDBRef   = database.database().ref('State_List');
        const snapshotState = await stateListDBRef.once("value");
        const stateListVal = snapshotState.val();

        const statesArray = Object.keys(stateListVal).map(stateKey => ({
          label: stateListVal[stateKey].State,
          value: stateKey,
        }));
    
        setStateList(statesArray);
      } catch (error){
        console.error('Error fetching State_List details:', error);
      }
    }

    let fetchCityArea =  async () => {
        try{
          const CityAreaDbRef = database.database().ref(`City_Area`)
          const snapshot = await CityAreaDbRef.once("value");
          const areaList = snapshot.val();

           setAreaList(areaList);
        
            let cities = Object.keys(areaList).map((city) => ({
            label: `${city}`,
            value: city
        }));
          setCities(cities)
          
         } catch (error){
          console.error('Error fetching city-area details:', error);
         
      }
    }


    const updateStoresWithPhone = (phone) => {
      const newStores = Array.from({ length: numStores }, () => ({
        store: '',
        // phone: phone, // Use the updated phone value here
        phone: phone, 
        address1: '',
        address2: '',
        mapUrl: '',
        latitude: '',
        longitude: '',
        isValidUrl: true,
        isPhoneValid:true
      }));
      setStores(newStores);
    }
   

     // Render the form for each store
  const renderStoresForm = () => {
    return Array.from({ length: numStores }).map((_, index) => (
      <div key={index}>
        <h4 className="text">Store {index + 1}</h4>
        <div className='row'>
        <div className="mb-3 col-6">
            <label htmlFor={`phone${index}`} className="form-label">Phone</label>
                <input
                    type="number"
                    // className={`form-control ${stores[index].isPhoneValid }`}
                    className={`form-control ${stores[index].isPhoneValid ? 'is-valid' : 'is-invalid'}`}
                    value={stores[index].phone}
                    // placeholder='Required 10 digits mobile number'
                    maxLength={10}
                    onChange={(e) => handleStoreChange(index, 'phone', e.target.value)}
                />
  {phoneErrors[index] && <div className="invalid-feedback">{phoneErrors[index]}</div>}

        </div>
        <div className="mb-3 col-6">
          <label htmlFor={`phone${index}`} className="form-label">
            Retailer ID
          </label>
          <input
            type="text"
            className="form-control"
            value={Last_ret_id++}
            readOnly 
          />
        </div>
        </div>
        <div className="mb-3">
            <label htmlFor={`store${index}`} className="form-label">Store Name</label>
                <input
                    type="text"
                    className="form-control"
                    value={stores[index].store}
                    onChange={(e) => handleStoreChange(index, 'store', e.target.value)}
                />
        </div>
        <div className="mb-3">
            <label htmlFor={`store${index}`} className="form-label">Retailer Name</label>
                <input
                    type="text"
                    className="form-control"
                    value={stores[index].retailer_name}
                    onChange={(e) => handleStoreChange(index, 'retailer_name', e.target.value)}
                />
        </div>
        <div className='row'>
            <div className='col-6'>
                <div className='mb-3'>  
                <label for="address1" class="form-label">Address Line 1</label>
                    <input
                     type="text"
                     class="form-control"
                    value={stores[index].address1}
                    onChange={(e) => handleStoreChange(index, 'address1', e.target.value)}
                     />
             </div>
            </div>
            <div className='col-6'>
                <div className='mb-3'> 
                <label for="address2" class="form-label">Address Line 2</label>               
                    <input
                     type="text"
                    class="form-control"
                    value={stores[index].address2}
                    onChange={(e) => handleStoreChange(index, 'address2', e.target.value)}
                    />
                </div>
            </div>
        </div>

        <div className='row'>
            <div className='col-6'>
                <div className='mb-3'>  
                <label for="pincode" class="form-label">Pincode</label>
                    <input
                     type="number"
                    //  class="form-control"
                     className={`form-control ${stores[index].isPincodeValid === false ? 'is-invalid' : ''}`}
                     value={stores[index].pincode}
                     onChange={(e) => handleStoreChange(index, 'pincode', e.target.value)}
                     />
                     {stores[index].isPincodeValid === false && (
                      <div className="invalid-feedback">Pincode must be exactly 6 digits.</div>
                     )}
             </div>
            </div>
            <div className='col-6'>
                <div className='mb-3'> 
                <label for="sub_area" class="form-label">Sub Area</label>
                    <input
                     type="text"
                    class="form-control"
                    value={stores[index].sub_area}
                    onChange={(e) => handleStoreChange(index, 'sub_area', e.target.value)}
                    />
                </div>
            </div>
        </div>

       
        <div className="mb-3">
            <label htmlFor={`mapUrl${index}`} className="form-label">Map URL</label>
                <input
                    type="text"
                    className={`form-control ${stores[index].isValidUrl ? 'is-valid' : 'is-invalid'}`}
                    value={stores[index].mapUrl}
                    onChange={(e) => handleStoreChange(index, 'mapUrl', e.target.value)}
                />
            {!stores[index].isValidUrl && (
                <div className="invalid-feedback">Please enter a valid Google Maps URL.</div>
            )}
        </div>
        <div className='row'>
        <div className='col-6'>
  <div class="mb-3">
    <label htmlFor={`city${index}`} class="form-label">Select City</label>
    <Select
      id={`city${index}`}
      // label="Select City"
      placeholder="Pick one"
      data={Cities}
      value={stores[index].selectedCity} 
      onChange={(value) => handleStoreChange(index, 'selectedCity', value)} 
      searchable
      maxDropdownHeight={400}
      clearable
    />
  </div>
  
</div>
<div className='col-6'>
  {stores[index].selectedCity && (
    <div class="mb-3">
      <label htmlFor={`area${index}`} class="form-label">Select Area</label>
      <Select
        id={`area${index}`}
        // label="Select Area"
        placeholder="Pick one"
        data={area}
        value={stores[index].selectedArea} 
        onChange={(value) => handleStoreChange(index, 'selectedArea', value)} 
        searchable
        maxDropdownHeight={400}
        clearable
      />
    </div>
  )}
</div>
<div className='col-6'>
<div class="mb-3">
    <label htmlFor={`state${index}`} class="form-label">Select State</label>
    <Select
      id={`state${index}`}
      placeholder="Pick one"
      data={stateList}
      value={stores[index].selectedstate} 
      onChange={(value) => handleStoreChange(index, 'selectedState', value)} 
      searchable
      maxDropdownHeight={400}
      clearable
    />
</div>
</div>
</div>

        
      </div>
    ));
  };
   
  // check duplicate phone
  const isDuplicatePhone = (stores, phone) => {
    return stores.some((store) => store.phone === phone);
  };

  

  // Function to handle changes in store information
  const   handleStoreChange = (index, field, value) => {
  
    // if no onchange function will not be called , n gives valid error,--- fix later, using temp fix.

    const updatedStores = [...stores];
  updatedStores[index] = {
    ...updatedStores[index],
    [field]: value,
  };
   
    if (field === 'mapUrl') {
      clearTimeout(updatedStores[index].debounceTimeout);
  
      updatedStores[index].debounceTimeout = setTimeout(() => {
        const isValid = validateGoogleMapsUrl(value);
        updatedStores[index].isValidUrl = isValid;        
        setStores(updatedStores);
      }, 300);

    } else if (field === 'selectedCity') {
        
        if (value) {
          let cityArea = Object.values(areaList[value]);
          let cityAreas = cityArea.map((area) => ({
            label: `${area}`,
            value: area
          }));
          setArea(cityAreas);
      
          updatedStores[index].city = value; 
        }
        setStores(updatedStores);
      } else if (field === 'selectedArea') {
       
        updatedStores[index].area = value;
        setStores(updatedStores);
      } 
      else if (field === 'phone') {
        
        // Clear debounce timer for input
        clearTimeout(updatedStores[index].debounceTimeout);
      
        // Check phone number validity and uniqueness
        const isPhoneNumberAlreadyUsed = updatedStores
          .filter((store, i) => i < index && store.phone === value)
          .length > 0;
        const isPhoneNumberInvalidLength = value.length !== 10;
      
        let newPhoneErrors = [...phoneErrors];
      
        if (isPhoneNumberInvalidLength) {
          newPhoneErrors[index] = 'Phone number must be 10 digits';
          setPhoneErrors(newPhoneErrors);
          updatedStores[index].phone = value;
          updatedStores[index].isPhoneValid = false;
        } else if (isPhoneNumberAlreadyUsed) {
          newPhoneErrors[index] = 'Phone number already added';
          setPhoneErrors(newPhoneErrors);
          updatedStores[index].phone = value;
          updatedStores[index].isPhoneValid = false;
        } 
        else {
          newPhoneErrors[index] = '';
          setPhoneErrors(newPhoneErrors);
          updatedStores[index].phone = value;
          updatedStores[index].isPhoneValid = true;
        }
      
       
      
        setStores(updatedStores);
      }
      
      else if (field === 'store') {
        updatedStores[index].store = value;
        setStores(updatedStores);
      } else if(field == 'retailer_name'){
        updatedStores[index].retailer_name = value;
        setStores(updatedStores);
      } else if(field == 'pincode'){
        updatedStores[index].pincode = value;
        updatedStores[index].isPincodeValid = /^\d{6}$/.test(value);
        setStores(updatedStores);
      } else if(field == 'sub_area'){
        updatedStores[index].sub_area = value;
        setStores(updatedStores);
      } else if(field == 'selectedState'){
        updatedStores[index].state = value;
        setStores(updatedStores);
      } else {
        setStores(updatedStores);
      }
  
    
  };
  
    
const updateNumStores = (newNumStores) => {
  const parsedNumStores = parseInt(newNumStores, 10);
  const numStoresToUpdate = parsedNumStores < 1 ? 1 : parsedNumStores;

  setNumStores(numStoresToUpdate);

  setStores((prevStores) => {
    const newStores = Array.from({ length: numStoresToUpdate }, (_, index) => {
      if (index < prevStores.length) {
        // If the store already exists, keep its data, including the phone number
        return {
          ...prevStores[index],
        };
      } else {
        // If it's a new store, add default data and the phone number
        return {
          address1: '',
          address2: '',
          mapUrl: '',
          latitude: '',
          longitude: '',
          isValidUrl: true,
          isPhoneValid: true,
          phone: index == 0? `${Number(Phone)}`:`${Number(Phone) + index}`,
          // phone: '',
        };
      }
    });

    return newStores;
  });
};



    const renderForm = () => {
         
        return(
        <form>
           <div className="row">
                </div>
           <div className="mb-3">


        <label htmlFor="numStores" className="form-label">
             Number of Stores
        </label>
        <input
        type="number"
        className="form-control"
        id="numStores"
        name="numStores"
        value={numStores}
        onChange={(e) => updateNumStores(e.target.value)}
        min="1"
        required
        />
        </div>

       { renderStoresForm()}

       
    <div className='row'>
        
            <div className='col-6'>
                <div class="mb-3">
                    <label for="ownerName" class="form-label">Owner Name</label>
                    <input type="text" class="form-control" id="ownerName" name="ownerName"  placeholder="optional" />
            </div>
        </div>
    </div>
       
       <div className='row'>
            <div className='col-6'>
                <div class="mb-3">
                    <label for="fssaiNumber" class="form-label">FSSAI Number</label>
                    <input
                        type="text"
                        className={`form-control ${isValidFsNum ? 'is-valid' : 'is-invalid'}`}
                        id="fssaiNumber"
                        name="fssaiNumber"
                        placeholder="required 14 digit no."
                        value={fssaiNumber}
                        onChange={handleFssaiChange}
                    />
                    {!isValidFsNum && (
                        <div className="invalid-feedback">Please enter a valid Fssai number.</div>
                    )}
                </div>
            </div>
            <div className='col-6'>
                <div class="mb-3">
                    <label for="gstNumber" class="form-label">GST Number</label>
                    <input
                        type="text"
                        className={`form-control ${isValidGST ? 'is-valid' : 'is-invalid'}`}
                        id="gstNumber"
                        name="gstNumber"
                        placeholder="required 15 digit no."
                        value={gstNumber}
                        onChange={handleGstChange}
                    />
                    {!isValidGST && (
                        <div className="invalid-feedback">Please enter a valid GST number.</div>
                    )}
                </div>
            </div>
            
       </div>
       
    <div className="mb-3">
        <label htmlFor="multiSelect" className="form-label">
            Select Products
        </label>
        <MultiSelect
            placeholder="All"
            data={MT_Products}
            value={SelectedProducts}
            onChange={setSelectedProducts}
            searchable
            maxDropdownHeight={400}
            dropdownPosition="flip"
            clearable
            >
        </MultiSelect> 
                         
    </div>     
      </form>
        )
    }

    const validateGST = (value) => {
        if (value.length === 15) {
            // const gstPattern = /^[23]\d{1}[A-Z]{5}\d{4}[A-Z]{1}[A-Z\d]{1}[Z]{1}[A-Z\d]{1}$/;
            const gstPattern = /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z][A-Z1Z][0-9A-Z]{2}$/
            const isValid = gstPattern.test(value);
            setIsValidGST(isValid);
        } else {
            setIsValidGST(false);
        }
    };

    const handleGstChange = (event) => {
        const { name, value } = event.target;
        setGSTNumber(value);
        // using debouncetimeout as prop instead of making a variable: for unexpected behaviour in react/ prevention
        clearTimeout(handleGstChange.debounceTimeout);
        handleGstChange.debounceTimeout = setTimeout(() => {
            validateGST(value);
        }, 300);
    };

   
    function validateFSSAI(fssaiNumber) {
     
      if (fssaiNumber.length !== 14) {
          // console.log("FSSAI number should be 14 characters long");
          return false;
      }
      if (!/^\d{14}$/.test(fssaiNumber)) {
          // console.log("FSSAI number should contain only digits (0-9)");
          return false;
      }
      
      if (fssaiNumber.charAt(0) !== '1' && fssaiNumber.charAt(0) !== '2') {
          // console.log("FSSAI number should start with '1' or '2'");
          return false;
      }
      
      const yearDigits = fssaiNumber.substr(3, 2);
      const currentYear = new Date().getFullYear() % 100; 
      const year = parseInt(yearDigits, 10);
  
      // range check
      
      if (!((year >= 70 && year <= 100) || (year >= 0 && year <= currentYear))) {
          // console.log("Invalid year in FSSAI number");
          return false;
      }
      return true;
  }
  

    const handleFssaiChange = (event) => {
        const { name, value } = event.target;
        setFssaiNumber(value);
        clearTimeout(handleFssaiChange.debounceTimeout);
        handleFssaiChange.debounceTimeout = setTimeout(() => {
            const isValid = validateFSSAI(value);
            setIsValidFsNum(isValid);
        }, 300);
    };
    
    

    function validateGoogleMapsUrl(url) {
        const googleMapsPattern = /^https?:\/\/www\.google\.com\/maps\/.*$/;    
        if (googleMapsPattern.test(url)) {
            return true; 
        } else {
            return false; 
        }
    }


      function extractLatLongFromMapUrl(mapUrl) {
        try {
            // const regex = /@(-?\d+\.\d+),(-?\d+\.\d+)/;
            const regex = /@(?!\d+z)(-?\d+\.\d+),(-?\d+\.\d+)/;
            const match = mapUrl.match(regex);
            if (match && match.length === 3) {
             const latitude = parseFloat(match[1]);
             const longitude = parseFloat(match[2]);
              if (!isNaN(latitude) && !isNaN(longitude)) {
                return { latitude, longitude };
              } else {
                console.error('No valid latitude and longitude found in the URL');
                return null;
              }
            }else {
              console.error('No valid latitude and longitude found in the URL');
              return null;
            }
        } catch (error) {
          console.error('Error extracting latitude and longitude:', error);
          return null;
        }
      }
  

  const handleSaveProducts = async () => {
    const isUniqueShopName = (storeName, stores, index) => {
      return stores.findIndex((store, i) => {
        return store.store && store.store.trim() === storeName.trim() && i !== index;
      }) === -1;
    };

    const isUniquePhoneNumber = (phone, stores, index) => {
      return stores.findIndex((store, i) => {
        return store.phone && store.phone.trim() === phone.trim() && i !== index;
      }) === -1;
    };

    // Check shop name and phone number uniqueness
    const uniqueNameErrors = [];

    stores.forEach((store, index) => {
      if (!isUniqueShopName(store.store, stores, index)) {
        uniqueNameErrors.push(`Shop name must be unique for Store ${index + 1}`);
      }

      if (!isUniquePhoneNumber(store.phone, stores, index)) {
        uniqueNameErrors.push(`Phone no. must be unique for Store ${index + 1}`);
      }
    });

    if (uniqueNameErrors.length > 0) {
      uniqueNameErrors.forEach(error => toast(error, { type: toast.TYPE.ERROR }));
      return;
    }

    const validationErrors = stores.map((store, index) => {
      const errors = [];
  
      if (store.store.trim() === '') {
        errors.push(`Store name is required for Store ${index + 1}`);
      }
      if (store.phone.trim() === '') {
        errors.push(`Phone number is required for Store ${index + 1}`);
      }
      if (store.address1.trim() === '') {
        errors.push(`Address 1 is required for Store ${index + 1}`);
      }
      if (store.address2.trim() === '') {
        errors.push(`Address 2 is required for Store ${index + 1}`);
      }
      if (!store.city || store.city.trim() === '') {
        errors.push(`City is required for Store ${index + 1}`);
      }
      if (!store.area || store.area.trim() === '') {
        errors.push(`Area is required for Store ${index + 1}`);
      }
      if (!store.retailer_name || store.retailer_name.trim() === '') {
        errors.push(`Retailer name is required for Store ${index + 1}`);
      }
      if (!store.pincode || store.pincode.trim() === '') {
        errors.push(`Pincode is required for Store ${index + 1}`);
      }
      if (!store.sub_area || store.sub_area.trim() === '') {
        errors.push(`Sub Area is required for Store ${index + 1}`);
      }
      if (!store.isValidUrl) {
        errors.push(`Invalid Google Maps URL for Store ${index + 1}`);
      }
      if (!store.isPhoneValid) {
        errors.push(`Invalid phone number for Store ${index + 1}`);
      }

      if (!store.state || store.state.trim() === '') {
        errors.push(`State is required for Store ${index + 1}`);
      }

      if (!gstNumber || gstNumber.trim() === '') {
        errors.push(`GST is required for Store ${index + 1}`);
      }

      if (!fssaiNumber || fssaiNumber.trim() === '') {
        errors.push(`FSSAI is required for Store ${index + 1}`);
      }
      const locationData = extractLatLongFromMapUrl(store.mapUrl);
      if (!locationData) {
          errors.push(`Invalid Google Maps URL for Store ${index + 1}`);
      } else {
          store.latitude = locationData.latitude;
          store.longitude = locationData.longitude;
      }
  
      return errors;
    }).flat();

    if (validationErrors.length > 0) {
      validationErrors.forEach(error => toast(error, { type: toast.TYPE.ERROR }));
      return;
    }

    const isFormValid =
      numStores !== '' &&
      SelectedProducts.length > 0 &&
      isValidGST &&
      isValidFsNum &&
      stores.length > 0 &&
      stores.every(store => store.isPincodeValid)
      //&&
      // stores.every(
      //   (store) =>
      //     store.isValidUrl &&
      //     store.isPhoneValid &&
      //     store.store.trim() !== '' &&
      //     store.address1.trim() !== '' &&
      //     store.address2.trim() !== '' &&
      //     store.phone.trim() !== '' &&
      //     (store.city !== '' && store.city !== null) &&
      //     (store.area !== '' && store.area !== null) &&
      //     (store.retailer_name !== '' && store.retailer_name !== null && store.retailer_name.trim() !== '') &&
      //     (store.pincode !== '' && store.pincode !== null && store.pincode.trim() !== '') &&
      //     (store.sub_area !== '' && store.sub_area !== null && store.sub_area.trim() !== '')
      // );

    // const invalidStoreIndices = stores.reduce((invalidIndices, store, index) => {
    //   if (!store.mapUrl) {
    //     handleStoreChange(index, 'mapUrl', store.mapUrl);
    //     toast(`Please enter a valid Google Maps URL for Store ${index + 1}`, { type: toast.TYPE.ERROR });
    //     invalidIndices.push(index);
    //   }
    //   return invalidIndices;
    // }, []);

    // if (invalidStoreIndices.length > 0) {
    //   return;
    // }

    if (isFormValid) {
      let databaseRef = database.database().ref();
      let storesData = [];
      let retailer_id_start = defRetID;
      const ownerName = document.getElementById("ownerName").value;
      const promises = [];

      for (let i = 0; i < stores.length; i++) {
        let retailer_id_current = retailer_id_start + i;
        const store = stores[i];
        const storeAddress1 = store.address1;
        const storeAddress2 = store.address2;
        const storeCity = store.city;
        const storeArea  = store.area;
        const phone  = store.phone;
        const shop_name  = store.store;
        const state = store.state;
        const { mapUrl } = store;
        // const { latitude, longitude } = extractLatLongFromMapUrl(mapUrl);

        const storeKey = phone;
  
        let storeData = {
          phone: phone,
          user_name: phone,
          shop_name: shop_name,
          store_type: "Modern Trade",
          address1: storeAddress1,
          address2: storeAddress2,
          area: storeArea,
          city: storeCity,
          beat_name: "Online",
          store_owner: ownerName,
          state: state,
          store_series:"O",
          verified:"Y",
          retailer_products:"Y",
          retailer_name: store.retailer_name,
          pincode: store.pincode,
          sub_area: store.sub_area,
          fssai_document:{document_number: fssaiNumber},
          gst_number: gstNumber,
          latitude: store.latitude+"", 
          retailer_id:retailer_id_current,
          longitude: store.longitude+"", 
          wallet:'0',
          registered_by: sessionStorage.getItem(LOGGED_USER_TYPE),
          registration_date:moment().format('ddd MMM DD HH:mm:ss [GMT]Z YYYY')
        };
        storesData.push(storeData);
        // databaseRef.child(`Retailer_Master_New/${storeKey}`).set(storeData);
        databaseRef.child(`Retailer_Master_New/${storeKey}`).set(storeData, function (error) {
          if (error) {
            console.error(`Error updating retailer details for ${storeKey}:`, error);
            toast(`Error updating retailer details for ${storeKey}`, {
              type: toast.TYPE.ERROR,
            });
          } else {
            console.log(`Retailer details updated successfully for ${storeKey}`);
            toast(`Added retailer details in Retailer master new for ${storeKey}`, {
              type: toast.TYPE.SUCCESS,
            });
          }})
        updateRetailerProducts(retailer_id_current,shop_name);
        if (i === stores.length - 1) {
          updateAppVersion(retailer_id_current);
          updateRegRet();
          setUpdateFunctionsCalled(true);
        }
     }
    } else {
      toast(`Please fill the mandatory fields`, { type: toast.TYPE.ERROR });
    }
  };
  

    function updateAppVersion(retailer_id){
      
      let app_version_ref = database.database().ref(`App_Version/-Ld8_P2P4ue6FYUiatdt`);
      app_version_ref.update({last_retailer_id_mt:retailer_id}).then(() =>{
          props.onHide();
          return toast(`Updated collection last retailer id for MT App version`, { type: toast.TYPE.SUCCESS });
      }).catch((error) =>{
          props.onHide();
          return toast("Couldn't update last retailer id for MT app version ", { type: toast.TYPE.ERROR });
      })
    }

    function updateRetailerProducts(retailer_id,shop_name){
        const hyphenatedShopName = shop_name.replace(/[^a-zA-Z0-9]/g, '-');
        let fbKey = `${retailer_id}-${hyphenatedShopName}`;
        let collectionsArray = [];
        SelectedProducts.forEach((item) =>{
                   
            let itemVal = allMTdata[item]
            if (itemVal) {
                const prodsCollection = {
                  [item]: itemVal
                };
                collectionsArray.push(prodsCollection);
              }
        })
            const allCollections = collectionsArray.reduce((result, collection) => {
                return { ...result, ...collection };
              }, {});


        let ret_prods_ref = database.database().ref(`Retailer_Products/${fbKey}`);
        ret_prods_ref.set(allCollections).then(() =>{
          updateRetailerDD(fbKey);
            return toast(`Created new retailer id ${fbKey}`, { type: toast.TYPE.SUCCESS });
        }).catch((err)=>{
          
            return toast(`Couldn't Add products and retailer in retailer products for ${fbKey} `, { type: toast.TYPE.ERROR });
        })
    }

    return(
        <>
    <Modal
     {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static" 
        keyboard={false}      >
    <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
            Register retailer
        </Modal.Title>
        </Modal.Header>
        <Modal.Body>
        {renderForm()}
        </Modal.Body>

        <Modal.Footer>
       
        <Button variant="success" onClick={handleSaveProducts}>Save retailer</Button>
        <Button onClick={()=>{props.onHide()}}>Close</Button>
        </Modal.Footer>
        </Modal>
    
        </>
    )

}
export default RegisterRetailer;