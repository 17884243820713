import React from "react";
import { SESSION_TEXT, LOGGED_USER_EMAIL } from "../../constants/allConstants";
import { useHistory } from "react-router-dom";
import { validateIsAdminUser, getUserType } from "../../service/credentials";

import {
  NavBarContainer,
  LeftContainer,
  SZLogoContainer,
  MidContainer,
  RightContainer,
  BulkUploadContainer,
  AddProductContainer,
  UserStatusContainer,
} from "./wrapper";

const DashboardNavBar = (props) => {
  let history = useHistory();
  const isUserSignedIn = true;
  const userStatusText = isUserSignedIn ? "Sign Out" : "Sign In";

  const userCity = sessionStorage.getItem("Logged User City") || "";

  const removeSession = () => {
    sessionStorage.clear();
    history.push("/login");
  };
  const onLogoClick = () => {
    sessionStorage.removeItem("datekey");
    switch (getUserType(sessionStorage.getItem(LOGGED_USER_EMAIL))) {
      case "stocktake":
        history.push("/listStockTake");
        break;
      default:
        history.push("/Dashboard");
        break;
    }
  };

  return (
    <NavBarContainer>
      <LeftContainer>
        <SZLogoContainer
          onClick={onLogoClick}
          key="0"
          title="Reload Home Page"
        />
      </LeftContainer>

      <RightContainer>
        <UserStatusContainer>
              <span style={{ marginRight: "10px" }}>
                {`Warehouse: ${userCity}`}
              </span>
          <button style={{ marginRight: "10px" }}>v 1.0.11</button>
          <button onClick={removeSession}>{userStatusText}</button>
        </UserStatusContainer>
      </RightContainer>
    </NavBarContainer>
  );
};

export default DashboardNavBar;
