import React, { useState, createRef, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { validateIsAdminUser, getUserType } from "../../service/credentials";
import {
  INIT_PAGE_TITLE,
  SESSION_TEXT,
  LOGGED_USER_EMAIL,
  LOGGED_CITY,
  LOGGED_USER_PASSWORD,
  LOGGED_USER_TYPE,
} from "../../constants/allConstants";
import { LoginContainer, LoginHeader, UserDetailsContainer } from "./wrapper";
import { Form, Button } from "react-bootstrap";
import firebase from "../../service/firebaseConfig";
import { support_portal_url } from "../../service/servicesConfig";

function formatDate(date) {
  var d = new Date(date),
    month = "" + (d.getMonth() + 1),
    day = "" + d.getDate(),
    year = d.getFullYear(),
    hour = "" + d.getHours(),
    minute = "" + d.getMinutes();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;
  if (hour.length < 2) hour = "0" + hour;
  if (minute.length < 2) minute = "0" + minute;

  //dd/mm/yyyy hh:mm
  return [day, month, year].join("/") + " " + [hour, minute].join(":");
}

const LoginPageComp = () => {
  let history = useHistory();
  const userNameRef = createRef();
  const passwordRef = createRef();
  const emailErrMsg = "Enter a valid email id";
  const passwordErrMsg = "Enter correct password";
  const [emailErr, setEmailErr] = useState(false);
  const [passwordErr, setPasswordErr] = useState(false);
  const [inputEmail, setInputEmail] = useState("");
  const [inputUserType, setInputUserType] = useState("");
  const [hideEmailForm, setHideEmailForm] = useState(false);
  const [otp, setOtp] = useState("");
  const [otpErrMsg, setOtpErrMsg] = useState("");
  const [otpSentMsg, setOtpSentMsg] = useState("");
  const [inputUserObj, setInputUserObj] = useState({});
  const [seconds, setSeconds] = useState(0);
  const [resendOtpGreyed, setResendOtpGreyed] = useState(true);
  const [isTimerActive, setIsTimerActive] = useState(false);

  useEffect(() => {
    if (seconds == 0) {
      setResendOtpGreyed(false);
    }
    let timer = setTimeout(() => setSeconds(seconds - 1), 1000);
    if (seconds == 0) {
      setResendOtpGreyed(false);
      clearTimeout(timer);
    }

    return () => clearTimeout(timer);
  }, [seconds]);

  const validateInput = (data) => {
    data = data.trim();
    return data !== undefined
      ? data !== null
        ? data.length > 0
          ? true
          : false
        : false
      : false;
  };

  const loginFunc = (event) => {
    
    event.preventDefault();
    const inpEmail = userNameRef.current.value;
    setInputEmail(inpEmail);
    const inpPass = passwordRef.current.value;
    const isSZUser = inpEmail.split("@")[1] === "superzop.com" ? true : false;
    if (validateInput(inpEmail) && validateInput(inpPass) && isSZUser) {
      let apiurlstr = `${support_portal_url}/api/adminportallogin`;
      let details = {
        email: inpEmail,
        password: inpPass,
      };

      fetch(apiurlstr, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(details),
      })
        .then((response) => {
          return response.json();
        })
        .then((result) => {
          console.log("check result", result);
          if (result.success == 1) {
            let loggedInUserDetails = result["data"] ? result["data"] : {};
            setInputUserObj(loggedInUserDetails);
            if (result["otpauth"] == 0) {
              sessionStorage.setItem(
                LOGGED_USER_EMAIL,
                loggedInUserDetails.email
              );
              sessionStorage.setItem(
                LOGGED_USER_TYPE,
                loggedInUserDetails.user_type
              );
              sessionStorage.setItem(SESSION_TEXT, true);
              sessionStorage.setItem(LOGGED_CITY, loggedInUserDetails.city || "")

              let currentTimestamp = Date.now();
              sessionStorage.setItem("session-start-date", currentTimestamp);

              sessionStorage.setItem("apiToken", result.token);
              handleRedirection();
            } else {
              let mobile = loggedInUserDetails.mobile
                ? loggedInUserDetails.mobile
                : "";
              let otp_bypass =
                loggedInUserDetails.otp_bypass == "1" ? true : false;

              if (otp_bypass) {
                //username,password login
              } else {
                console.log("otp auth");
                //otp auth
                if (mobile) {
                  setHideEmailForm(true);
                  setInputUserType(loggedInUserDetails.user_type);
                  generateOtp(mobile, loggedInUserDetails.id);
                } else {
                  setHideEmailForm(true);
                  setInputUserType(loggedInUserDetails.user_type);
                  setOtpErrMsg("Invalid mobile number.");
                }
              }
            }
          } else {
            let errorMessage = result.message ? result.message : "";
            // alert(errorMessage)
            setEmailErr(true);
            setPasswordErr(true);
          }
        })
        .catch((err) => {
          alert(err);
        });
    } else {
      setEmailErr(true);
      setPasswordErr(true);
    }
  };

  const handleRedirection = () => {
    switch (sessionStorage.getItem(LOGGED_USER_TYPE)) {
      case "stocktake":
        history.push("/listStockTake");
        break;
      default:
        history.push("/Dashboard");
        break;
    }
  };

  const logInIfTrue = () => {
    const loggedStatus = sessionStorage.getItem(SESSION_TEXT);
    if (loggedStatus && inputEmail) {
      switch (sessionStorage.getItem(LOGGED_USER_TYPE)) {
        case "stocktake":
          history.push("/listStockTake");
          break;
        default:
          history.push("/Dashboard");
          break;
      }
    }
  };

  useEffect(() => {
    document.title = `${INIT_PAGE_TITLE} | Login`;
    logInIfTrue();
  }, []);

  const generateOtp = (phoneNumber, userID) => {
    if (!phoneNumber) {
      phoneNumber = inputUserObj.mobile;
    }
    if (!userID) {
      userID = inputUserObj.id;
    }

    if (phoneNumber == "") {
      alert("Please enter phone number");
    } else if (phoneNumber.length != 10) {
      alert("Please enter 10 digit phone number");
    } else {
      let apiurlstr = `${support_portal_url}/api/otp?phone=${phoneNumber}&ID=${userID}&portal=admin`;
      console.log(apiurlstr);
      fetch(apiurlstr)
        .then((res) => {
          return res.json();
        })
        .then((data) => {
          setOtpSentMsg(`OTP has been sent to ${phoneNumber}`);
          setIsTimerActive(true);
          setSeconds(30);
          setResendOtpGreyed(true);
          setOtpErrMsg("");
        })
        .catch((error) => {
          alert("Error in sending OTP" + error);
        });
    }
  };

  const signInWithOTP = (e) => {
    if (e) {
      e.preventDefault();
    }
    console.log("signInWithOTP");
    console.log(otp);
    let userObj = { ID: inputUserObj.id, otp: otp, portal: "admin" };

    fetch(`${support_portal_url}/api/validateotp`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(userObj),
    })
      .then((res) => {
        return res.json();
      })
      .then((result) => {
        // console.log(result);
        if (result["success"] == 1) {
          setOtpErrMsg("");
          setOtpSentMsg("");

          sessionStorage.setItem(LOGGED_USER_EMAIL, inputEmail);
          sessionStorage.setItem(LOGGED_USER_TYPE, inputUserType);
          sessionStorage.setItem(SESSION_TEXT, true);
          let currentTimestamp = Date.now();
          sessionStorage.setItem("session-start-date", currentTimestamp);

          sessionStorage.setItem("apiToken", result.token);
          handleRedirection();
        } else {
          setOtpSentMsg("");
          setOtpErrMsg("OTP is incorrect.");
        }
      })
      .catch((error) => {
        setOtpSentMsg("");
        setOtpErrMsg("OTP is incorrect.");
      });
  };

  return (
    <LoginContainer>
      <LoginHeader>
        <span></span> {/* for logo, so don't remove the span */}
      </LoginHeader>
      {!hideEmailForm && (
        <UserDetailsContainer>
          <input
            autoFocus
            autoComplete="false"
            type="text"
            placeholder="Email Id"
            ref={userNameRef}
          />
          {emailErr ? <span>{emailErrMsg}</span> : null}
          <input
            autoComplete="false"
            type="password"
            placeholder="Password"
            ref={passwordRef}
          />
          {passwordErr ? <span>{passwordErrMsg}</span> : null}
          <button className="submitBtn" onClick={loginFunc} type="submit">
            Sign In
          </button>
        </UserDetailsContainer>
      )}
      {hideEmailForm && (
        <UserDetailsContainer>
          <Form.Control
            autoFocus
            type="text"
            defaultValue={otp}
            onChange={(e) => {
              setOtp(e.target.value);
            }}
            placeholder="OTP"
          />
          {otpErrMsg ? <span>{otpErrMsg}</span> : null}
          {otpSentMsg ? <span id="otpsent">{otpSentMsg}</span> : null}

          <div style={{ textAlign: "center", marginTop: "10px" }}>
            {isTimerActive == true ? (
              <p>Time Left : 00:{seconds < 10 ? "0" + seconds : seconds}</p>
            ) : null}
          </div>

          <button className="submitBtn" onClick={signInWithOTP} type="submit">
            Submit OTP
          </button>

          <div style={{ textAlign: "center", marginTop: "10px" }}>
            {isTimerActive == true ? (
              resendOtpGreyed == true ? (
                <Button
                  variant="success"
                  color="primary"
                  type="submit"
                  disabled={true}
                  onClick={(e) => {
                    generateOtp();
                  }}
                >
                  Resend OTP
                </Button>
              ) : (
                <Button
                  variant="success"
                  type="submit"
                  onClick={(e) => {
                    generateOtp();
                  }}
                >
                  Resend OTP
                </Button>
              )
            ) : null}
          </div>
        </UserDetailsContainer>
      )}
    </LoginContainer>
  );
};

export default LoginPageComp;
