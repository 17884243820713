import {
  Box,
  Button,
  Container,
  Flex,
  Image,
  Stack,
  Table,
  Title,
} from "@mantine/core";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { CreateBomDrawer } from "./createBomDrawer";
import firebase from "../../service/firebaseConfig";
import { DELIVERY_BASE_URL } from "../../service/servicesConfig";
import { DatePicker } from "@mantine/dates";
import moment from "moment";
import NavBar from "../../Dashboard/Navbar/Dashboard_Navabar";
import ExportToExcel from "./export/ExportToExcel";
import { DeleteModal } from "./deleteModal";
import { ViewCalculationsModal } from "./viewCalculationsModal";

export function BillOfMaterials(props) {
  const [finalCostInCalculationModal,setfFinalCostInCalculationModal] = useState(null); 
  const [productMaster, setProductMaster] = useState({});
  const [materialsAndCombinations, setMaterialsAndCombinations] = useState([
    [],
    {},
  ]);

  const [isBoMDrawerOpened, setIsBoMDrawerOpened] = useState(false);
  const [isViewCalculationsModalOpened, setIsViewCalculationsModalOpened] =
    useState(false);
  const [viewCalculationsModalData, setViewCalculationsModalData] = useState({
    quantity: 0,
    cost_per_kg: 0,
    total_cost: 0,
    packaging_cost: 0,
    batter_name: "",
    batter_id: "",
    items: [],
  });
  const [isDeleteModalOpened, setIsDeleteModalOpened] = useState(false);
  const [itemIdMarkedForDeletion, setItemIdMarkedForDeletion] = useState(false);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [listingData, setListingData] = useState([]);


  const userCity = sessionStorage.getItem("Logged User City") || "";

  function fetchPriceDetails () {
    return firebase.database().ref('Price_Details/mumbai/items').once('value');
  }

  async function fetchProductMasterNew(series = "A") {
    firebase
      .database()
      .ref(`Product_Master_New/${series}`)
      .once("value")
      .then( async (snapshot) => {
        if (snapshot.hasChildren()) {
          const results = snapshot.val();

          const priceDetailsSnapshot = await fetchPriceDetails();
          // console.log("check received data", results);
          // setProductMaster(results);
          // computeMaterialCombinations(results);

          if (priceDetailsSnapshot.hasChildren()) {
            const priceDetails = priceDetailsSnapshot.val();
            const updatedResults = {};
            Object.keys(results).forEach(itemId => {
              const item = results[itemId];
              const landing_cost = priceDetails[itemId]?.landing_cost || null;
              updatedResults[itemId] = { ...item, landing_cost };
            });
            setProductMaster(updatedResults);
            computeMaterialCombinations(results);
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function formatDataFromAPI(data) {
    if (!data || data.length === 0) return [];

    data.forEach((item) => {
      item.created_at_formatted = moment(item.created_at).format(
        "YYYY-MM-DD HH:mm"
      );
    });

    return data;
  }

  function fetchBoMListingData() {
    let start_date = moment(startDate).format("YYYY-MM-DD");
    let end_date = moment(endDate).format("YYYY-MM-DD");
    const url = `${DELIVERY_BASE_URL}/api/superzop/billmanagement/getBillMaterials?start_date=${start_date}&end_date=${end_date}`;
    fetch(url, { method: "GET" })
      .then((res) => res.json())
      .then((body) => {
        console.log("check received data", body);

        const formattedData = formatDataFromAPI(body);
        setListingData(formattedData);
      })
      .catch((err) => console.log("Erorr: ", err));
  }

  function computeMaterialCombinations(prod_master_data) {
    const parentProductList = new Set();
    const parentAndIngredients = {};

    Object.keys(prod_master_data).forEach((key) => {
      const item = prod_master_data[key];

      if (!("bill_material" in item)) return;
      let { bill_material } = item;
      bill_material = bill_material.split(",");
      bill_material.forEach((parent_id) => {
        parentProductList.add(parent_id);
        if (!(parent_id in parentAndIngredients))
          parentAndIngredients[parent_id] = [];
        parentAndIngredients[parent_id].push(item.item_id);
      });
    });

    // console.log('debug check', parentProductList, parentAndIngredients)
    setMaterialsAndCombinations([parentProductList, parentAndIngredients]);
  }

  useEffect(() => {
    fetchPriceDetails();
    fetchProductMasterNew();
  }, []);

  useEffect(() => {
    if (!startDate || !endDate) return;
    fetchBoMListingData();
  }, [startDate, endDate]);

  return (
    <>
      <NavBar />
      <Box style={{ margin: 0 }}>
        <Stack align="stretch" justify="center">
          <Box style={{ paddingTop: "6rem", paddingBottom: "3rem" }}>
            <Flex
              style={{ marginLeft: "1rem" }}
              gap="md"
              justify="flex-start"
              align="end"
              direction="row"
              wrap="nowrap"
            >
              <Title mt={30} order={1}>
                Bill of Materials
              </Title>
              <Box style={{ display: "inline-block" }}>
                <DatePicker
                  placeholder="Pick date"
                  label="Start date"
                  value={startDate}
                  maxDate={new Date()}
                  onChange={setStartDate}
                />
              </Box>
              <DatePicker
                placeholder="Pick date"
                label="End date"
                value={endDate}
                minDate={startDate}
                onChange={setEndDate}
              />
              <Flex direction="row" align={"end"}>
                <Button
                  variant="gradient"
                  gradient={{ from: "teal", to: "lime", deg: 105 }}
                  style={{ alignSelf: "flex-end", marginRight: "1rem" }}
                  loading={Object.keys(productMaster).length < 1}
                  onClick={() => setIsBoMDrawerOpened(true)}
                >
                  Create BoM
                </Button>
                <ExportToExcel
                  sheet1={listingData}
                  filename={"bill_of_materials"}
                  sheet1Name={"Sheet 1"}
                />
              </Flex>
            </Flex>
          </Box>
          <Box>
            {" "}
            <Table striped fontSize={"xs"}>
              <thead>
                <tr>
                  <th style={{ textAlign: "center" }}>Product Image</th>
                  <th style={{ textAlign: "center" }}>Creation date</th>
                  <th style={{ textAlign: "center" }}>Major Category</th>
                  <th style={{ textAlign: "center" }}>Product Name</th>
                  <th style={{ textAlign: "center" }}>Product Code</th>
                  <th style={{ textAlign: "center" }}>Total Cost</th>
                  <th style={{ textAlign: "center" }}>Cost per Kg</th>
                  <th style={{ textAlign: "center" }}>No of Pcs</th>
                  <th style={{ textAlign: "center" }}>Total Weight</th>
                  <th style={{ textAlign: "center" }}>Packaging material</th>
                  <th style={{ textAlign: "center" }}>Final Cost</th>
                  <th style={{ textAlign: "center" }}>Warehouse</th>
                  <th style={{ textAlign: "center" }}>View</th>
                  <th style={{ textAlign: "center" }}>Delete</th>
                </tr>
              </thead>
              <tbody>
                {listingData.filter(({warehouse}) => {
                      if(!userCity) {
                          return true;
                      }
                      return warehouse == userCity
                  }).map(
                  (
                    {
                      major_category,
                      created_at,
                      created_at_formatted,
                      bom_item_name,
                      bom_item_id,
                      bom_quantity,
                      total_cost,
                      final_price,
                      cost_per_kg,
                      total_weight,
                      packing_cost,
                      items = [],
                      bom_id,
                      warehouse
                    },
                    index
                  ) => {
                    return (
                      <tr key={index}>
                        <td>
                          {" "}
                          <Image
                            width={100}
                            height={80}
                            radius="md"
                            src={productMaster[bom_item_id]?.image_url || ""}
                            alt="Product image"
                          />
                        </td>
                        <td>{created_at_formatted}</td>
                        <td>{major_category}</td>
                        <td>{bom_item_name}</td>
                        <td>{bom_item_id}</td>
                        <td>₹ {total_cost}</td>
                        <td>₹ {cost_per_kg}</td>
                        <td>{bom_quantity}</td>
                        <td>{total_weight}</td>
                        <td>₹ {packing_cost}</td>
                        <td>₹ {final_price}</td>
                        <td>{warehouse}</td>
                        <td>
                          <Button
                            color="orange"
                            size="xs"
                            onClick={() => {
                              const calculationsObj = {
                                batter_name: bom_item_name,
                                batter_id: bom_item_id,
                                quantity: bom_quantity || "0",
                                total_cost,
                                cost_per_kg,
                                final_price,
                                packing_cost,
                                total_weight,
                                items,
                                image_url:
                                  productMaster[bom_item_id]?.image_url || "",
                              };
                              setViewCalculationsModalData(calculationsObj);
                              setIsViewCalculationsModalOpened(true);
                            }}
                          >
                            View
                          </Button>{" "}
                        </td>

                        <td>
                          <Button
                            color="red"
                            size="xs"
                            disabled={
                              // converting it into Indian Time Zone
                              // moment().add(5, 'hours').add(30, 'minutes').format("DD-MM-YYYY") !==
                              // moment(created_at).add(5, 'hours').add(30, 'minutes').format("DD-MM-YYYY")
                              moment().format("DD-MM-YYYY") !==
                              moment(created_at).format("DD-MM-YYYY")
                          }
                            onClick={() => {
                              setItemIdMarkedForDeletion(bom_id);
                              setIsDeleteModalOpened(true);
                            }}
                          >
                            Delete
                          </Button>{" "}
                        </td>
                      </tr>
                    );
                  }
                )}
              </tbody>
            </Table>
          </Box>
        </Stack>
      </Box>
      {isDeleteModalOpened && (
        <DeleteModal
          opened={isDeleteModalOpened}
          refetchListingData={fetchBoMListingData}
          setOpened={setIsDeleteModalOpened}
          id={itemIdMarkedForDeletion}
        />
      )}
      {isBoMDrawerOpened && (
        <CreateBomDrawer
          opened={isBoMDrawerOpened}
          setOpened={setIsBoMDrawerOpened}
          fetchBoMListingData={fetchBoMListingData}
          materialsAndCombinations={materialsAndCombinations}
          productMaster={productMaster}
          setfFinalCostInCalculationModal={setfFinalCostInCalculationModal}
        />
      )}

      {isViewCalculationsModalOpened && (
        <ViewCalculationsModal
          opened={isViewCalculationsModalOpened}
          setOpened={setIsViewCalculationsModalOpened}
          data={viewCalculationsModalData}
          finalCostInCalculationModal={finalCostInCalculationModal}
        />
      )}
    </>
  );
}
