import { INIT_PAGE_TITLE, SESSION_TEXT, LOGGED_USER_EMAIL, LOGGED_USER_PASSWORD, LOGGED_USER_TYPE } from '../constants/allConstants';

export const validateIsAdminUser = (inpEmail) => {
    let adminCheck = false;
    if(inpEmail == sessionStorage.getItem(LOGGED_USER_EMAIL) && sessionStorage.getItem(LOGGED_USER_TYPE) == "admin") {
        adminCheck = true;
    }
    return adminCheck;
};



export const validateIsInternalUser = (inpEmail) => {
    let adminCheck = false;
    if(inpEmail == sessionStorage.getItem(LOGGED_USER_EMAIL) && sessionStorage.getItem(LOGGED_USER_TYPE) == "internal") {
        adminCheck = true;
    }
    return adminCheck;
};

export const validateProductUser = (inpEmail) => {
    let productCheck = false;
    if(getUserType (inpEmail) == "admin" || getUserType (inpEmail) == "product" || getUserType (inpEmail) == "productHead") {
        productCheck = true;
    }
    return productCheck;
};

export const validateMarketingUser = (inpEmail) => {
    let marketCheck = false;
    if(getUserType (inpEmail) == "admin" || getUserType (inpEmail) == "marketing") {
        marketCheck = true;
    }
    return marketCheck;
};

export const validateCommercialUser = (inpEmail) => {
    let commercialCheck = false;
    if(getUserType (inpEmail) == "admin" || getUserType (inpEmail) == "commercial" || getUserType (inpEmail) == "commercialadmin" ) {
        commercialCheck = true;
    }
    return commercialCheck;
};

export const validateProductOnlyUser = (inpEmail) => {
    let productCheck = false;
    if(getUserType (inpEmail) == "product" ||  getUserType (inpEmail) == "productHead") {
        productCheck = true;
    }
    return productCheck;
};



export const validateProductHeadOnlyUser = (inpEmail) => {
    let productCheck = false;
    if(getUserType (inpEmail) == "productHead") {
        productCheck = true;
    }
    return productCheck;
};


export const validateStockUser = (inpEmail) => {
    let stockCheck = false;
    if(getUserType (inpEmail) == "stock" || getUserType (inpEmail) == "stock-manager" || getUserType (inpEmail) == "admin" ) {
        stockCheck = true;
    }
    return stockCheck;
};

export const validateDCManagerUser = (inpEmail) => {
    let dcmanagerCheck = false;
    if(getUserType (inpEmail) == "DCManager" || getUserType (inpEmail) == "financeHead"  || getUserType (inpEmail) == "admin" ) {
        dcmanagerCheck = true;
    }
    return dcmanagerCheck;
};

export const validateDumpApprovalUser = (inpEmail) => {
    let userCheck = false;
    if (getUserType (inpEmail) == "DCManager" || getUserType (inpEmail) == "financeHead"  || getUserType (inpEmail) == "admin" || getUserType (inpEmail) == "productHead" || getUserType (inpEmail) == "commercialadmin" ) {
        userCheck = true;
    }
    return userCheck;
}

export const validateStockOnlyUser = (inpEmail) => {
    let grnCheck = false;
    if(getUserType (inpEmail) == "stock") {
        grnCheck = true;
    }
    return grnCheck;
};

export const validateMDMUser = (inpEmail) => {
    let mdmCheck = false;
    if(getUserType (inpEmail) == "MDM" || getUserType (inpEmail) == "admin" ) {
        mdmCheck = true;
    }
    return mdmCheck;
};

export const validatePurchaseUser = (inpEmail) => {
    let purchaseCheck = false;
    if(getUserType (inpEmail) == "admin" || getUserType (inpEmail) == "product" || 
    getUserType (inpEmail) == "purchase_grn" || getUserType(inpEmail) == 'purchase' || 
    getUserType (inpEmail) == "productHead"  || getUserType (inpEmail) == "ops" || 
    getUserType (inpEmail) == "commercial" || getUserType (inpEmail) == "commercialadmin" || 
    getUserType (inpEmail) == "MDM" || getUserType (inpEmail) == "grn") {
        purchaseCheck = true;
    }
    return purchaseCheck;
};

export const validatePurchaseOnlyUser = (inpEmail) => {
    let purchaseCheck = false;
    if(getUserType (inpEmail) == "product" ||  
    getUserType(inpEmail) == 'purchase' || getUserType (inpEmail) == "productHead") {
        purchaseCheck = true;
    }
    return purchaseCheck;
};

export const validateGRNUser = (inpEmail) => {
    let grnCheck = false;
    if(getUserType (inpEmail) == "grn" || getUserType (inpEmail) == "purchase_grn" || getUserType (inpEmail) == "admin" ) {
        grnCheck = true;
    }
    return grnCheck;
};

export const validateGRNOnlyUser = (inpEmail) => {
    let grnCheck = false;
    if(getUserType (inpEmail) == "grn") {
        grnCheck = true;
    }
    return grnCheck;
};

export const validateGRNOrFinanceUser = (inpEmail) => {
    let check = false;
    if(getUserType (inpEmail) == "grn" || getUserType (inpEmail) == "finance" || getUserType (inpEmail) == "financeHead") {
        check = true;
    }
    return check;
};

export const validateDispatchOnlyUser = (inpEmail) => {
    let dispatchCheck = false;
    if(getUserType (inpEmail) == "dispatch") {
        dispatchCheck = true;
    }
    return dispatchCheck;
};

export const validatePackageOnlyUser = (inpEmail) => {
    let packageCheck = false;
    if(getUserType (inpEmail) == "package") {
        packageCheck = true;
    }
    return packageCheck;
};

export const validateQualityyUser = (inpEmail) => {
    let qualityCheck = false;
    if(getUserType (inpEmail) == "quality") {
        qualityCheck = true;
    }
    return qualityCheck;
};


export const validateOPSUser = (inpEmail) => {
    let grnCheck = false;
    if(getUserType (inpEmail) == "ops" || getUserType (inpEmail) == "admin" ) {
        grnCheck = true;
    }
    return grnCheck;
};

export const validateOPSOnlyUser = (inpEmail) => {
    let grnCheck = false;
    if(getUserType (inpEmail) == "ops") {
        grnCheck = true;
    }
    return grnCheck;
};

export const validateBuyingHead = (inpEmail) => {
    let buyingHeadCheck = false;
    if(getUserType (inpEmail) == "admin" || getUserType (inpEmail) == "productHead") {
        buyingHeadCheck = true;
    }
    return buyingHeadCheck;
};

export const validatePurchaseReportUser = (inpEmail) => {
    let exportUser = false;
    if(getUserType (inpEmail) == "admin" || getUserType(inpEmail) == 'product' || getUserType (inpEmail) == "productHead") {
        exportUser = true;
    }
    return exportUser;
};

export const validatePurchaseExportUser = (inpEmail) => {
    let exportUser = false;
    if(getUserType (inpEmail) == "admin" || getUserType(inpEmail) == 'financeHead' || getUserType(inpEmail) == 'finance' || getUserType (inpEmail) == "productHead") {
        exportUser = true;
    }
    return exportUser;
};

export const validatePurchaseExportOnlyUser = (inpEmail) => {
    let exportOnlyUser = false;
    if(getUserType(inpEmail) == 'finance') {
        exportOnlyUser = true;
    }
    return exportOnlyUser;
};

export const getUserType = (inpEmail) => {
    let userType = "";
    if(inpEmail == sessionStorage.getItem(LOGGED_USER_EMAIL)) {
        userType = sessionStorage.getItem(LOGGED_USER_TYPE);
    }
    return userType;
};