import React, { Component, Fragment } from "react";
import RetailerPhoneUpdate from "./RetailerPhoneUpdate";
import RetailerSecondaryPhoneUpdate from "./RetailerSecondaryPhoneUpdate";
import {
  Row,
  FormGroup,
  FormControl,
  FormLabel,
  Button,
  Tabs,
  Tab,
  Col,
  Nav,
  Breadcrumb,
} from "react-bootstrap";
import database from "../../../service/firebaseConfig";
import { Link, Redirect, useHistory } from "react-router-dom";
import Select from "react-select";
import NavBar from "../../../Dashboard/Navbar/Dashboard_Navabar";
import FooterComponent from "../../../components/Footer/index";
import SalesmanCreate from "./AddSalesman";
import WalletDetails from "./WalletDetails";
import { withRouter } from "react-router-dom";

const retailerDetails = database.database().ref("Retailer_Master_New");

const retailerUnapproved = database.database().ref("Retailer_Unapproved");

const salesmanDetails = database.database().ref("SalesMan_Details");
const ordersNew = database.database().ref("Orders_New");
const ordersHistory = database.database().ref("Orders_History");

class CustomUpdates extends Component {
  constructor(props) {
    super(props);

    this.state = {
      inputValue: "",
      options1: [],
      salesmanList: [],
      retailerList: [],
      retailerUnapprov: [],
      maximum: "",
      user: "",
      address1: "",
      address2: "",
      address3: "",
      address4: "",
      address5: "",
      retailer_phone: "",
      phoneno: "",
    };
    this.changadd = this.changadd.bind(this);
  }
  componentDidMount() {
    salesmanDetails.on("value", (snapshot) => {
      var data = snapshot.val();
      var element = Object.keys(data);
      let salesmanArray = [];
      var max = -Infinity;
      for (var i = 0; i < element.length; i++) {
        var k = element[i];
        let element1 = {};
        var p = data[k].salesman_id;
        element1["value"] = data[k].salesman_id;
        element1["label"] = data[k].salesman_id;
        console.log(element1);
        salesmanArray.push(element1);
        if (p > max) max = p;
      }
      this.setState({
        maximum: max,
      });
      // console.log(salesmanArray);
      this.setState({ salesmanList: salesmanArray });
    });
    retailerDetails.limitToFirst(100).on("value", (snapshot) => {
      var data = snapshot.val();
      var element = Object.keys(data);
      let retailersArray = [];

      for (var i = 0; i < element.length; i++) {
        var k = element[i];
        let element1 = {};
        element1["value"] = data[k].phone;
        element1["label"] = data[k].phone;
        // console.log(element1);
        retailersArray.push(element1);
        // console.log(retailersArray);
      }

      this.setState({ retailerList: retailersArray });
    });
    retailerUnapproved.on("value", (snapshot) => {
      var data = snapshot.val();
      var element = Object.keys(data);
      let retailersArrayUnapprov = [];

      // for(var i=0;i<element.length;i++){
      //   var k=element[i];
      //   let element={};
      //   element["value"]=data[k].retailer_id;
      //   element["label"]=data[k].retailer_id;
      //   console.log(element)
      //   retailersArrayUnapprov.push(element);

      // }
      element.forEach((item) => {
        var k = Object.keys(item);
        let elementsobjects = {};
        elementsobjects["value"] = item;
        elementsobjects["label"] = item;
        retailersArrayUnapprov.push(element);
      });

      this.setState({ retailerUnapprov: retailersArrayUnapprov });
    });

    const queryString = require("query-string");
    var parsed = queryString.parse(this.props.location.search);
    var retailer_id = parsed.retailer_id;
    var salesman_id = parsed.salesman_id;
    if (retailer_id) {
      // console.log(retailerDetails.child(retailer_id));
      retailerDetails.child(retailer_id).once("value", (retailer_details) => {
        // console.log("Retailer details: " + retailer_details.val());
        this.retailer_details = retailer_details.val();
      });
    } else if (salesman_id) {
      // console.log(retailerUnapproved.child(salesman_id));
      retailerUnapproved.child(salesman_id).once("value", (retailersList) => {
        // console.log("Retailer List: " + retailersList.val());
        this.retailersList = retailersList.val();
      });
    }
  }

  myform() {
    console.log("hello");

    database
      .database()
      .ref("SalesMan_Details")
      .on("value", (snapshot) => {
        var scores = snapshot.val();
        var keys = Object.keys(scores);

        let asm_arr = [];

        for (var i = 0; i < keys.length; i++) {
          var k = keys[i];
          var salesman_asm = scores[k].asm;

          asm_arr[i] = salesman_asm;
        }
        // console.log(asm_arr);
        // console.log([...new Set(asm_arr)]);
        let unique = [...new Set(asm_arr)];
        for (var j = 0; j < unique.length; j++) {
          var l = unique[j];
          var select = document.getElementById("selected");
          var newoption = document.createElement("OPTION");
          var newoptionval = document.createTextNode(l);
          newoption.appendChild(newoptionval);
          newoption.setAttribute("value", l);
          select.insertBefore(newoption, select.lastChild);
        }
        var asm3 = document.getElementById("selected").value;

        for (var g = 0; g < keys.length; g++) {
          var key = keys[g];
          var asm_id2 = scores[key].asm;

          if (asm3 === asm_id2)
            document.getElementById("asmid").value = Number(scores[key].asm_id);
        }
      });
  }

  handleNewSalesman = (e) => {
    e.preventDefault();
  };

  assignRetailerSalesman = (e) => {
    var retailer_id = this.retailer_id.value;
    var salesman_phone = this.salesman_toassign.value;
    e.preventDefault();
    var retailerUnapproved = database
      .database()
      .ref("Retailer_Unapproved/" + salesman_phone);
    const Retailers_Data = database.database().ref(`Retailer_Master_New`);
    Retailers_Data.child(retailer_id).once("value", (retailer_details) => {
      retailer_details = retailer_details.val();

      if (retailer_details && salesman_phone) {
        retailer_details.agent_id = salesman_phone;
        // console.log(retailerUnapproved);
        retailerUnapproved.once("value", (retailersList) => {
          // console.log(`UnApprovedRetailer List: ${retailersList.val()}for salesman${salesman_phone}`);
          retailersList = retailersList.val();
        });
        retailerUnapproved
          .child(retailer_id)
          .set(retailer_details)
          .then(function () {
            alert(
              "Successfully assigned the retailer phone :::: " +
                retailer_id +
                " to salesman" +
                salesman_phone
            );
          })
          .catch(function (error) {
            alert("Data could not be saved." + error);
          });
      } else {
        alert("Salesman ID is empty");
      }
    });
  };
  handleRetailerPassword = (e) => {
    e.preventDefault();
    var retailer_id = this.retailer_phone.value;
    var retailer_password = this.retailer_password.value;
    if (retailer_id) {
      retailerDetails.child(retailer_id).once("value", (retailer_details) => {
        this.retailer_details = retailer_details.val();
        // console.log("Retailer Details" + this.retailer_details);
        if (this.retailer_details && this.retailer_details.retailer_id !== 0) {
          alert("Valid Retailer ID::: " + this.retailer_details.user_name);
          this.retailer_details.password = retailer_password;
          // console.log("Updated new retailer details::" + this.retailer_details);
          retailerDetails
            .child(retailer_id)
            .set(this.retailer_details)
            .then(function () {
              alert(
                "Successfully added password::: " +
                  retailer_password +
                  " for retailer::: " +
                  retailer_id
              );
            })
            .catch(function (error) {
              alert("Data could not be saved." + error);
            });
        } else {
          alert(`Retailer ID is invalid. Try again`);
        }
      });
    } else {
      alert("Retailer ID is empty");
    }
  };

  updateOrderNumber = (e) => {
    e.preventDefault();
    var old_order_no = this.old_order_number.value;
    var new_order_no = this.new_order_number.value;
    // console.log(  "Old & New orderNumber:: " + old_order_no + " , " + new_order_no );
    if (new_order_no && old_order_no) {
      ordersNew.once("value", (ordersList) => {
        let ordersListValue = ordersList.val();
        Object.keys(ordersListValue).forEach((itemKey) => {
          // console.log(`OrderDetails of ${itemKey} Object:: ${ordersListValue[itemKey]}`);
          if (
            ordersListValue[itemKey] &&
            ordersListValue[itemKey].order_number == old_order_no
          ) {
            ordersListValue[itemKey].order_number = new_order_no;
            ordersNew
              .child(itemKey)
              .set(ordersListValue[itemKey])
              .then(function () {
                alert(
                  "Successfully updated ordernumber::: " +
                    old_order_no +
                    " to " +
                    new_order_no +
                    " for record::: " +
                    itemKey
                );
              })
              .catch(function (error) {
                alert("Data could not be saved." + error);
              });
          }
        });
      });
    } else {
      alert("Order Number is empty");
    }
  };

  addFreeSugar = (e) => {
    e.preventDefault();
    const free_product_order_no = this.free_product_order_no.value;
    const free_product_qty = this.free_product_qty.value;
    // console.log(" orderNumber:: " + free_product_order_no);
    if (free_product_order_no && free_product_qty) {
      ordersNew.once("value", (ordersList) => {
        let ordersListValue = ordersList.val();
        let order_matched = false;
        let item_already_added = false;
        let item_id = 1300110099;
        const updateOrderKey =
          free_product_order_no.replace("/", "-") + "-" + item_id;
        Object.keys(ordersListValue).forEach((itemKey) => {
          if (
            ordersListValue[itemKey] &&
            ordersListValue[itemKey].order_number == free_product_order_no &&
            item_id == ordersListValue[itemKey].item_id
          ) {
            // console.log(`OrderDetails of ${itemKey} Object:: ${ordersListValue[itemKey]}` );
            item_already_added = true;
            ordersNew.child(itemKey).update({ order_qty: free_product_qty });
            alert(
              "Free Sugar already added::: updated quantity to " +
                free_product_qty
            );
          }
        });
        if (!item_already_added) {
          Object.keys(ordersListValue).forEach((itemKey) => {
            // console.log(`OrderDetails of ${itemKey} Object:: ${ordersListValue[itemKey]}`);
            if (order_matched) return;
            if (
              ordersListValue[itemKey] &&
              ordersListValue[itemKey].order_number == free_product_order_no
            ) {
              order_matched = true;
              ordersListValue[itemKey].apmc_charge = "0";
              ordersListValue[itemKey].dealer_price = "0";
              ordersListValue[itemKey].item_disc = "FREE SUGAR (3KG) - 3 kg";
              ordersListValue[itemKey].item_id = item_id;
              ordersListValue[itemKey].net_amt = "0";
              ordersListValue[itemKey].price = "0";
              ordersListValue[itemKey].net_amt = "0";
              ordersListValue[itemKey].total_amt = "0";
              ordersListValue[itemKey].transport_charge = "0";
              ordersListValue[itemKey].order_qty = free_product_qty;
              ordersNew
                .child(updateOrderKey)
                .set(ordersListValue[itemKey])
                .then(function () {
                  alert(
                    "Successfully added free Product::: " +
                      item_id +
                      " to " +
                      updateOrderKey
                  );
                })
                .catch(function (error) {
                  alert("Data could not be saved." + error);
                });
            }
          });
        }
        if (!order_matched && !item_already_added) {
          const retailer_id = free_product_order_no.slice(
            0,
            free_product_order_no.indexOf("/")
          );
          ordersHistory.child(retailer_id).once("value", (ordersList) => {
            ordersListValue = ordersList.val();
            const updateOrderKey =
              free_product_order_no.replace("/", "-") + "-" + item_id;
            Object.keys(ordersListValue).forEach((itemKey) => {
              // console.log(`OrderDetails of ${itemKey} Object:: ${ordersListValue[itemKey]}`);
              if (order_matched) return;
              if (
                ordersListValue[itemKey] &&
                ordersListValue[itemKey].order_number == free_product_order_no
              ) {
                order_matched = true;
                ordersListValue[itemKey].apmc_charge = "0";
                ordersListValue[itemKey].dealer_price = "0";
                ordersListValue[itemKey].item_disc = "FREE SUGAR (3KG)";
                ordersListValue[itemKey].item_id = item_id;
                ordersListValue[itemKey].net_amt = "0";
                ordersListValue[itemKey].price = "0";
                ordersListValue[itemKey].net_amt = "0";
                ordersListValue[itemKey].total_amt = "0";
                ordersListValue[itemKey].transport_charge = "0";
                ordersListValue[itemKey].order_qty = free_product_qty;
                ordersHistory
                  .child(retailer_id)
                  .child(updateOrderKey)
                  .set(ordersListValue[itemKey])
                  .then(function () {
                    alert(
                      "Successfully added free Product::: " +
                        item_id +
                        " to " +
                        updateOrderKey
                    );
                  })
                  .catch(function (error) {
                    alert("Data could not be saved." + error);
                  });
              }
            });
          });
        }
      });
    } else {
      alert("Order Number or qty is empty");
    }
  };

  handleRetailerAddress = (e) => {
    e.preventDefault();
    var retailer_id = this.retailer_address_id.value;
    if (retailer_id) {
      // console.log("RetailerID::: " + retailer_id);

      retailerDetails.child(retailer_id).once("value", (retailer_details) => {
        retailer_details = retailer_details.val();
        // console.log(`Retailer details: ${retailer_details} RetailerID: ${retailer_details.retailer_id}`);
        if (retailer_details) {
          retailer_details.user_name = this.retailer_id.value;
          retailer_details.address1 = this.address1.value;
          retailer_details.address2 = this.address2.value;
          retailer_details.city = this.city.value;
          retailer_details.area = this.area.value;
          retailer_details.shop_name = this.shop_name.value;
          retailer_details.latitude = this.latitude.value;
          retailer_details.longitude = this.longitude.value;

          // console.log("Updated new retailer details::" + retailer_details);
          retailerDetails
            .child(retailer_id)
            .set(retailer_details)
            .then(function () {
              alert(
                "Successfully added the new addresss of retailer::: " +
                  retailer_id
              );
            })
            .catch(function (error) {
              alert("Data could not be saved." + error);
            });
        } else {
          alert(`Retailer ID is invalid`);
        }
      });
    } else {
      alert("Retailer ID is empty");
    }
  };

  handleDealers = (e) => {
    e.preventDefault();
    var store_series = this.store_series.value;
    var id2 = this.corres_id.value;
    var price = this.dealer_price.value;
    var data = {
      dealer_price: price,
    };
    database
      .database()
      .ref("Product_Master_New/" + store_series + "/" + id2)
      .update(data);

    alert(
      "successfully updated the dealer price of " +
        id2 +
        " of store series " +
        store_series
    );
  };

  changadd() {
    var userid = document.getElementById("phoneno").value;
    database
      .database()
      .ref("Retailer_Master_New/" + userid)
      .once("value")
      .then(function (datasnapshort) {
        document.getElementById("address1").value =
          datasnapshort.val().address1;
        document.getElementById("address2").value =
          datasnapshort.val().address2;
        document.getElementById("city").value = datasnapshort.val().city;
        document.getElementById("area").value = datasnapshort.val().area;
        document.getElementById("shop_name").value =
          datasnapshort.val().shop_name;
        document.getElementById("latitude").value =
          datasnapshort.val().latitude;
        document.getElementById("longitude").value =
          datasnapshort.val().longitude;
      });
  }

  myData() {
    var value1 = document.getElementById("selected1").value;
    database
      .database()
      .ref("Product_Master_New/" + value1)
      .on("value", (snapshot) => {
        var child = Object.keys(snapshot.val());

        for (var i = 0; i < child.length; i++) {
          var k = child[i];
          var select = document.getElementById("selected2");
          var newoption = document.createElement("OPTION");
          var newoptionval = document.createTextNode(k);
          newoption.appendChild(newoptionval);
          newoption.setAttribute("value", k);
          select.insertBefore(newoption, select.lastChild);
        }
      });
  }
  dealerprice() {
    var value1 = document.getElementById("selected1").value;
    var dealer = document.getElementById("selected2").value;
    database
      .database()
      .ref("Product_Master_New/" + value1 + "/" + dealer)
      .on("value", (snapshot) => {
        var dealerprice = snapshot.val().dealer_price;
        // console.log(dealerprice);
        document.getElementById("dealer_price").value = dealerprice;
      });
  }
  randompass() {
    var random = Math.floor(Math.random() * 1000000 + 1);
    document.getElementById("pass").value = random;
  }
  
  render() {
    const { maximum } = this.state;

    return (
      <>
        <NavBar />
        <br />
        <br />

        <div className="container" style={{ padding: "3em" }}>
          <h1 className="text-center"> Custom Portal </h1>
          <form className="form-group" style={{ marginTop: "2em" }}>
            <Breadcrumb>
              <Breadcrumb.Item></Breadcrumb.Item>
            </Breadcrumb>

            <Tab.Container id="left-tabs-example" defaultActiveKey="first">
              <Row>
                <Col sm={3}>
                  <Nav variant="pills" className="flex-column">
                    <Nav.Item>
                      <Nav.Link eventKey="first">
                        Create Retailer password
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="second">Update Order Number</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="third">Add Free Products</Nav.Link>
                    </Nav.Item>
                    <Nav.Item id="retailer_phone_number">
                      <Nav.Link eventKey="fourth">
                        Retailer's Phone No. update
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item id="unapproved_retailers">
                      <Nav.Link eventKey="fifth">
                        Move UnApproved retailers
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="sixth">
                        Update Retailers address
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="seventh">
                        Add Salesman Details
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="eight">Update dealer price</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="nine">Wallet Details</Nav.Link>
                    </Nav.Item>
                    <Nav.Item id="retailer_secondary_phone_number">
                      <Nav.Link eventKey="tenth">
                        Retailer's Secondary Phone No. update
                      </Nav.Link>
                    </Nav.Item>
                  </Nav>
                </Col>
                <Col sm={9}>
                  <Tab.Content>

                    <Tab.Pane eventKey="first" style={{ width: "500px" }}>
                      <div style={{ padding: "1em" }}>
                        <div className="container">
                          <form onSubmit={this.handleRetailerPassword}>
                            <h3 className="text-center">
                              <b>Create Retailer Details</b>
                            </h3>
                            <div className="form-group">
                              <label htmlFor="old_phone_number">
                                Enter Retailer Phone Number
                              </label>
                              <input
                                className="form-control"
                                ref={(input) => (this.retailer_phone = input)}
                                //  options={this.state.retailerList}
                                onChange={this.randompass}
                                required
                              />
                            </div>
                            <div className="form-group">
                              <label htmlFor="new_phone_number">Password</label>
                              <input
                                className="form-control"
                                type="number"
                                id="pass"
                                ref={(input) =>
                                  (this.retailer_password = input)
                                }
                                required
                                readOnly
                              />
                            </div>
                            <div>
                              <button type="submit" className="btn btn-info">
                                Submit
                              </button>
                            </div>
                          </form>
                        </div>
                      </div>
                    </Tab.Pane>

                    <Tab.Pane eventKey="second" style={{ width: "500px" }}>
                      <div style={{ padding: "1em" }}>
                        <div className="container">
                          <form onSubmit={this.updateOrderNumber}>
                            <h3 className="text-center">
                              <b>Update Order Number (Only Today's Order)</b>
                            </h3>
                            <div className="form-group">
                              <label htmlFor="old_order_number">
                                Current Order Number
                              </label>
                              <input
                                className="form-control"
                                ref={(input) => (this.old_order_number = input)}
                                required
                              />
                            </div>
                            <div className="form-group">
                              <label htmlFor="new_order_number">
                                New Order Number
                              </label>
                              <input
                                className="form-control"
                                ref={(input) => (this.new_order_number = input)}
                                required
                              />
                            </div>
                            <div>
                              <button type="submit" className="btn btn-info">
                                Submit
                              </button>
                            </div>
                          </form>
                        </div>
                      </div>
                    </Tab.Pane>

                    <Tab.Pane eventKey="third" style={{ width: "500px" }}>
                      <div style={{ padding: "1em" }}>
                        <div className="container">
                          <form onSubmit={this.addFreeSugar}>
                            <h3 className="text-center">
                              <b>Add Free Sugar to Orders</b>
                            </h3>
                            <div className="form-group">
                              <label htmlFor="old_order_number">
                                Order Number
                              </label>
                              <input
                                className="form-control"
                                ref={(input) =>
                                  (this.free_product_order_no = input)
                                }
                                required
                              />
                            </div>
                            <div className="form-group">
                              <label htmlFor="new_order_number">Quantity</label>
                              <input
                                className="form-control"
                                ref={(input) => (this.free_product_qty = input)}
                                required
                              />
                            </div>
                            <div>
                              <button type="submit" className="btn btn-info">
                                Submit
                              </button>
                            </div>
                          </form>
                        </div>
                      </div>
                    </Tab.Pane>

                    <Tab.Pane eventKey="fourth" style={{ width: "500px" }}>
                      <div style={{ padding: "1em" }}>
                        <RetailerPhoneUpdate />
                      </div>
                    </Tab.Pane>

                    <Tab.Pane eventKey="fifth">
                      <div style={{ padding: "1em" }}>
                        <div className="container">
                          <h3 className="text-center">
                            <b></b>
                          </h3>
                          <Tab.Container
                            id="left-tabs-example"
                            defaultActiveKey="sub-first"
                          >
                            <Row>
                              <Col sm={6}>
                                <Nav variant="pills" className="flex-column">
                                  <Nav.Item>
                                    <Nav.Link eventKey="sub-first">
                                      Assign Retailer to Salesman
                                    </Nav.Link>
                                  </Nav.Item>
                                </Nav>
                              </Col>
                              <Col sm={6}>
                                <Nav variant="pills" className="flex-column">
                                  <Nav.Item>
                                    <Nav.Link eventKey="sub-second">
                                      Move retailer to salesman
                                    </Nav.Link>
                                  </Nav.Item>
                                </Nav>
                              </Col>
                            </Row>
                            <Row>
                              <Col sm={12}>
                                <Tab.Content>
                                  <Tab.Pane eventKey="sub-first">
                                    <form
                                      onSubmit={this.assignRetailerSalesman}
                                    >
                                      <div className="form-group">
                                        <label htmlFor="appreove_retailer">
                                          Select the retailer to approve
                                        </label>
                                        <input
                                          className="form-control"
                                          ref={(input) =>
                                            (this.retailer_id = input)
                                          }
                                        />
                                      </div>
                                      <div className="form-group">
                                        <label htmlFor="new_phone_number">
                                          Select SE phone number
                                        </label>
                                        <input
                                          className="form-control"
                                          ref={(input) =>
                                            (this.salesman_toassign = input)
                                          }
                                        />
                                      </div>
                                      <div>
                                        <button
                                          type="submit"
                                          className="btn btn-info"
                                        >
                                          Submit
                                        </button>
                                      </div>
                                    </form>
                                  </Tab.Pane>

                                  <Tab.Pane eventKey="sub-second">
                                    <form onSubmit={this.handleNewSalesman}>
                                      <div className="form-group">
                                        <label htmlFor="old_phone_number">
                                          Enter Old SE Phone Number
                                        </label>
                                        <Select
                                          options={this.state.salesmanList}
                                        />
                                      </div>

                                      <div className="form-group">
                                        <label htmlFor="new_phone_number">
                                          Enter New SE Phone Number
                                        </label>
                                        <Select
                                          options={this.state.salesmanList}
                                        />
                                      </div>
                                      <div>
                                        <button
                                          type="submit"
                                          className="btn btn-info"
                                        >
                                          Submit
                                        </button>
                                      </div>
                                    </form>
                                  </Tab.Pane>
                                </Tab.Content>
                              </Col>
                            </Row>
                          </Tab.Container>
                        </div>
                      </div>
                    </Tab.Pane>

                    <Tab.Pane eventKey="sixth">
                      <div style={{ padding: "1em" }}>
                        <div className="container">
                          <form>
                            <h3 className="text-center">
                              <b>UPDATE RETAILERS ADDRESS</b>
                            </h3>
                            <div className="form-group">
                              <label htmlFor="old_phone_number">
                                Enter Phone number
                              </label>
                              <input
                                id="phoneno"
                                className="form-control"
                                type="number"
                                ref={(input) =>
                                  (this.retailer_address_id = input)
                                }
                                required
                              />
                              <button
                                className="btn btn-info"
                                onClick={this.changadd}
                              >
                                Get address{" "}
                              </button>
                            </div>

                            <div className="form-group">
                              <label htmlFor="address1">
                                Enter New address
                              </label>
                              <input
                                id="address1"
                                className="form-control"
                                type="text"
                                ref={(input) => (this.address1 = input)}
                                required
                              />
                            </div>
                            <div className="form-group">
                              <label htmlFor="address2">
                                Enter New address 2
                              </label>
                              <input
                                id="address2"
                                className="form-control"
                                type="text"
                                ref={(input) => (this.address2 = input)}
                                required
                              />
                            </div>
                            <div className="form-group">
                              <label htmlFor="city"> city</label>
                              <input
                                id="city"
                                className="form-control"
                                type="text"
                                ref={(input) => (this.city = input)}
                                required
                              />
                            </div>
                            <div className="form-group">
                              <label htmlFor="area">area</label>
                              <input
                                id="area"
                                className="form-control"
                                type="text"
                                ref={(input) => (this.area = input)}
                                required
                              />
                            </div>
                            <div className="form-group">
                              <label htmlFor="shop_name"> shop</label>
                              <input
                                id="shop_name"
                                className="form-control"
                                type="text"
                                ref={(input) => (this.shop_name = input)}
                                required
                              />
                            </div>
                            <div className="form-group">
                              <label htmlFor="shop_name"> latitude</label>
                              <input
                                id="latitude"
                                className="form-control"
                                type="text"
                                ref={(input) => (this.latitude = input)}
                                required
                              />
                            </div>
                            <div className="form-group">
                              <label htmlFor="longitude"> longitude</label>
                              <input
                                id="longitude"
                                className="form-control"
                                type="text"
                                ref={(input) => (this.longitude = input)}
                                required
                              />
                            </div>
                            <div>
                              <button
                                className="btn btn-info"
                                onClick={this.handleRetailerAddress}
                              >
                                Submit
                              </button>
                            </div>
                          </form>
                        </div>
                      </div>
                    </Tab.Pane>

                    <Tab.Pane eventKey="seventh">
                      <SalesmanCreate />
                    </Tab.Pane>

                    <Tab.Pane eventKey="eight">
                      <div style={{ padding: "1em" }}>
                        <div className="container">
                          <form onSubmit={this.handleDealers} className="form1">
                            <h3 className="text-center">
                              <b>Update dealer price</b>
                            </h3>
                            <div className="form-group">
                              <label htmlFor="Store series">
                                select store series
                              </label>
                              <select
                                id="selected1"
                                onChange={this.myData}
                                className="form-control"
                                ref={(input) => (this.store_series = input)}
                              >
                                <option value="">--Select--</option>
                                <option value="A">A</option>
                                <option value="B">B</option>
                                <option value="P">P</option>
                                <option value="N">N</option>
                                <option value="T">T</option>
                              </select>
                            </div>

                            <div className="form-group">
                              <label htmlFor="Corresponding IDs">
                                Corresponding IDs
                              </label>
                              <select
                                id="selected2"
                                onChange={this.dealerprice}
                                className="form-control"
                                ref={(input) => (this.corres_id = input)}
                              >
                                <option value="">--Select--</option>
                              </select>
                            </div>

                            <div className="form-group">
                              <label htmlFor="dealer-price">Dealer price</label>
                              <input
                                className="form-control"
                                type="text"
                                id="dealer_price"
                                ref={(input) => (this.dealer_price = input)}
                                required
                              />
                            </div>

                            <div>
                              <button
                                type="submit"
                                onSubmit={this.reset1}
                                className="btn btn-info"
                              >
                                Submit
                              </button>
                            </div>
                          </form>
                        </div>
                      </div>
                    </Tab.Pane>

                    <Tab.Pane eventKey="nine" style={{ width: "500px" }}>
                      <WalletDetails />
                    </Tab.Pane>
                    <Tab.Pane eventKey="tenth" style={{ width: "500px" }}>
                      <div style={{ padding: "1em" }}>
                        <RetailerSecondaryPhoneUpdate />
                      </div>
                    </Tab.Pane>        
                  </Tab.Content>
                </Col>
              </Row>
            </Tab.Container>
          </form>
        </div>
        <div style={{ position: "fixed", bottom: "0", width: "100%" }}>
          <FooterComponent />
        </div>
      </>
    );
  }
}

export default CustomUpdates;

