import React,{useEffect, useState} from 'react'
import database from '../../../service/firebaseConfig';
import { ORDERING_BASE_URL } from '../../../service/servicesConfig';


const Retailers_Data = database.database().ref(`Retailer_Master_New`);
const Super_Coin_Transactions = database.database().ref(`Super_Coin_Transactions`);
const Superzop_Kredit_Retailers = database.database().ref(`Superzop_Kredit_Retailers`);
function RetailerPhoneUpdate() {

    
    const [originalPhoneNo,setOriginalPhoneNo] = useState("")
    const [updatePhoneNo,setUpdatePhoneNo]= useState("")
    const [PushData, setPushData] = useState(false)

    const pushRetailer=()=>{
        if(originalPhoneNo!==""){
        const loadObject={}
        Retailers_Data.child(originalPhoneNo).once('value', retailer_details => {
            retailer_details=retailer_details.val();
            console.log("Retailer Details"+retailer_details);
            if(retailer_details) {
              if(retailer_details.retailer_id==0 || retailer_details.verified=='N') {
                console.log(retailer_details.retailer_id + retailer_details.verified);
                alert('Retailer ID is not verified ::: ' + originalPhoneNo);
                return;
              }
              retailer_details.user_name=updatePhoneNo;
              retailer_details.phone = updatePhoneNo;

              retailer_details.old_phone = retailer_details.old_phone? retailer_details.old_phone + "," + originalPhoneNo: originalPhoneNo;
              console.log("Updated new retailer details::"+retailer_details);

              let old_phoneArray = retailer_details.old_phone.split(",");
              if (old_phoneArray.includes(updatePhoneNo)) {
                alert("The phone number is already registered you cannot changed it");
                return;
              }

              Superzop_Kredit_Retailers.child(originalPhoneNo).once('value', retailer_new => {
                console.log(retailer_new);
                if(retailer_new.val()){
                  alert('The phone number has SuperKredit. Cannot be changed::: ' + originalPhoneNo);
                } else {
                  // Validate the new phone number using the API
                  fetch(
                    ORDERING_BASE_URL+'/api/superzop/admin/getRetailerPhoneNumberDetails?phone_number='+updatePhoneNo
                  )
                    .then((response) => response.json())
                    .then((data) => {
                      if (data.data==true) {
                        alert("Number already registered previously");
                      } else {
                        Retailers_Data.child(updatePhoneNo).once('value', retailer_new => {
                          if(retailer_new.val()){
                            alert('The new phone number is already present::: ' + updatePhoneNo);
                          } else {
                            Retailers_Data.child(updatePhoneNo).set(retailer_details).then(function(){
                                Retailers_Data.child(originalPhoneNo).remove();
                                Super_Coin_Transactions.child(originalPhoneNo).once('value', supercoins_details => {
                                  if(supercoins_details){
                                    supercoins_details=supercoins_details.val();
                                    Super_Coin_Transactions.child(updatePhoneNo).set(supercoins_details).then(function(){
                                      alert('Successfully updated phoneNo and assigned SuperCoins successfully::: ' + updatePhoneNo+ " for old phone number ::: "+originalPhoneNo);
                                    });
                                  } else {
                                    alert('Successfully updated phoneNo successfully::: ' + updatePhoneNo+ " for old phone number ::: "+originalPhoneNo);
                                  }
                                });
                              }).catch(function(error) {
                                alert("Data could not be saved." + error);
                              });
                          }
                        });
                      }
                    }).catch((error) => {
                      console.error("Error:", error);
                    });
                }
              });

              setOriginalPhoneNo("");
              setUpdatePhoneNo("");
            } else {
              alert(`Retailer ID is not present`)
            }
          });
        } else {
          alert(`Enter a phone number to update`);
        }
    }
    

    return (
        <div className='container'>
        <form>
        <h3 className='text-center'><b>Update Retailer phone number</b></h3>
        <div className='form-group'>
            <label htmlFor='old_phone_number'>Enter Current Phone Number</label>
            <input
            className='form-control'
            value={originalPhoneNo}
            onChange={(e)=>{setOriginalPhoneNo(e.target.value)}}
            />
            </div>
            <div className='form-group'>
            <label htmlFor='new_phone_number'>Enter New Phone Number</label>
            <input
                className='form-control'
                value={updatePhoneNo}
                onChange={(e)=>setUpdatePhoneNo(e.target.value)}
                />
        </div>
        <div>
            <button onClick={()=>pushRetailer()} className="btn btn-info">Submit</button>
        </div>
        </form>
        </div>
    )
}

export default RetailerPhoneUpdate

