import React,{useEffect, useState} from 'react'
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Button,
    Box,
    Paper,
    Typography,
    TextField,
    FormControl,
    InputLabel,
    Select,
    MenuItem
  } from '@mui/material';
import { getUserType, validateIsAdminUser } from "../../service/credentials";
import { LOGGED_USER_EMAIL } from "../../constants/allConstants";

// Edit and Add Pre-existing Modal
import PurchaseEditModal from "./NewPurchaseEditModal";
import PurchaseViewModal from "./NewPurchaseViewModal";
import { Divider } from "@material-ui/core";
import { toast } from "react-toastify";
import baseUrl from "../../service/servicesConfig";

// Fetch GST % from the firebase
import database from "../../service/firebaseConfig";
import { parse } from "csv";
const priceDetailsDB = database.database().ref(`Price_Details/mumbai/items`);
const SuppliersDB = database.database().ref(`Suppliers`);

 toast.configure({
    autoClose: 4000,
    draggable: true,
    pauseOnHover: true,
    position: toast.POSITION.TOP_LEFT,
  });

function NewListPurchaseAddAndEdit({selectedPO_Item, handleCloseForViewOrEdit, itemCode,selectedDate,selectedMonth,selectedYear, grnEntryClose}) {

const [render_selectedPO_Item, setSelectedPO_Item] = useState([...selectedPO_Item]); // creating just for re-rendering purposes when bag Purchase state is updated

const [addEditItemDetails, setAddItemDetails] = useState(null);

 const [addGRNModal, setViewModal] = useState(false);
 // const [allAddItemDetails, setAllAddItemDetails] = useState([]);  // mutate onClick of 'save' changes in this object inside Add Modal

 const [addEditModal, setEditModal] = useState(false);
 const [allEditItemDetails, setAllEditItemDetails] = useState([]);

 const[shouldAddActive,setAddActive] = useState(true)

 const [suppliersList, setSuppliersList] = useState([]);
 const [supplierName,  setSupplierName] = useState(selectedPO_Item[0].supplier_name || "");
 const [supplierGroup, setSupplierGroup] = useState('');

 const [po_date, setPO_date] = useState(selectedPO_Item[0].purchased_date || '')

 const [getMinimumGstPercentage,setGetMinimumGstPercentage] = useState(0)

 const [invoiceDate, setInvoiceDate] = useState(()=>{
  if(selectedPO_Item[0].invoice_date && selectedPO_Item[0].invoice_date !== "")
  { // if present then return the date obtained form the API
    const [year, month, day] = selectedPO_Item[0].invoice_date.split('-');
    return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;
  }
  else{ // returns today's date
    const today = new Date();
    return `${today.getFullYear()}-${String(today.getMonth() + 1).padStart(2, '0')}-${String(today.getDate()).padStart(2, '0')}`;
  }
 });

 const [materialReceivingDt, setMaterialReceivingDt] = useState(() => {
  if (selectedPO_Item[0].grn_date && selectedPO_Item[0].grn_date !== "") {
    // if present then return the date obtained form the API
    const [year, month, day] = selectedPO_Item[0].grn_date.split("-");
    return `${year}-${month}-${day}`;
  } else {
    // returns today's date
    const today = new Date();
    return `${today.getFullYear()}-${String(today.getMonth() + 1).padStart(2, '0')}-${String(today.getDate()).padStart(2, '0')}`;
  }
});

const [poAmount, setPoAmount] = useState(0);

 // Generic values
 const [genericValues, setGenericValues] = useState({
  invoice_date:selectedPO_Item[0].invoice_date,
  invoice_number:selectedPO_Item[0].invoice_number,
  payment_terms:selectedPO_Item[0].payment_terms,
  delivery_period:selectedPO_Item[0].delivery_in_days,
  supplier_name:selectedPO_Item[0].supplier_name,
  supplier_id:selectedPO_Item[0].supplier_id,
  supplier_email:selectedPO_Item[0].supplier_email,
  purchased_date:selectedPO_Item[0].purchased_date,
  poAmount:poAmount
 })
 


 // check for item values updation in Add or Edit
 // const [addSaveClick,setAddSaveClick] = useState(false)
 const [editSaveClick,setEditSaveClick] = useState(false)

 


 console.log("supplierGroup-->",supplierGroup)

// selectedPO_Item is the items present inside that specific PO number
// Adding temporary index to uniquely identify each object/Item in the array
 let count=0
 for(let objects of selectedPO_Item)
 {
    objects.indexVal=count
    count+=1
 }




 console.log("selectedPO_Item-->",selectedPO_Item)

  const handleAddModal = (item) => {

     // check for GRN Number if GRN number is present even for 1 item then disable the edit PO button
     let shouldOpenEditPO = true
     for(let objects of selectedPO_Item)
     {
         if(objects.grn_no)
         {
           shouldOpenEditPO=false
           break 
         }
     }
 
     if(shouldOpenEditPO)
     {

        setAddItemDetails(item);
        setViewModal(true);
      }
    else{
      window.alert("This Cannot be Opened because the GRN is done for this PO")
    }

  };

  const handleEditModal = (item) => {

    setAddItemDetails(item);
    setEditModal(true);
  
  };

  // console.log("ARRAYYY ADD-->",allAddItemDetails)
  // console.log("ARRAYYY EDIT-->",allEditItemDetails)




  const materialUiformatDate = (val) => {

    if(!val){
        return ''
    }

    if (typeof val === 'string' && val.includes('-')) {
        // Assuming the input is in "DD-MM-YYYY" format
        const [year, month, day] = val.split('-');
        return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;
      }

    // On Change the value will be
    // Wed Jul 17 2024 05:30:00 GMT+0530 (India Standard Time) {}

    if (!(val instanceof Date)) {
      val = new Date(val);
    }

    // Ensure month and day are padded with leading zeros if necessary
    const month = String(val.getMonth() + 1).padStart(2, '0');
    const day = String(val.getDate()).padStart(2, '0');
    return `${val.getFullYear()}-${month}-${day}`;
  };

 

  const fetchSuppliers = () => {
    const suppliers = [];
    const sortSupplierList = [];
    SuppliersDB.once("value", (snapShot) => {
      const suppliersList = snapShot.val();
      Object.keys(suppliersList).forEach((supplier) => {
        sortSupplierList.push({
          name: suppliersList[supplier].supplier_name,
          email: suppliersList[supplier].EmailID,
          key: suppliersList[supplier].supplier_id,
          fssai: (suppliersList[supplier].fssai ? suppliersList[supplier].fssai : suppliersList[supplier].FSSAI),
          gstin:  (suppliersList[supplier]['GST_Identification_Number_(GSTIN)'] ? suppliersList[supplier]['GST_Identification_Number_(GSTIN)'] : suppliersList[supplier]["Gstin"] ),
          phone: suppliersList[supplier].MobilePhone,
          address: suppliersList[supplier].Billing_Address,
          street: suppliersList[supplier].Billing_Street2,
          city: suppliersList[supplier].Billing_City,
          pin: suppliersList[supplier].Billing_Code,
          state: suppliersList[supplier].Billing_State,
          supplierGroup: suppliersList[supplier].supplierGroup
        });
      });
      sortSupplierList.sort();
      sortSupplierList.forEach((items) => {
        suppliers.push({
          value: items.name,
          label: items.name,
          email: items.email,
          key: items.key,
          fssai: items.fssai,
          gstin: items.gstin,
          phone: items.phone,
          address: items.address,
          street: items.street,
          city: items.city,
          pin: items.pin,
          state: items.state,
          supplierGroup:items.supplierGroup
        });
      });
    }).then(() => {
      setSuppliersList(suppliers);
      const selectedSupplier = suppliers.find(supplier => supplier.value === selectedPO_Item[0].supplier_name);
  
      if (selectedSupplier) {
      // setProductEditObject({
      //     ...productEditObject,
      //     supplier_name: selectedSupplier.value,
      //     supplier_id: selectedSupplier.key,
      //     supplier_email: selectedSupplier.email,
      //     bill_address: selectedSupplier.address,
      //     bill_city: selectedSupplier.city,
      //     bill_state: selectedSupplier.state,
      //     bill_street: selectedSupplier.street,
      //     bill_pin: selectedSupplier.pin,
      //     bill_fssai: selectedSupplier.fssai,
      //     bill_gstin: selectedSupplier.gstin,
      //     bill_phone: selectedSupplier.phone,
      // });
      setSupplierGroup(selectedSupplier.supplierGroup)
      setSupplierName(selectedSupplier.value)
      setGenericValues({...genericValues,supplier_email:selectedSupplier.email})
      }
    });
  };

 
  // Finding minmum non zero GST number among all
  async function findMinimumGstPercentage(selectedPO_Item) {
    let minimumGstPercentage = 100;

    try{

    const updatedSelectedPO_Item = await Promise.all(selectedPO_Item.map(async (item, index) => {
      const snapshot = await priceDetailsDB.child(`${item.article_code}`).once('value');
      const prices = snapshot.val();
      const gstPercentage = parseFloat(prices.gst_per) || 0;
      const apmcPercentage = parseFloat(prices.apmc_per) || 0;

        if (gstPercentage < minimumGstPercentage && gstPercentage !== 0) {
          minimumGstPercentage = gstPercentage;
        }

        selectedPO_Item[index]["gstPerFromFb"]=gstPercentage
        selectedPO_Item[index]["apmcPerFromFb"]=apmcPercentage

        return {
          ...item,
          gstPerFromFb: gstPercentage,
          apmcPerFromFb: apmcPercentage
        };

      }));

      
      if (minimumGstPercentage === 100) {
        minimumGstPercentage = 0;
      }
      
      setSelectedPO_Item([...updatedSelectedPO_Item])
      return minimumGstPercentage;
    } catch (error) {
      console.error("Error fetching GST percentages:", error);
      return 0; // or handle the error as appropriate
    }
  }

   // Usage
  useEffect(()=>{
    findMinimumGstPercentage(selectedPO_Item).then(minimumGstPercentage => {
      console.log("Minimum GST Percentage:", minimumGstPercentage);
      // setGetMinimumGstPercentage(minimumGstPercentage)
      genericValuesCalculation(Number(minimumGstPercentage),selectedPO_Item)
    }).catch(error=>{console.log("Some Error Occured at Firebase while fetching GST Percentage values",error)});
  },[])


  // useEffect(()=>{
  
  //   if(allAddItemDetails.length>0 && shouldAddActive)
  //   {
  //   genericValuesCalculation(parseFloat(getMinimumGstPercentage),allAddItemDetails)
  //   }
  //   else if(allEditItemDetails.length>0)
  //   {
  //     genericValuesCalculation(parseFloat(getMinimumGstPercentage),allEditItemDetails)
  //   }
  //   else
  //   {
  //     genericValuesCalculation(parseFloat(getMinimumGstPercentage),selectedPO_Item)
  //   }
    
  // },[containerCharges,loadingCharges,otherCharges,tds,getMinimumGstPercentage])



const genericValuesCalculation=(minimGstPercentage,listOfObjects)=>{

  // const containerCharges = parseFloat(selectedPO_Item[0].container_packing_charges) || 0;
  // const loadingCharges = parseFloat(selectedPO_Item[0].loading_unloading_charges) || 0;
  // const otherCharges = parseFloat(selectedPO_Item[0].other_charges) || 0;
  // const tds = parseFloat(selectedPO_Item[0].tds) || 0;

    let calculatedTotalAmount=0
    let calculatedTotalGstAmount=0
    let calculatedTotalAPMC_charges=0
    let calculatedTotalAPMC_chargesForGst=0

    for(let object of listOfObjects)
    {   
        // calculates totalWeight
        const totalWeight=parseFloat(object.outer_size || 0)*(parseFloat(object.bags_received || 0) || parseFloat(object.bag_purchased || 0))
        const calcualtedItemAmount = parseFloat(totalWeight)*parseFloat(object.purchase_price || 0)
        calculatedTotalAmount+=calcualtedItemAmount

        // calulating Generic GST Total Amount
        let calculatedGstValue=0
        if(object.gstPerFromFb && parseFloat(object.gstPerFromFb)>0) // excluding the item having GST as 0
        {
          calculatedGstValue=(calcualtedItemAmount/100)*minimGstPercentage
        }
        calculatedTotalGstAmount+=calculatedGstValue

        // calculates sum of all apmc
      
        if(object.gstPerFromFb && parseFloat(object.gstPerFromFb)>0)
        { // excluding the item having GST as 0 not in including it in the calculation for apmcCharge
            if(object.apmcPerFromFb)
            {
              calculatedTotalAPMC_chargesForGst+=(parseFloat(object.apmcPerFromFb)*parseFloat(calcualtedItemAmount))/100
            }
            else{
              calculatedTotalAPMC_chargesForGst+=parseFloat(object.apmc_charges || 0)
            }
        }

        calculatedTotalAPMC_charges+=(parseFloat(object.apmcPerFromFb)*parseFloat(calcualtedItemAmount))/100

    }

    calculatedTotalGstAmount+=((Number(parseFloat(calculatedTotalAPMC_chargesForGst).toFixed(2))/100)*minimGstPercentage)//+ (parseFloat(loadingCharges)/100)*minimGstPercentage  + (parseFloat(otherCharges)/100)*minimGstPercentage + (parseFloat(containerCharges)/100)*minimGstPercentage
    // setTotalAPMC(parseFloat(calculatedTotalAPMC_charges).toFixed(2))
    // setTotalAmount(parseFloat(calculatedTotalAmount).toFixed(2))
    // setGstCharges(parseFloat(calculatedTotalGstAmount).toFixed(2))

    const grossAmountCalculation = Number(parseFloat(calculatedTotalAmount).toFixed(2))  + Number(parseFloat(calculatedTotalAPMC_charges).toFixed(2)) // + parseFloat(containerCharges) + parseFloat(loadingCharges) + parseFloat(otherCharges)
    const PO_amountCalculation = Number(parseFloat(grossAmountCalculation).toFixed(2)) + Number(parseFloat(calculatedTotalGstAmount).toFixed(2)) // - parseFloat(tds)

    // setGrossAmount(parseFloat(grossAmountCalculation).toFixed(2))
    setPoAmount(parseFloat(PO_amountCalculation).toFixed(2))
    genericValues.poAmount=parseFloat(PO_amountCalculation).toFixed(2)
  
}


// update the calculations according to saved new changes for an item
useEffect(()=>{


  function mapBagReceivedStateWithAPIFetchedValue(modifiedItems)
  {

    const updatedSelectedPO_Item = selectedPO_Item.map(api_object => {

      let matchingItem = modifiedItems.find(item => item.indexVal === api_object.indexVal);

        if (matchingItem) {
        
          // Check if bag_purchased values are different
          if (api_object.bag_purchased != matchingItem.bag_purchased)
          {
              return {
                ...api_object,
                bag_purchased: matchingItem.bag_purchased,
                showMark:" *"
              };
              
            }
          }

      return api_object;
    });

  
    setSelectedPO_Item(updatedSelectedPO_Item);
  }




  // if(addSaveClick)
  // {
   // genericValuesCalculation(getMinimumGstPercentage,allAddItemDetails)
  //   setAddSaveClick(false)
  //   mapBagReceivedStateWithAPIFetchedValue(allAddItemDetails)
  // }

  if(editSaveClick)
  {
    genericValuesCalculation(getMinimumGstPercentage,allEditItemDetails)
    setEditSaveClick(false)
    mapBagReceivedStateWithAPIFetchedValue(allEditItemDetails)
  }

},[allEditItemDetails])




const shouldActivateAddFunctionality=()=>{
  // this should be autofetched at the time of Edit and Add
  // setInvoiceNumber(selectedPO_Item[0].invoice_number)


    for(let items of selectedPO_Item)
    {
        // if GRN is not found in any of the items then ADD should be Activated
        // this scenario should not occur it should be either all GRN present or nun
        if(!items.grn_no)
        {
            return true
        }
    }

    // Edit functionality preSet Generic Values for all items it will be same
    // it wil auto load the values in case of Edit
    // setContainerCharges(parseFloat(selectedPO_Item[0].container_packing_charges) || 0)
    // setLoadingCharges(parseFloat(selectedPO_Item[0].loading_unloading_charges) || 0)
    // setOtherCharges(parseFloat(selectedPO_Item[0].other_charges) || 0)
    // setTds(parseFloat(selectedPO_Item[0].tds) || 0)
    

    return false
    }


  useEffect(()=>{
    
   findMinimumGstPercentage(selectedPO_Item).then(minimumGstPercentage => {
      // console.log("Minimum GST Percentage:", minimumGstPercentage);
      // this will trigger for generic calculation
      setGetMinimumGstPercentage(minimumGstPercentage)
      
      // generic value should be calculated only when these values are available for calculation
      // this will trigger for generic calculation when any value of tds,containerCharges,packingCharges,otherCharge is updated
      const shouldActive = shouldActivateAddFunctionality()
      setAddActive(shouldActive)
      fetchSuppliers()

      genericValuesCalculation(Number(minimumGstPercentage),selectedPO_Item)

   }).catch(error=>{console.log("Some Error Occured at Firebase while fetching GST Percentage values",error)});
  },[])



  // takes the list of QR codes and then one by one used it on another API
  // const generateQRForAddOnUpdateClick = async () => {

  //   // check if the all the PO number items has been added in the updated Add Array List as well by the user

  //   let isAllItemsPresent=true

  //   allAddItemDetails.length==0 ? isAllItemsPresent=false : isAllItemsPresent=true
    
  //   for(let objects of allAddItemDetails)
  //   {
  //     if(objects.type==="not-added")
  //     {
  //       isAllItemsPresent=false
  //       break
  //     }
  //   }

    
  //   if (isAllItemsPresent) {

  //     if(!invoiceDate){
  //       return toast("Enter invoice Date", { type: toast.TYPE.ERROR });

  //     }

  //     if(!materialReceivingDt)
  //     {
  //       return toast("Enter Material receiving Date", { type: toast.TYPE.ERROR });
  //     }

  //     if(!invoiceNumber)
  //     {
  //       return toast("Enter Invoice Number", { type: toast.TYPE.ERROR });
  //     }


  //     try {
  //       // Getting the QR From the First API then adding it to the Second API Call

  //       const addPayload = {
  //           supplierName:supplierName,
  //           // purchased_date
  //           // supplier_name
  //           // supplier_id
  //           // delivery_in_days
  //           // payment_terms
  //           grn_no:allAddItemDetails[0].commonGRN_no ,
  //           invoice_amt: totalAmount,
  //           grn_date: materialReceivingDt, // new Date().toISOString().split('T')[0], // yyyy-mm-dd 
  //           invoice_date : invoiceDate,
  //           invoice_number: invoiceNumber, 
  //           container_packing_charges: containerCharges,
  //           loading_unloading_charges: loadingCharges,
  //           other_charges: otherCharges,
  //           total_po_amount: poAmount,
  //           total_gst_charges: gstCharges,
  //           gross_amount: grossAmount,
  //           tds:tds, 
  //           itemsList:[...allAddItemDetails]
  //       }
    
  //       const url = `/api/superzop/commercial/purchasedetails/addeditgrnlist?type=add`;
  //       console.log("allAddItemDetails Check From NewAddGRNModal.js ---->", addPayload);
  
  //       const getQRCodeFromAPI = await baseUrl.post(url, addPayload);
  
  //       if (getQRCodeFromAPI.status === 200) {
  //         const qrCodes = getQRCodeFromAPI.data.item_details;
  
  //         setTimeout(() => {
  //           window.location.reload();
  //         }, 2000);
  
  //         try {
  //           const baseQRGenerationUrl = '/api/bag-qr';
  //           const qrPromises = qrCodes.map(async (qrCodeObj) => {
  //             const QRGenerationUrl = `${baseQRGenerationUrl}?qr_code=${qrCodeObj.qr_code}`;
  //             const qrBody = {
  //               grn_number: allAddItemDetails[0].commonGRN_no, // for all the items it will be same 
  //               grn_date: allAddItemDetails[0].grn_date, // for all the items it will be same 
  //               item_id: qrCodeObj.item_id,
  //               bags_received: qrCodeObj.bags_received,
  //             };
       
  //             return baseUrl.post(QRGenerationUrl, qrBody);
  //           });
   
  //           const qrResponses = await Promise.all(qrPromises);
       
  
  //           // Check if all responses are successful
  //           const allSuccessful = qrResponses.every(response => response.status === 200);
  
  //           if (allSuccessful) {
  //             toast("Saved data to QR Generation Table for all QR codes", {
  //               type: toast.TYPE.SUCCESS,
  //             });
  //           } else {
  //             toast("Some QR code data failed to save", {
  //               type: toast.TYPE.WARNING,
  //             });
  //           }
  //         } catch (error) {
  //           console.error("Error in QR Generation:", error);
  //           toast("Error in QR Generation", {
  //             type: toast.TYPE.ERROR,
  //           });
  //         }
  //       } else {
  //         toast("Failed to get QR codes from API", {
  //           type: toast.TYPE.ERROR,
  //         });
  //       }
  //     } catch (error) {
  //       console.log(error);
  //       toast("Failed to update System Inventory", {
  //         type: toast.TYPE.ERROR,
  //       });
  //     }
  //   } else {
  //     window.alert("Please update all the ADD Items Values");
  //   }
  // };


  // Update On update all Edit items Click
  const updateForEditClick=()=>{


    if(!invoiceDate){
      return toast("Enter invoice Date", { type: toast.TYPE.ERROR });

    }

    if(!materialReceivingDt)
    {
      return toast("Enter Material receiving Date", { type: toast.TYPE.ERROR });
    }

    // if(!invoiceNumber)
    // {
    //   return toast("Enter Invoice Number", { type: toast.TYPE.ERROR });
    // }


    let editPayload={}
    // this means user directly wants to update only generic values
    if (allEditItemDetails.length===0)
    {

      const objList=[]
      for(let object of selectedPO_Item)
      {
        const obj={
          // this should be identified because of new values total_weight_received and grn_date
          // the new values shold not be updated
          type:"non-editable",

          // No changes were made in the items so these values are not required for the calculation
          // outer_size:object.outer_size,
          // bag_purchased:object.bag_purchased,
          // purchase_price:object.purchase_price,
          // apmc_charges:object.apmc_charges,
  
          bags_received:object.bags_received,
          base_price:object.base_price,
          bill_submitted:object.bill_submitted,
          diff_receiving:object.diff_receiving,
          expiry_date:object.expiry_date,
          grn_date: object.grn_date,       //formatDates(new Date()), // new values should not be entered here
          invoice_amt:object.invoice_amt,
          isAdmin:validateIsAdminUser(
            sessionStorage.getItem(LOGGED_USER_EMAIL)
          ),                       // sessionStorage.getItem(LOGGED_USER_EMAIL),
          item_id:object.article_code,
          old_grn_date:object.grn_date, // requires old value
          old_total_weight_received:object.total_weight_received, // requires old value
          payment_date:object.payment_date,
          unique_key:object.uniqueKey,
         // purchased_date:object.purchased_date,
          purchased_date:po_date,
          replacement_brand:object.replacement_brand,
          total_weight_received:object.total_weight_received,  // new values should not be entered here
          updated_at:object.updated_at,
          updated_by:object.updated_by,

          po_amount:object.po_amount // po amount at item level
         }
         
         objList.push(obj)
      }



      editPayload={
    "total_po_amount":genericValues.poAmount,
    "invoice_number": genericValues.invoice_number,
    // "purchased_date": genericValues.purchased_date,
    "purchased_date": po_date,
    "invoice_date": genericValues.invoice_date,
    "supplier_name":genericValues.supplier_name,
    "supplier_id":genericValues.supplier_id,
    "supplier_email":genericValues.supplier_email,
    "delivery_in_days":genericValues.delivery_period,
    "payment_terms":genericValues.payment_terms,
    itemList:[...objList]

    }



    }
    else{ // this means that the user has opened the edit window and triggered the necessary functions
     editPayload = {
    "total_po_amount":genericValues.poAmount,
    "invoice_number": genericValues.invoice_number,
    // "purchased_date": genericValues.purchased_date,
    "purchased_date": po_date,
    "invoice_date": genericValues.invoice_date,
    "supplier_name":genericValues.supplier_name,
    "supplier_id":genericValues.supplier_id,
    "supplier_email":genericValues.supplier_email,
    "delivery_in_days":genericValues.delivery_period,
    "payment_terms":genericValues.payment_terms,
    itemList:[...allEditItemDetails]
    }

  }

    const url = `/api/superzop/commercial/purchasedetails/updatepurchasedetailsrecordList`
    baseUrl
      .put(url, editPayload)
      .then((res) => {
        if (res.status == 200) {
         toast("Updated Successfully", { type: toast.TYPE.SUCCESS });
          setTimeout(() => {
            window.location.reload();
          }, 2000);
        }
      })
      .catch((error) => {
        toast("Failed to update System Inventory", { type: toast.TYPE.ERROR });
        console.log(error);
  
      });
  }

  const columnStyle={
      '& .MuiOutlinedInput-root': {
        height: '30px',
        '& fieldset': {
          borderColor: 'rgba(0, 0, 0, 0.23)',
        },
      },
      '& .MuiOutlinedInput-input': {
        padding: '5px 8px',
      },
    }
  


  return (
    <>

    <Box sx={{ 
      height: '102%', 
      width:'101%',
      display: 'flex', 
      flexDirection: 'column',
      p: 2,
      position: 'relative',
    }}>

    {addEditItemDetails && <PurchaseEditModal
        open={addGRNModal}
        onClose={() => setViewModal(false)}
        data={addEditItemDetails}
        itemCode={itemCode}
        selectedDate={selectedDate}
        selectedMonth={selectedMonth}
        selectedYear={selectedYear}
        setAllEditItemDetails={setAllEditItemDetails} // mutating this state inside the Modal
        setEditSaveClick={setEditSaveClick}  // mutating this state inside the Modal
        supplierName={supplierName} 
        setPO_date={setPO_date}
        po_date={po_date}
        selectedPO_Item={selectedPO_Item}
    />}

    

    {addEditItemDetails && <PurchaseViewModal
        open={addEditModal} 
        onClose={() => setEditModal(false)}
        data={addEditItemDetails}
        itemCode={itemCode}
        selectedDate={selectedDate}
        selectedMonth={selectedMonth}
        selectedYear={selectedYear}
        setAllEditItemDetails={setAllEditItemDetails} // mutating this state inside the Modal
        setEditSaveClick={setEditSaveClick}  // mutating this state inside the Modal
        supplierName = {supplierName} 
        selectedPO_Item={selectedPO_Item}
    />}

    <Button
        aria-label="close"
        onClick={handleCloseForViewOrEdit}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        Close
      </Button>


      <Typography sx={{ textAlign: 'center',
                                 marginBottom: 0,  // Add some bottom margin if needed
                                fontWeight: 'bold'}} 
            // id="view-modal-title" 
            variant="h6" 
            component="h2"
            >
                        View/Edit Purchase
    </Typography>
    
      <Box sx={{ 
        mt: 1,
        flexGrow: 1,
        display: 'flex', 
        flexDirection: 'column',
        gap: 1,
        overflow: 'hidden'
      }}>
        <TableContainer component={Paper} sx={{ flexBasis: '64%', flexShrink: 0, overflow: 'auto' }}>
          
          <Table stickyHeader  sx={{
                        '& .MuiTableCell-root': {
                          padding: '4px 8px',
                          fontSize: '0.75rem',
                        },
                        '& .MuiTableCell-head': {
                          fontWeight: 'bold',
                          backgroundColor: '#28a745',
                        },
                        '& .MuiButton-root': {
                          padding: '2px 8px',
                          fontSize: '0.7rem',
                          minWidth: '60px',
                          height: '24px'
                        }
                      }}  
                      >

            <TableHead>
              <TableRow>
              {/* <TableCell sx={{ fontWeight: 'bold', backgroundColor: '#28a745', color: "#ffffff" }}>Sr. No.</TableCell> */}
                <TableCell sx={{ fontWeight: 'bold', backgroundColor: '#28a745', color: "#ffffff" }}>Sr. No. / PO Number</TableCell>
                <TableCell sx={{ fontWeight: 'bold', backgroundColor: '#28a745', color: "#ffffff" }}>Purchase Date</TableCell>
                <TableCell sx={{ fontWeight: 'bold', backgroundColor: '#28a745', color: "#ffffff" }}>Article Code</TableCell>
                <TableCell sx={{ fontWeight: 'bold', backgroundColor: '#28a745', color: "#ffffff" }}>Item Name</TableCell>
                <TableCell sx={{ fontWeight: 'bold', backgroundColor: '#28a745', color: "#ffffff" }}>Category</TableCell>
                <TableCell sx={{ fontWeight: 'bold', backgroundColor: '#28a745', color: "#ffffff" }}>Outer Size</TableCell>
                <TableCell sx={{ fontWeight: 'bold', backgroundColor: '#28a745', color: "#ffffff" }}>Outer Type</TableCell>
                <TableCell sx={{ fontWeight: 'bold', backgroundColor: '#28a745', color: "#ffffff" }}>Bags Purchased</TableCell>
                {/* <TableCell sx={{ fontWeight: 'bold', backgroundColor: '#28a745', color: "#ffffff" }}>Bags Received</TableCell> */}
                <TableCell sx={{ fontWeight: 'bold', backgroundColor: '#28a745', color: "#ffffff" }}>Base Price</TableCell>
                <TableCell sx={{ fontWeight: 'bold', backgroundColor: '#28a745', color: "#ffffff" }}>Purchase Price</TableCell>
                <TableCell sx={{ fontWeight: 'bold', backgroundColor: '#28a745', color: "#ffffff" }}>Total Weight</TableCell>

                <TableCell sx={{ fontWeight: 'bold', backgroundColor: '#28a745', color: "#ffffff" }}>Edit PO</TableCell>
                <TableCell sx={{ fontWeight: 'bold', backgroundColor: '#28a745', color: "#ffffff" }}>View PO</TableCell>
                
      
          
              </TableRow>
            </TableHead>
            <TableBody>
              {render_selectedPO_Item && render_selectedPO_Item.map((item, index) => (
                <TableRow key={index}>
                    {/* <TableCell>{index+1}</TableCell> */}
                  <TableCell>{index+1} / {item.po_number}</TableCell>
                  <TableCell>{item.purchased_date}</TableCell>
                  <TableCell>{item.article_code}</TableCell>
                  <TableCell>{item.item_name}</TableCell>
                  <TableCell>{item.category}</TableCell>
                  <TableCell>{item.outer_size}</TableCell>
                  <TableCell>{item.outer_type}</TableCell>
                  <TableCell>{item.bag_purchased} {item.showMark ? item.showMark:""}</TableCell>
                  {/* <TableCell>{item.bags_received} {item.showMark ? item.showMark:""}</TableCell> */}
                  <TableCell>{item.base_price}</TableCell>
                  <TableCell>{item.purchase_price}</TableCell>
                  <TableCell>{item.total_weight}</TableCell>
                  <TableCell>
                    {/* can also use item.grn_date */}
                    {item.grn_no ? (
                    // <Button 
                    // sx={{
                    //   backgroundColor: "#ffc107",
                    //   '&:hover': {
                    //     backgroundColor: "#ffab00", // Change this to your desired hover color
                    //   },
                    // }}
                    // variant="contained" color="secondary" onClick={()=>{handleEditModal(item)}} size="small">
                    //  View
                    // </Button>)
                    <></>)
                    :
                    (
                    <Button 
                       color="secondary" variant="contained" onClick={()=>{handleAddModal(item)}} size="small">
                        Edit
                    </Button> 
                    )
                    }
                  </TableCell>
                  
                  <TableCell>
                  <Button 
                    sx={{
                      backgroundColor: "#ffc107",
                      '&:hover': {
                        backgroundColor: "#ffab00", // Change this to your desired hover color
                      },
                    }}
                    variant="contained" color="secondary" onClick={()=>{handleEditModal(item)}} size="small">
                     View
                    </Button>
                    </TableCell>
                    
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        <Divider/>

        <TableContainer component={Paper} sx={{ flexBasis: '32%', flexShrink: 0, overflow: 'auto' }}>


        {shouldAddActive ? (<Table stickyHeader size="small">

        {/* <TableHead>
        <TableRow>
        <TableCell colSpan={6} align="center">
            <Typography 
            sx={{ 
                marginBottom: 0,  // Add some bottom margin if needed
                fontWeight: 'bold',
                fontSize:'14px'
            }} 
            >
            Add Generic values
            </Typography>
        </TableCell>
        </TableRow>
    </TableHead> */}
            
        <TableRow>

        <TableCell sx={{ fontSize:"0.8rem" }} >Invoice Date</TableCell>

          <TableCell>
                <TextField 
                //  InputProps={{
                //   style: { fontSize: '0.875rem' }
                // }}
                sx={columnStyle}
                value={genericValues.invoice_date} disabled type="date"  fullWidth />
        </TableCell>

        <TableCell sx={{ fontSize:"0.8rem" }}>Invoice Number</TableCell>
              <TableCell> <TextField  sx={columnStyle} value={genericValues.invoice_number}  onChange={(e)=>setGenericValues({...genericValues,invoice_number:(e.target.value)})}  fullWidth />
        </TableCell>

        <TableCell sx={{ fontSize:"0.8rem" }} >Payment Terms (in Days)</TableCell>
          <TableCell>
              <TextField sx={columnStyle} value={genericValues.payment_terms}  onChange={(e)=>setGenericValues({...genericValues,payment_terms:(e.target.value)})}  type="number" fullWidth />
        </TableCell>

        </TableRow>




        <TableRow>

        <TableCell sx={{ fontSize:"0.8rem" }} >Delivery Period (in Days)</TableCell>
            <TableCell>
                <TextField sx={columnStyle} value={genericValues.delivery_period} onChange={(e)=>setGenericValues({...genericValues,delivery_period:(e.target.value)})} fullWidth />
        </TableCell>

        {/* <TableCell sx={{ fontSize:"0.8rem" }} >Supplier Name</TableCell>
            <TableCell>
                <TextField  sx={columnStyle} disabled value={genericValues.supplier_name} fullWidth />
        </TableCell> */}


          <TableCell sx={{ fontSize:"0.8rem" }}>Supplier Name</TableCell>
              <TableCell sx={columnStyle}>
            <FormControl fullWidth>
                <InputLabel id="supplier-select-label">Supplier</InputLabel>
                <Select
                
                labelId="supplier-select-label"
                // ref={supplierRef}
                id="supplier-select"
                value={supplierName || ''}  // common value in all items
                label="Supplier"
                onChange={(event) => {
                    const selectedSupplier = suppliersList.find(supplier => supplier.value === event.target.value);
                    if (selectedSupplier) {
                    // setProductEditObject({
                    //     ...productEditObject,
                    //     supplier_name: selectedSupplier.value,
                    //     supplier_id: selectedSupplier.key,
                    //     supplier_email: selectedSupplier.email,
                    //     bill_address: selectedSupplier.address,
                    //     bill_city: selectedSupplier.city,
                    //     bill_state: selectedSupplier.state,
                    //     bill_street: selectedSupplier.street,
                    //     bill_pin: selectedSupplier.pin,
                    //     bill_fssai: selectedSupplier.fssai,
                    //     bill_gstin: selectedSupplier.gstin,
                    //     bill_phone: selectedSupplier.phone,
                    // });

                   
                    const gstin = selectedSupplier.gstin
                    const fssai = selectedSupplier.fssai

                    if(gstin && fssai)
                    {

                      const fssaiRegex = /^([1-2]\d{13})$/;
                      // const gstRegex = /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z][1Z][0-9A-Z]{1}$/
                      const gstRegex = /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z][A-Z1Z][0-9A-Z]{2}$/
                      
                    if (fssaiRegex.test(fssai) && gstRegex.test(gstin)) {
                    setSupplierGroup(selectedSupplier.supplierGroup)
                    setSupplierName(selectedSupplier.value)
                    setGenericValues({...genericValues,supplier_name:selectedSupplier.value,supplier_id:selectedSupplier.key,supplier_email:selectedSupplier.email})
                    }
                    else
                    {
                      toast("Please Check GST number and FSSAI format for the selected supplier", {
                        type: toast.TYPE.ERROR,
                      });
                    }
                  }
                  else
                  {
                      toast("Please Update GST number and FSSAI number for the selected supplier", {
                      type: toast.TYPE.ERROR,
                    });
                  }
                }
                }}
                >
                {suppliersList && suppliersList.map((supplier) => (
                    <MenuItem key={supplier.key} value={supplier.value}>
                    {supplier.value}
                    </MenuItem>
                ))}
                </Select>
            </FormControl>
            </TableCell>

        <TableCell sx={{ fontSize:"0.8rem" }}>Supplier ID</TableCell>
            <TableCell>
                <TextField sx={columnStyle} value={genericValues.supplier_id} disabled InputProps={{ readOnly: true }} fullWidth />
        </TableCell>

      
        </TableRow>



        <TableRow>

        <TableCell sx={{ fontSize:"0.8rem" }}>Supplier Email</TableCell>
            <TableCell>
                <TextField sx={columnStyle} value={genericValues.supplier_email} disabled onChange={(e)=>setGenericValues({...genericValues,supplier_email:(e.target.value)})} fullWidth />
        </TableCell>

        <TableCell sx={{ fontSize:"0.8rem" }}>PO Amount</TableCell>
            <TableCell>
                <TextField disabled sx={columnStyle} value={poAmount} onChange={(e)=>setPoAmount(e.target.value)} fullWidth />
        </TableCell>


        </TableRow>


        </Table>
        )
        : // For view
        (
          <Table stickyHeader size="small">

        {/* <TableHead>
        <TableRow>
        <TableCell colSpan={6} align="center">
            <Typography 
            sx={{ 
                marginBottom: 0,  // Add some bottom margin if needed
                fontWeight: 'bold',
                fontSize:'14px'
            }} 
            >
            Add Generic values
            </Typography>
        </TableCell>
        </TableRow>
    </TableHead> */}
            
        <TableRow>

        <TableCell sx={{ fontSize:"0.8rem" }} >Invoice Date</TableCell>

          <TableCell>
                <TextField 
                //  InputProps={{
                //   style: { fontSize: '0.875rem' }
                // }}
                disabled
                sx={columnStyle}
                value={genericValues.invoice_date}   type="date"  fullWidth />
        </TableCell>

        <TableCell sx={{ fontSize:"0.8rem" }}>Invoice Number</TableCell>
              <TableCell> <TextField disabled  sx={columnStyle} value={genericValues.invoice_number} fullWidth />
        </TableCell>

        <TableCell sx={{ fontSize:"0.8rem" }} >Payment Terms (in Days)</TableCell>
          <TableCell>
              <TextField disabled sx={columnStyle} value={genericValues.payment_terms}   type="number" fullWidth />
        </TableCell>

        </TableRow>




        <TableRow>

        <TableCell sx={{ fontSize:"0.8rem" }} >Delivery Period (in Days)</TableCell>
            <TableCell>
                <TextField  sx={columnStyle} value={genericValues.delivery_period} disabled InputProps={{ readOnly: true }} fullWidth />
        </TableCell>

        <TableCell sx={{ fontSize:"0.8rem" }} >Supplier Name</TableCell>
            <TableCell>
                <TextField  sx={columnStyle} disabled value={genericValues.supplier_name} fullWidth />
        </TableCell>

        <TableCell sx={{ fontSize:"0.8rem" }}>Supplier ID</TableCell>
            <TableCell>
                <TextField sx={columnStyle} value={genericValues.supplier_id} disabled InputProps={{ readOnly: true }} fullWidth />
        </TableCell>

      
        </TableRow>



        <TableRow>

        <TableCell sx={{ fontSize:"0.8rem" }}>Supplier Email</TableCell>
            <TableCell>
                <TextField sx={columnStyle} value={genericValues.supplier_email} disabled InputProps={{ readOnly: true }} fullWidth />
        </TableCell>

        <TableCell sx={{ fontSize:"0.8rem" }}>PO Amount</TableCell>
            <TableCell>
                <TextField disabled sx={columnStyle} value={poAmount} fullWidth />
        </TableCell>


        </TableRow>


        </Table>

        )
        }

        </TableContainer>
        
      </Box>

      <Divider />
    <Box sx={{ p: 2, display: 'flex', justifyContent: 'flex-end' }}>

    { shouldAddActive && (<Button sx={{ mr: 1,  '&:hover': {
                          backgroundColor: "#4CBB17", // Change this to your desired hover color
                        }, 
                        backgroundColor:"#28a745"}} 
                        onClick={() => updateForEditClick()} 
                        variant="contained">
          Update Edit PO
        </Button>) 
      //   : 
      //   (<Button sx={{ mr: 1,  '&:hover': {
      //       backgroundColor: "#4CBB17", // Change this to your desired hover color
      //     },
      //     backgroundColor:"#28a745"}} onClick={() => updateForEditClick()} variant="contained">
      //   Update Edit GRN
      // </Button>) 
      }
        <Button onClick={handleCloseForViewOrEdit} variant="outlined" color="secondary">
          Close
        </Button>
    </Box>


     
    </Box>


  </>
  )
}

export default NewListPurchaseAddAndEdit