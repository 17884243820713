import React, { useEffect, useState, useRef } from "react";

import {
  Navbar,
  Badge,
  InputGroup,
  Button,
  Table,
  Modal,
  FormControl,
} from "react-bootstrap";
import Form from "react-bootstrap/Form";
import { toast } from "react-toastify";
import { LOGGED_USER_EMAIL } from "../../constants/allConstants";
import database from "../../service/firebaseConfig";
import UpdatedDate from "../../CommonRequirements/UpdatedDate";
import UpdatedTime from "../../CommonRequirements/UpdatedTime";
const SuppliersDB = database.database().ref(`Suppliers`);
var storageRef = database.storage().ref(`Suppliers`);
let state = [
  "AP",
  "AR",
  "AS",
  "BR",
  "CG",
  "GA",
  "GJ",
  "HR",
  "HP",
  "JK",
  "JH",
  "KA",
  "KL",
  "MP",
  "MH",
  "MN",
  "ML",
  "MZ",
  "NL",
  "OR",
  "PB",
  "RJ",
  "SK",
  "TN",
  "TS",
  "TR",
  "UK",
  "UP",
  "WB",
  "AN",
  "CH",
  "DH",
  "DD",
  "DL",
  "LD",
  "PY",
];

const AddEditSupplierModal = ({
  data,
  supplierModalType,
  supplierEditData,
  ...props
}) => {
  const [supplierName, setSupplierName] = useState(
    supplierModalType === "Edit" ? supplierEditData.supplier_name : ""
  );
  const [firstName, setFirstName] = useState(
    supplierModalType === "Edit" ? supplierEditData.first_name : ""
  );
  const [lastName, setLastName] = useState(
    supplierModalType === "Edit" ? supplierEditData.last_name : ""
  );
  const [phoneNumber, setPhoneNumber] = useState(
    supplierModalType === "Edit" ? supplierEditData.phone_number : ""
  );
  const [email, setEmail] = useState(
    supplierModalType === "Edit" ? supplierEditData.email : ""
  );
  const [remarks, setRemarks] = useState(
    supplierModalType === "Edit" ? supplierEditData.remarks : ""
  );
  const [supplierType, setSupplierType] = useState(
    supplierModalType === "Edit" ? supplierEditData.supplierType : ""
  );
  const [supplierGroup, setSupplierGroup] = useState(
    supplierModalType === "Edit" ? supplierEditData.supplierGroup : ""
  );
  const [supplierBankName, setSupplierBankName] = useState(
    supplierModalType === "Edit" ? supplierEditData.supplierBankName : ""
  );
  const [supplierAcNumber, setSupplierAcNumber] = useState(
    supplierModalType === "Edit" ? supplierEditData.supplierAcNumber : ""
  );
  const [supplierBankIfsc, setSupplierBankIfsc] = useState(
    supplierModalType === "Edit" ? supplierEditData.supplierBankIfsc : ""
  );
  const [supplierBankBranch, setSupplierBankBranch] = useState(
    supplierModalType === "Edit" ? supplierEditData.supplierBankBranch : ""
  );
  const [BranchID, setBranchID] = useState(
    supplierModalType === "Edit" ? supplierEditData.BranchID : ""
  );

  const [PaymentTermsLabel, setPaymentTermsLabel] = useState(
    supplierModalType === "Edit" ? supplierEditData.PaymentTermsLabel : ""
  );
  const [sourceOfSupply, setSourceOfSupply] = useState(
    supplierModalType === "Edit" ? supplierEditData.sourceOfSupply : ""
  );
  const [GstTreatment, setGstTreatment] = useState(
    supplierModalType === "Edit" ? supplierEditData.GstTreatment : ""
  );
  const [Gstin, setGstin] = useState(
    supplierModalType === "Edit" ? supplierEditData.Gstin : ""
  );
  const [Billing_Address, setBillingAdd] = useState(
    supplierModalType === "Edit" ? supplierEditData.Billing_Address : ""
  );
  const [Shipping_Address, setShippingAdd] = useState(
    supplierModalType === "Edit" ? supplierEditData.Shipping_Address : ""
  );
  const [Billing_Street, setBillingStreet] = useState(
    supplierModalType === "Edit" ? supplierEditData.Billing_Street : ""
  );
  const [Shipping_Street, setShippingStreet] = useState(
    supplierModalType === "Edit" ? supplierEditData.Shipping_Street : ""
  );
  const [Billing_City, setBillingCity] = useState(
    supplierModalType === "Edit" ? supplierEditData.Billing_City : ""
  );
  const [Shipping_City, setShippingCity] = useState(
    supplierModalType === "Edit" ? supplierEditData.Shipping_City : ""
  );
  const [Billing_Code, setBillingCode] = useState(
    supplierModalType === "Edit" ? supplierEditData.Billing_Code : ""
  );
  const [Shipping_Code, setShippingCode] = useState(
    supplierModalType === "Edit" ? supplierEditData.Shipping_Code : ""
  );
  const [Billing_State, setBillingState] = useState(
    supplierModalType === "Edit" ? supplierEditData.Billing_State : ""
  );
  const [Shipping_State, setShippingState] = useState(
    supplierModalType === "Edit" ? supplierEditData.Shipping_State : ""
  );
  const [PAN, setPAN] = useState(
    supplierModalType === "Edit" ? supplierEditData.PAN : ""
  );
  const [TAN, setTAN] = useState(
    supplierModalType === "Edit" ? supplierEditData.TAN : ""
  );
  const [FSSAI, setFSSAI] = useState(
    supplierModalType === "Edit" ? supplierEditData.FSSAI : ""
  );
  const [ImgUrl, setImgUrl] = useState(
    supplierModalType === "Edit" ? supplierEditData.ImgUrl : ""
  );
  const [billadd, setbilladd] = useState(
    supplierModalType === "Edit" ? supplierEditData.billadd : "Not Same"
  );
  const [TrAbv50, setTrAbv50] = useState(
    supplierModalType === "Edit" ? supplierEditData.TrAbv50 : "No"
  );

  var emailValid =
    /^([a-zA-Z0-9_.+-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
  var phoneValid = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/;
  var pinValid = /^[1-9]{1}[0-9]{2}\s{0,1}[0-9]{3}$/;
  var panValid = /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/;
  var tanValid = /^[A-Z]{4}[0-9]{5}[A-Z]{1}$/;
  // var gstValid = /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/;
  // var gstValid = /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z][A-Z1Z][0-9A-Z]{2}$/;
  var gstValid = /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z][A-Z1-9][0-9A-Z]{2}$/

  var fssaiValid = /^([1-2]\d{13})$/;

  toast.configure({
    autoClose: 4000,
    draggable: true,
    pauseOnHover: true,
    position: toast.POSITION.TOP_LEFT,
  });

  const createSupplier = () => {
    if (
      !(
        supplierAcNumber === "" &&
        supplierBankName === "" &&
        supplierBankBranch === "" &&
        supplierBankIfsc === "" &&
        supplierBankBranch === "" &&
        BranchID === "" &&
        ImgUrl === ""
      )
    ) {
      if (
        supplierAcNumber === "" ||
        supplierBankName === "" ||
        supplierBankBranch === "" ||
        supplierBankIfsc === "" ||
        supplierBankBranch === "" ||
        BranchID === "" ||
        ImgUrl === ""
      ) {
        return toast("Please Fill All The Bank Details", {
          type: toast.TYPE.ERROR,
        });
      }
    }
    const suppliersList = data;
    const fssaiRegex = /^([1-2]\d{13})$/;
    const gstRegex = /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z][A-Z1-9][0-9A-Z]{2}$/
    // /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z][A-Z1Z][0-9A-Z]{2}$/
    if (Gstin && !gstRegex.test(Gstin)) {
      return toast("Please enter a valid GSTIN", { type: toast.TYPE.ERROR });
    }
    if (!FSSAI || !fssaiRegex.test(FSSAI)) {
      return toast("Please enter a valid FSSAI number", { type: toast.TYPE.ERROR });
    }

    if (supplierName && supplierType) {
      const supplier_name = supplierName.trim().toUpperCase();

      const matched_supplier_names = suppliersList.filter((obj) => {
        return (
          obj.supplier_name &&
          supplier_name === obj.supplier_name.trim().toUpperCase()
        );
      });

      console.log("matched_supplier_names: ", matched_supplier_names);

      if (matched_supplier_names.length === 0) {
        let current_supplier_id = "000001";
        current_supplier_id = Number(current_supplier_id);
        if (supplierType === "Expenses") {
          if (
            supplierGroup.toUpperCase() === "CAPITAL" ||
            supplierGroup.toUpperCase() === "MILL"
          ) {
            suppliersList.map((item) => {
              if (item.supplier_id.toString().substring(0, 2) == "VC") {
                current_supplier_id = current_supplier_id + 1;
              }
            });
            current_supplier_id =
              "VC" + String(current_supplier_id).padStart(6, "0");
          } else if (supplierGroup.toUpperCase() === "PACKING") {
            suppliersList.map((item) => {
              if (item.supplier_id.toString().substring(0, 2) == "VP") {
                current_supplier_id = current_supplier_id + 1;
              }
            });
            current_supplier_id =
              "VP" + String(current_supplier_id).padStart(6, "0");
          } else {
            suppliersList.map((item) => {
              if (item.supplier_id.toString().substring(0, 2) == "VS") {
                current_supplier_id = current_supplier_id + 1;
              }
            });
            current_supplier_id =
              "VS" + String(current_supplier_id).padStart(6, "0");
          }
        } else {
          if (supplierGroup.toUpperCase() === "OUTSTATION") {
            suppliersList.map((item) => {
              if (item.supplier_id.toString().substring(0, 2) == "VO") {
                current_supplier_id = current_supplier_id + 1;
              }
            });
            current_supplier_id =
              "VO" + String(current_supplier_id).padStart(6, "0");
          }
          else if (supplierGroup.toUpperCase() === "DANA") {
            suppliersList.map((item) => {
              if (item.supplier_id.toString().substring(0, 2) == "VD") {
                current_supplier_id = current_supplier_id + 1;
              }
            });
            current_supplier_id =
              "VD" + String(current_supplier_id).padStart(6, "0");
          }
          else if (supplierGroup.toUpperCase() === "MASALA") {
            suppliersList.map((item) => {
              if (item.supplier_id.toString().substring(0, 2) == "VM") {
                current_supplier_id = current_supplier_id + 1;
              }
            });
            current_supplier_id =
              "VM" + String(current_supplier_id).padStart(6, "0");
          } 
          else if (supplierGroup.toUpperCase() === 'SPICES') {
            suppliersList.map((item) => {
              if (item.supplier_id.toString().substring(0, 2) == "VA") {
                current_supplier_id = current_supplier_id + 1;
              }
            });
            current_supplier_id =
              "VA" + String(current_supplier_id).padStart(6, "0");
          }
          else {
               suppliersList.map((item) => {
                 if (item.supplier_id.toString().substring(0, 2) == "VG") {
                   current_supplier_id = current_supplier_id + 1;
                 }
               });
               current_supplier_id =
                 "VG" + String(current_supplier_id).padStart(6, "0");
          }
        }
        const cret_supp_obj = {
          supplier_name: supplierName.trim().toUpperCase(),
          supplier_id: current_supplier_id,
          first_name: firstName.trim(),
          last_name: lastName.trim(),
          phone_number: phoneNumber.trim(),
          email: email.trim(),
          remarks: remarks.trim(),
          supplierType: supplierType,
          supplierGroup: supplierGroup.trim(),
          supplierBankName: supplierBankName.trim(),
          supplierAcNumber: supplierAcNumber.trim(),
          supplierBankIfsc: supplierBankIfsc.trim(),
          supplierBankBranch: supplierBankBranch,
          BranchID: BranchID.trim(),
          sourceOfSupply: sourceOfSupply.trim(),
          GstTreatment: GstTreatment,
          Gstin: Gstin.trim().toUpperCase(),
          Billing_Address: Billing_Address,
          Shipping_Address: Shipping_Address,
          Billing_Street2: Billing_Street,
          Shipping_Street2: Shipping_Street,
          Billing_City: Billing_City,
          Shipping_City: Shipping_City,
          Billing_Code: Billing_Code,
          Shipping_Code: Shipping_Code,
          Billing_State: Billing_State,
          Shipping_State: Shipping_State,
          PaymentTermsLabel: PaymentTermsLabel,
          PAN: PAN.trim().toUpperCase(),
          TAN: TAN.trim().toUpperCase(),
          FSSAI: FSSAI.trim().toUpperCase(),
          ImgUrl: ImgUrl,
          billadd: billadd,
          TrAbv50: TrAbv50,
          created_at: `${UpdatedDate()} ${UpdatedTime()}`,
          created_by: sessionStorage.getItem(LOGGED_USER_EMAIL),
        }
        console.log("cret_supp_obj: ", cret_supp_obj);

        SuppliersDB.child(current_supplier_id)
          .set(cret_supp_obj)
          .then(() => {
            setTimeout(() => {
              window.location.reload();
            }, 1000);

            toast(`Created supplier successfully`, {
              type: toast.TYPE.SUCCESS,
            });
          })
          .catch((error) => {
            toast(`Failed to create supplier`, { type: toast.TYPE.ERROR });
          });
      } else {
        return toast("Supplier Already Exists", { type: toast.TYPE.ERROR });
      }
    } else {
      return toast("Please Fill All The Required Fields Correctly", {
        type: toast.TYPE.ERROR,
      });
    }
  };

  const EditSupplier = () => {
    console.log("supplierEditData: ", supplierEditData);

    const supplier_id = supplierEditData.key;

    const fssaiRegex = /^([1-2]\d{13})$/;
    // const gstRegex = /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z][A-Z1Z][0-9A-Z]{2}$/
    const gstRegex = /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z][A-Z1-9][0-9A-Z]{2}$/
    if (Gstin && !gstRegex.test(Gstin)) {
      return toast("Please enter a valid GSTIN", { type: toast.TYPE.ERROR });
    }
    if (!FSSAI || !fssaiRegex.test(FSSAI)) {
      return toast("Please enter a valid FSSAI number", { type: toast.TYPE.ERROR });
    }

    const dataToUpdate = {
      first_name: firstName
        ? firstName
        : supplierEditData.first_name
        ? supplierEditData.first_name
        : "",
      last_name: lastName
        ? lastName
        : supplierEditData.last_name
        ? supplierEditData.last_name
        : "",
      phone_number: phoneNumber
        ? phoneNumber
        : supplierEditData.phone_number
        ? supplierEditData.phone_number
        : "",
      email: email
        ? email
        : supplierEditData.email
        ? supplierEditData.email
        : "",
      remarks: remarks
        ? remarks
        : supplierEditData.remarks
        ? supplierEditData.remarks
        : "",
      supplierType: supplierType
        ? supplierType
        : supplierEditData.supplierType
        ? supplierEditData.supplierType
        : "",
      supplierGroup: supplierGroup
        ? supplierGroup
        : supplierEditData.supplierGroup
        ? supplierEditData.supplierGroup
        : "",
      supplierAcNumber: supplierAcNumber
        ? supplierAcNumber
        : supplierEditData.supplierAcNumber
        ? supplierEditData.supplierAcNumber
        : "",
      supplierBankName: supplierBankName
        ? supplierBankName
        : supplierEditData.supplierBankName
        ? supplierEditData.supplierBankName
        : "",

      supplierBankIfsc: supplierBankIfsc
        ? supplierBankIfsc
        : supplierEditData.supplierBankIfsc
        ? supplierEditData.supplierBankIfsc
        : "",
      supplierBankBranch: supplierBankBranch
        ? supplierBankBranch
        : supplierEditData.supplierBankBranch
        ? supplierEditData.supplierBankBranch
        : "",
      BranchID: BranchID
        ? BranchID
        : supplierEditData.BranchID
        ? supplierEditData.BranchID
        : "",
      PaymentTermsLabel: PaymentTermsLabel
        ? PaymentTermsLabel
        : supplierEditData.PaymentTermsLabel
        ? supplierEditData.PaymentTermsLabel
        : "",
      sourceOfSupply: sourceOfSupply
        ? sourceOfSupply
        : supplierEditData.sourceOfSupply
        ? supplierEditData.sourceOfSupply
        : "",
      GstTreatment: GstTreatment
        ? GstTreatment
        : supplierEditData.GstTreatment
        ? supplierEditData.GstTreatment
        : "",
      Gstin: Gstin
        ? Gstin
        : supplierEditData.Gstin
        ? supplierEditData.Gstin
        : "",
      PAN: PAN ? PAN : supplierEditData.PAN ? supplierEditData.PAN : "",
      TAN: TAN ? TAN : supplierEditData.TAN ? supplierEditData.TAN : "",
      FSSAI: FSSAI
        ? FSSAI
        : supplierEditData.FSSAI
        ? supplierEditData.FSSAI
        : "",
      ImgUrl: ImgUrl
        ? ImgUrl
        : supplierEditData.ImgUrl
        ? supplierEditData.ImgUrl
        : "",
      TrAbv50: TrAbv50
        ? TrAbv50
        : supplierEditData.TrAbv50
        ? supplierEditData.TrAbv50
        : "No",

      billadd: billadd
        ? billadd
        : supplierEditData.billadd
        ? supplierEditData.billadd
        : "Not Same",
      Billing_Address: Billing_Address
        ? Billing_Address
        : supplierEditData.Billing_Address
        ? supplierEditData.Billing_Address
        : "",
      Shipping_Address: Shipping_Address
        ? Shipping_Address
        : supplierEditData.Shipping_Address
        ? supplierEditData.Shipping_Address
        : "",
      Billing_Street: Billing_Street
        ? Billing_Street
        : supplierEditData.Billing_Street
        ? supplierEditData.Billing_Street
        : "",
      Shipping_Street: Shipping_Street
        ? Shipping_Street
        : supplierEditData.Shipping_Street
        ? supplierEditData.Shipping_Street
        : "",
      Billing_City: Billing_City
        ? Billing_City
        : supplierEditData.Billing_City
        ? supplierEditData.Billing_City
        : "",
      Shipping_City: Shipping_City
        ? Shipping_City
        : supplierEditData.Shipping_City
        ? supplierEditData.Shipping_City
        : "",
      Billing_Code: Billing_Code
        ? Billing_Code
        : supplierEditData.Billing_Code
        ? supplierEditData.Billing_Code
        : "",
      Shipping_Code: Shipping_Code
        ? Shipping_Code
        : supplierEditData.Shipping_Code
        ? supplierEditData.Billing_Code
        : "",
      Billing_State: Billing_State
        ? Billing_State
        : supplierEditData.Billing_State
        ? supplierEditData.Billing_State
        : "",
      Shipping_State: Shipping_State
        ? Shipping_State
        : supplierEditData.Shipping_State
        ? supplierEditData.Shipping_State
        : "",
      updated_at: `${UpdatedDate()} ${UpdatedTime()}`,
      updated_by: sessionStorage.getItem(LOGGED_USER_EMAIL),
    };

    console.log("dataToUpdate: ", dataToUpdate);

    SuppliersDB.child(supplier_id)
      .update(dataToUpdate)
      .then(() => {
        props.onEdit({ ...supplierEditData, ...dataToUpdate });

        props.showCloseModal(false);

        return toast(`Updated supplier successfully`, {
          type: toast.TYPE.SUCCESS,
        });
      })
      .catch((error) => {
        console.error(error);
        return toast(`Failed to update supplier`, { type: toast.TYPE.ERROR });
      });
  };

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {supplierModalType} Supplier
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Table>
          {" "}
          <tr>
            {supplierModalType === "Edit" ? (
              <>
                <td style={{ borderTop: "None" }}>Supplier ID</td>
                <td>
                  <Form.Control
                    id="addSupplier"
                    size="sm"
                    style={{ marginRight: "20px" }}
                    onChange={(e) => {
                      setSupplierName(e.target.value);
                    }}
                    placeholder={supplierEditData.key}
                    readOnly={true}
                  />
                </td>
              </>
            ) : (
              <></>
            )}

            <td style={{ borderTop: "None" }}>Supplier Name *</td>
            {supplierModalType === "Add" ? (
              <td style={{ borderTop: "None" }}>
                <Form.Control
                  id="addSupplier"
                  size="sm"
                  style={{ marginRight: "20px" }}
                  required
                  onChange={(e) => {
                    setSupplierName(e.target.value);
                  }}
                  placeholder={"Supplier Name"}
                />
              </td>
            ) : (
              <td style={{ borderTop: "None" }}>
                <Form.Control
                  id="addSupplier"
                  readOnly={true}
                  size="sm"
                  style={{ marginRight: "20px" }}
                  placeholder={supplierName}
                />
              </td>
            )}
          </tr>
          <tr>
            <td style={{ borderTop: "None" }}>Point of Contact</td>
            {supplierModalType === "Add" ? (
              <td style={{ borderTop: "None" }}>
                <Form.Control
                  id="addSupplier"
                  size="sm"
                  style={{ marginRight: "20px" }}
                  required
                  onChange={(e) => {
                    setFirstName(e.target.value);
                  }}
                  placeholder="First Name"
                />
              </td>
            ) : (
              <td style={{ borderTop: "None" }}>
                <Form.Control
                  id="addSupplier"
                  size="md"
                  style={{ marginRight: "20px" }}
                  required
                  onChange={(e) => {
                    setFirstName(e.target.value);
                  }}
                  placeholder={firstName}
                  value={firstName}
                />
              </td>
            )}
            {supplierModalType === "Add" ? (
              <td style={{ borderTop: "None" }}>
                <Form.Control
                  id="addSupplier"
                  size="sm"
                  style={{ marginLeft: "20px", marginRight: "20px" }}
                  required
                  onChange={(e) => {
                    setLastName(e.target.value);
                  }}
                  placeholder="Last Name"
                />
              </td>
            ) : (
              <td style={{ borderTop: "None" }}>
                <Form.Control
                  id="addSupplier"
                  size="sm"
                  style={{ marginLeft: "20px", marginRight: "20px" }}
                  required
                  onChange={(e) => {
                    setLastName(e.target.value);
                  }}
                  placeholder={lastName}
                  value={lastName}
                />
              </td>
            )}
          </tr>
          <tr>
            <td style={{ borderTop: "None" }}>Phone Number</td>
            {supplierModalType === "Add" ? (
              <td style={{ borderTop: "None" }}>
                <Form.Control
                  id="addSupplier"
                  size="sm"
                  style={{ marginRight: "20px" }}
                  required
                  onChange={(e) => {
                    setPhoneNumber(e.target.value);
                  }}
                  isValid={phoneValid.test(phoneNumber)}
                  isInvalid={
                    phoneNumber === "" ? false : !phoneValid.test(phoneNumber)
                  }
                  placeholder="Phone Number"
                />
              </td>
            ) : (
              <td style={{ borderTop: "None" }}>
                <Form.Control
                  id="addSupplier"
                  onChange={(e) => {
                    setPhoneNumber(e.target.value);
                  }}
                  isValid={phoneValid.test(phoneNumber)}
                  isInvalid={
                    phoneNumber === "" ? false : !phoneValid.test(phoneNumber)
                  }
                  style={{ marginRight: "20px" }}
                  size="sm"
                  placeholder={phoneNumber}
                  value={phoneNumber}
                />
              </td>
            )}
            <td style={{ borderTop: "None" }}>Email</td>
            {supplierModalType === "Add" ? (
              <td style={{ borderTop: "None" }}>
                <Form.Control
                  id="addSupplier"
                  size="sm"
                  required
                  isValid={emailValid.test(email)}
                  isInvalid={email === "" ? false : !emailValid.test(email)}
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                  placeholder="Email"
                />
              </td>
            ) : (
              <td style={{ borderTop: "None" }}>
                <Form.Control
                  id="addSupplier"
                  size="sm"
                  isValid={emailValid.test(email)}
                  isInvalid={email === "" ? false : !emailValid.test(email)}
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                  value={email}
                />
              </td>
            )}
          </tr>
          <tr>
            <td style={{ borderTop: "None" }}>Remarks</td>
            {supplierModalType === "Add" ? (
              <td style={{ borderTop: "None" }}>
                <Form.Control
                  id="addSupplier"
                  size="sm"
                  style={{ marginRight: "20px" }}
                  required
                  onChange={(e) => {
                    setRemarks(e.target.value);
                  }}
                  placeholder="Remarks"
                />
              </td>
            ) : (
              <td style={{ borderTop: "None" }}>
                <Form.Control
                  id="addSupplier"
                  size="sm"
                  style={{ marginRight: "20px" }}
                  onChange={(e) => {
                    setRemarks(e.target.value);
                  }}
                  value={remarks}
                />
              </td>
            )}
            <td style={{ borderTop: "None" }}>Supplier Type*</td>
            {supplierModalType === "Add" ? (
              <td style={{ borderTop: "None" }}>
                <Form.Control
                  as="select"
                  id="addSupplier"
                  size="sm"
                  onChange={(e) => {
                    setSupplierType(e.target.value);
                  }}
                >
                  <option value="">Select Type</option>
                  <option value="Expenses">Expenses</option>
                  <option value="Goods">Goods</option>
                </Form.Control>
              </td>
            ) : (
              <td style={{ borderTop: "None" }}>
                <Form.Control
                  as="select"
                  id="addSupplier"
                  size="sm"
                  defaultValue={supplierType}
                  disabled
                  readOnly={true}
                >
                  <option value="Expenses">Expenses</option>
                  <option value="Goods">Goods</option>
                </Form.Control>
              </td>
            )}
          </tr>
          <tr>
            <td style={{ borderTop: "None" }}>Supplier Group</td>
            {supplierModalType === "Add" ? (
              <td style={{ borderTop: "None" }}>
                <Form.Control
                  id="addSupplier"
                  as="Select"
                  size="sm"
                  style={{ marginRight: "20px" }}
                  placeholder="Supplier Group"
                  onChange={(e) => {
                    setSupplierGroup(e.target.value);
                  }}
                >
                  {supplierType === "" && (
                    <>
                      <option value="">Select</option>
                    </>
                  )}
                  {supplierType === "Expenses" && (
                    <>
                      <option value="">Select Group</option>
                      <option value="TRANSPORT">TRANSPORT</option>
                      <option value="MILL">MILL</option>
                      <option value="CAPITAL">CAPITAL</option>
                      <option value="OFFICE EXPENSES">OFFICE EXPENSES</option>
                      <option value="MANPOWER">MANPOWER</option>
                      <option value="RENT">RENT</option>
                      <option value="PACKING">PACKING</option>
                      <option value="OTHERS">OTHERS</option>
                    </>
                  )}
                  {supplierType === "Goods" && (
                    <>
                      <option value="">Select Group</option>
                      <option value="OUTSTATION">OUTSTATION</option>
                      <option value="DANA">DANA</option>
                      <option value="MASALA">MASALA</option>
                      <option value="SPICES">SPICES</option>
                    </>
                  )}
                </Form.Control>
              </td>
            ) : (
              <td style={{ borderTop: "None" }}>
                <Form.Control
                  id="addSupplier"
                  as="select"
                  size="sm"
                  style={{ marginRight: "20px" }}
                  defaultValue={supplierGroup ? supplierGroup.toUpperCase() : undefined}
                  disabled={!(supplierGroup === "")}
                  onChange={(e) => {
                    setSupplierGroup(e.target.value);
                  }}
                >
                  {supplierType === "" && (
                    <>
                      <option value="">Select</option>
                    </>
                  )}
                  {supplierType === "Expenses" && (
                    <>
                      <option value="">Select Group</option>
                      <option value="Transport">TRANSPORT</option>
                      <option value="MILL">MILL</option>
                      <option value="CAPITAL">CAPITAL</option>
                      <option value="OFFICE EXPENSES">OFFICE EXPENSES</option>
                      <option value="MANPOWER">MANPOWER</option>
                      <option value="RENT">RENT</option>
                      <option value="PACKING">PACKING</option>
                      <option value="OTHERS">OTHERS</option>
                    </>
                  )}
                  {supplierType === "Goods" && (
                    <>
                      <option value="">Select Group</option>
                      <option value="OUTSTATION">OUTSTATION</option>
                      <option value="DANA">DANA</option>
                      <option value="MASALA">MASALA</option>
                    </>
                  )}
                </Form.Control>
              </td>
            )}
          </tr>
          <tr>
            <td>Supplier Account Number</td>
            {supplierModalType === "Add" ? (
              <td>
                <Form.Control
                  id="addSupplier"
                  size="sm"
                  placeholder="Supplier Account Number"
                  onChange={(e) => {
                    setSupplierAcNumber(e.target.value);
                  }}
                />
              </td>
            ) : (
              <td>
                <Form.Control
                  id="addSupplier"
                  size="sm"
                  value={supplierAcNumber}
                  onChange={(e) => {
                    setSupplierAcNumber(e.target.value);
                  }}
                />
              </td>
            )}
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td style={{ borderTop: "None" }}>Supplier Bank Name</td>
            {supplierModalType === "Add" ? (
              <td style={{ borderTop: "None" }}>
                <Form.Control
                  id="addSupplier"
                  size="sm"
                  style={{ marginRight: "20px" }}
                  placeholder="Supplier Bank Name"
                  onChange={(e) => {
                    setSupplierBankName(e.target.value);
                  }}
                />
              </td>
            ) : (
              <td style={{ borderTop: "None" }}>
                <Form.Control
                  id="addSupplier"
                  size="sm"
                  style={{ marginRight: "20px" }}
                  value={supplierBankName}
                  onChange={(e) => {
                    setSupplierBankName(e.target.value);
                  }}
                />
              </td>
            )}
 
            <td style={{ borderTop: "None" }}>Supplier Bank IFSC</td>
            {supplierModalType === "Add" ? (
              <td style={{ borderTop: "None" }}>
                <Form.Control
                  id="addSupplier"
                  size="sm"
                  placeholder="Supplier Bank IFSC"
                  onChange={(e) => {
                    setSupplierBankIfsc(e.target.value);
                  }}
                />
              </td>
            ) : (
              <td style={{ borderTop: "None" }}>
                <Form.Control
                  id="addSupplier"
                  size="sm"
                  value={supplierBankIfsc}
                  onChange={(e) => {
                    setSupplierBankIfsc(e.target.value);
                  }}
                />
              </td>
            )}
          </tr>
          <tr>
            <td style={{ borderTop: "None" }}>Supplier Bank Branch</td>
            {supplierModalType === "Add" ? (
              <td style={{ borderTop: "None" }}>
                <Form.Control
                  id="addSupplier"
                  size="sm"
                  style={{ marginRight: "20px" }}
                  placeholder="Supplier Bank Branch"
                  onChange={(e) => {
                    setSupplierBankBranch(e.target.value);
                  }}
                />
              </td>
            ) : (
              <td style={{ borderTop: "None" }}>
                <Form.Control
                  id="addSupplier"
                  size="sm"
                  style={{ marginRight: "20px" }}
                  value={supplierBankBranch}
                  onChange={(e) => {
                    setSupplierBankBranch(e.target.value);
                  }}
                />
              </td>
            )}
            <td style={{ borderTop: "None" }}>Payment Terms</td>
            {supplierModalType === "Add" ? (
              <td style={{ borderTop: "None" }}>
                <Form.Control
                  id="addSupplier"
                  size="sm"
                  as="select"
                  onChange={(e) => {
                    setPaymentTermsLabel(e.target.value);
                  }}
                >
                  <option value="">Select</option>
                  <option value="Net 15">Net 15</option>
                  <option value="Net 30">Net 30</option>
                  <option value="Net 45">Net 45</option>
                  <option value="Net 60">Net 60</option>
                  <option value="Due on Receipt">Due on Receipt</option>
                </Form.Control>
              </td>
            ) : (
              <td style={{ borderTop: "None" }}>
                <Form.Control
                  id="addSupplier"
                  size="sm"
                  as="select"
                  value={PaymentTermsLabel}
                  onChange={(e) => {
                    setPaymentTermsLabel(e.target.value);
                  }}
                >
                  <option value="">Select</option>
                  <option value="Net 15">Net 15</option>
                  <option value="Net 30">Net 30</option>
                  <option value="Net 45">Net 45</option>
                  <option value="Net 60">Net 60</option>
                  <option value="Due on Receipt">Due on Receipt</option>
                </Form.Control>
              </td>
            )}
          </tr>
          <tr>
            <td style={{ borderTop: "None" }}>Branch ID</td>
            {supplierModalType === "Add" ? (
              <td style={{ borderTop: "None" }}>
                <Form.Control
                  id="addSupplier"
                  size="sm"
                  style={{ marginRight: "20px" }}
                  placeholder="Branch ID"
                  onChange={(e) => {
                    setBranchID(e.target.value);
                  }}
                />
              </td>
            ) : (
              <td style={{ borderTop: "None" }}>
                <Form.Control
                  id="addSupplier"
                  size="sm"
                  style={{ marginRight: "20px" }}
                  value={BranchID}
                  onChange={(e) => {
                    setBranchID(e.target.value);
                  }}
                />
              </td>
            )}
            <td style={{ borderTop: "None" }}>Cancelled Cheque</td>
            {supplierModalType === "Add" ? (
              <td style={{ borderTop: "None" }}>
                <Form.File
                  type="file"
                  size="sm"
                  id="addSupplier"
                  onChange={(e) => {
                    var upload = storageRef.put(e.target?.files[0]);

                    upload.snapshot.ref.getDownloadURL().then((url) => {
                      // << problem
                      setImgUrl(url);
                    });
                  }}
                />
              </td>
            ) : (
              <td style={{ borderTop: "None" }}>
                <Form.File
                  type="file"
                  size="sm"
                  id="addSupplier"
                  onChange={(e) => {
                    var upload = storageRef.put(e.target?.files[0]);

                    upload.snapshot.ref.getDownloadURL().then((url) => {
                      // << problem
                      setImgUrl(url);
                    });
                  }}
                />
              </td>
            )}
          </tr>
          <tr>
            {supplierModalType == "Add" ? (
              <td style={{ borderTop: "None" }}>
                <Form.Check
                  label="Transaction Above 50,00,000"
                  onChange={() => {
                    if (TrAbv50 === "Yes") {
                      setTrAbv50("No");
                    } else {
                      setTrAbv50("Yes");
                    }
                  }}
                  defaultChecked={TrAbv50 === "Yes" ? true : false}
                />
              </td>
            ) : (
              <td style={{ borderTop: "None" }}>
                <Form.Check
                  label="Transaction Above 50,00,000"
                  onChange={() => {
                    if (TrAbv50 === "Yes") {
                      setTrAbv50("No");
                    } else {
                      setTrAbv50("Yes");
                    }
                  }}
                  defaultChecked={TrAbv50 === "Yes" ? true : false}
                />
              </td>
            )}
          </tr>
          <tr>
            <td>GST Treatment </td>
            {supplierModalType === "Add" ? (
              <td>
                <Form.Control
                  as="select"
                  id="addSupplier"
                  size="sm"
                  style={{ marginRight: "20px" }}
                  onChange={(e) => {
                    setGstTreatment(e.target.value);
                  }}
                  defaultValue="select"
                >
                  <option value="select">Select</option>
                  <option value="business_gst">Business Registered</option>
                  <option value="business_none">Unregistered Business</option>
                </Form.Control>
              </td>
            ) : (
              <td>
                <Form.Control
                  id="addSupplier"
                  as="select"
                  size="sm"
                  style={{ marginRight: "20px" }}
                  defaultValue={GstTreatment}
                  onChange={(e) => {
                    setGstTreatment(e.target.value);
                  }}
                >
                  <option value="select">Select</option>
                  <option value="business_gst">Business Registered</option>
                  <option value="business_none">
                    Unregistered Business
                  </option>{" "}
                </Form.Control>
              </td>
            )}

            <td>GST Identification Number (GSTIN)</td>
            {supplierModalType === "Add" ? (
              <td>
                <Form.Control
                  id="addSupplier"
                  size="sm"
                  placeholder="GSTIN"
                  // required
                  onChange={(e) => {
                    setGstin(e.target.value);
                  }}
                  isValid={!Gstin || gstValid.test(Gstin)}
                  isInvalid={Gstin && !gstValid.test(Gstin)}
                />
              </td>
            ) : (
              <td>
                <Form.Control
                  id="addSupplier"
                  size="sm"
                  value={Gstin}
                  // required
                  isValid={!Gstin || gstValid.test(Gstin)}
                  isInvalid={Gstin && !gstValid.test(Gstin)}
                  onChange={(e) => {
                    setGstin(e.target.value);
                  }}
                />
              </td>
            )}
          </tr>
          <tr>
            <td style={{ borderTop: "None" }}>PAN Number </td>
            {supplierModalType === "Add" ? (
              <td style={{ borderTop: "none" }}>
                <Form.Control
                  id="addSupplier"
                  size="sm"
                  style={{ marginRight: "20px" }}
                  placeholder="PAN number"
                  required
                  isValid={panValid.test(PAN)}
                  isInvalid={PAN === "" ? false : !panValid.test(PAN)}
                  onChange={(e) => {
                    setPAN(e.target.value);
                  }}
                />
              </td>
            ) : (
              <td style={{ borderTop: "None" }}>
                <Form.Control
                  id="addSupplier"
                  size="sm"
                  style={{ marginRight: "20px" }}
                  value={PAN}
                  isValid={panValid.test(PAN)}
                  isInvalid={PAN === "" ? false : !panValid.test(PAN)}
                  onChange={(e) => {
                    setPAN(e.target.value);
                  }}
                />
              </td>
            )}
            <td style={{ borderTop: "None" }}>TAN Number </td>
            {supplierModalType === "Add" ? (
              <td style={{ borderTop: "None" }}>
                <Form.Control
                  id="addSupplier"
                  size="sm"
                  placeholder="TAN Number"
                  required
                  isValid={tanValid.test(TAN)}
                  isInvalid={TAN === "" ? false : !tanValid.test(TAN)}
                  onChange={(e) => {
                    setTAN(e.target.value);
                  }}
                />
              </td>
            ) : (
              <td style={{ borderTop: "None" }}>
                <Form.Control
                  id="addSupplier"
                  size="sm"
                  value={TAN}
                  isValid={tanValid.test(TAN)}
                  isInvalid={TAN === "" ? false : !tanValid.test(TAN)}
                  onChange={(e) => {
                    setTAN(e.target.value);
                  }}
                />
              </td>
            )}
          </tr>
          <tr>
            <td style={{ borderTop: "None" }}>FSSAI Number *</td>
            {supplierModalType === "Add" ? (
              <td style={{ borderTop: "None" }}>
                <Form.Control
                  id="addSupplier"
                  size="sm"
                  style={{ marginRight: "20px" }}
                  placeholder="FSSAI Number"
                  required
                  onChange={(e) => {
                    setFSSAI(e.target.value);
                  }}
                  isValid={fssaiValid.test(FSSAI)}
                  isInvalid={FSSAI === "" ? false : !fssaiValid.test(FSSAI)}
                />
              </td>
            ) : (
              <td style={{ borderTop: "None" }}>
                <Form.Control
                  id="addSupplier"
                  size="sm"
                  required
                  style={{ marginRight: "20px" }}
                  value={FSSAI}
                  onChange={(e) => {
                    setFSSAI(e.target.value);
                  }}
                  isValid={fssaiValid.test(FSSAI)}
                  isInvalid={FSSAI === "" ? false : !fssaiValid.test(FSSAI)}
                />
              </td>
            )}
            <td style={{ borderTop: "None" }}>Source Of Supply</td>
            {supplierModalType === "Add" ? (
              <td style={{ borderTop: "None" }}>
                <Form.Control
                  as="select"
                  id="addSupplier"
                  size="sm"
                  style={{ marginRight: "20px" }}
                  onChange={(e) => {
                    setSourceOfSupply(e.target.value);
                  }}
                >
                  <option>Select</option>

                  {state.map((name) => {
                    return <option>{name}</option>;
                  })}
                </Form.Control>
              </td>
            ) : (
              <td style={{ borderTop: "None" }}>
                <Form.Control
                  as="select"
                  id="addSupplier"
                  size="sm"
                  style={{ marginRight: "20px" }}
                  defaultValue={sourceOfSupply}
                  onChange={(e) => {
                    setSourceOfSupply(e.target.value);
                  }}
                >
                  <option>Select</option>
                  {state.map((name) => {
                    return <option>{name}</option>;
                  })}
                </Form.Control>
              </td>
            )}
          </tr>
          {supplierModalType === "Add" ? (
            <>
              <tr>
                <td>Shipping Address</td>
                <td>
                  <Form.Control
                    size="sm"
                    placeholder="Address"
                    onChange={(e) => {
                      setShippingAdd(e.target.value.trim());
                      if (billadd === "Same") {
                        setBillingAdd(e.target.value.trim());
                      }
                    }}
                  />
                </td>
                <td style={{ paddingBottom: "0px" }}>
                  <Form.Control
                    size="sm"
                    placeholder="Street"
                    name="Street"
                    onChange={(e) => {
                      setShippingStreet(e.target.value.trim());
                      if (billadd === "Same") {
                        setBillingStreet(e.target.value.trim());
                      }
                    }}
                  />
                </td>
                <td>
                  <Form.Control
                    size="sm"
                    placeholder="City"
                    name="City"
                    onChange={(e) => {
                      setShippingCity(e.target.value.trim());
                      if (billadd === "Same") {
                        setBillingCity(e.target.value.trim());
                      }
                    }}
                  />
                </td>
              </tr>
              <tr>
                <td style={{ borderTop: "none" }}></td>
                <td style={{ borderTop: "none" }}>
                  <Form.Control
                    size="sm"
                    placeholder="State"
                    name="State"
                    onChange={(e) => {
                      setShippingState(e.target.value.trim());
                      if (billadd === "Same") {
                        setBillingState(e.target.value.trim());
                      }
                    }}
                  />
                </td>
                <td style={{ borderTop: "none" }}>
                  <Form.Control
                    size="sm"
                    placeholder="Pincode"
                    // isInvalid={!pinValid.test(ShippingAdd.Pincode)}
                    onChange={(e) => {
                      setShippingCode(e.target.value.trim());
                      if (billadd === "Same") {
                        setBillingCode(e.target.value.trim());
                      }
                    }}
                    isValid={pinValid.test(Shipping_Code.trim())}
                    isInvalid={
                      Shipping_Code !== ""
                        ? !pinValid.test(Shipping_Code.trim())
                        : false
                    }
                  />
                </td>
              </tr>
              <tr>
                <td style={{ borderTop: "none" }}></td>
                <td style={{ borderTop: "none", width: "200px" }}>
                  <Form.Check
                    label="Same Billing Address"
                    defaultChecked={billadd === "Same" ? true : false}
                    onChange={() => {
                      if (billadd === "Same") {
                        setbilladd("Not Same");
                      } else {
                        setBillingAdd(Shipping_Address);
                        setBillingCity(Shipping_City);
                        setBillingCode(Shipping_Code);
                        setBillingStreet(Shipping_Street);
                        setBillingState(Shipping_State);
                        setbilladd("Same");
                      }
                    }}
                  />
                </td>
              </tr>
              {billadd == "Not Same" ? (
                <>
                  <tr>
                    <td>Billing Address</td>
                    <td>
                      <Form.Control
                        size="sm"
                        placeholder="Address"
                        name="Address"
                        onChange={(e) => {
                          setBillingAdd(e.target.value.trim());
                        }}
                      />
                    </td>
                    <td>
                      <Form.Control
                        size="sm"
                        placeholder="Street"
                        name="Street"
                        onChange={(e) => {
                          setBillingStreet(e.target.value.trim());
                        }}
                      />
                    </td>
                    <td>
                      <Form.Control
                        size="sm"
                        placeholder="City"
                        name="City"
                        onChange={(e) => {
                          setBillingCity(e.target.value.trim());
                        }}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td style={{ borderTop: "none" }}></td>
                    <td style={{ borderTop: "none" }}>
                      <Form.Control
                        size="sm"
                        placeholder="State"
                        name="State"
                        onChange={(e) => {
                          setBillingState(e.target.value);
                        }}
                      />
                    </td>
                    <td style={{ borderTop: "none" }}>
                      <Form.Control
                        size="sm"
                        placeholder="Pincode"
                        name="Pincode"
                        isValid={pinValid.test(Billing_Code)}
                        isInvalid={
                          Billing_Code !== ""
                            ? !pinValid.test(Billing_Code)
                            : false
                        }
                        onChange={(e) => {
                          setBillingCode(e.target.value.trim());
                        }}
                      />
                    </td>
                  </tr>
                </>
              ) : (
                <tr></tr>
              )}
            </>
          ) : (
            <>
              <tr>
                <td>Shipping Address</td>
                <td>
                  <Form.Control
                    size="sm"
                    placeholder={Shipping_Address}
                    value={Shipping_Address}
                    onChange={(e) => {
                      setShippingAdd(e.target.value.trim());
                      if (billadd === "Same") {
                        setBillingAdd(e.target.value.trim());
                      }
                    }}
                  />
                </td>
                <td style={{ paddingBottom: "0px" }}>
                  <Form.Control
                    size="sm"
                    placeholder={Shipping_Street}
                    value={Shipping_Street}
                    name="Street"
                    onChange={(e) => {
                      setShippingStreet(e.target.value.trim());
                      if (billadd === "Same") {
                        setBillingStreet(e.target.value.trim());
                      }
                    }}
                  />
                </td>
                <td>
                  <Form.Control
                    size="sm"
                    placeholder={Shipping_City}
                    value={Shipping_City}
                    name="City"
                    onChange={(e) => {
                      setShippingCity(e.target.value.trim());
                      if (billadd === "Same") {
                        setBillingCity(e.target.value.trim());
                      }
                    }}
                  />
                </td>
              </tr>
              <tr>
                <td style={{ borderTop: "none" }}></td>
                <td style={{ borderTop: "none" }}>
                  <Form.Control
                    size="sm"
                    placeholder={Shipping_State}
                    value={Shipping_State}
                    name="State"
                    onChange={(e) => {
                      setShippingState(e.target.value.trim());
                      if (billadd === "Same") {
                        setBillingState(e.target.value.trim());
                      }
                    }}
                  />
                </td>
                <td style={{ borderTop: "none" }}>
                  <Form.Control
                    size="sm"
                    placeholder={Shipping_Code}
                    // isInvalid={!pinValid.test(ShippingAdd.Pincode)}
                    value={Shipping_Code}
                    onChange={(e) => {
                      setShippingCode(e.target.value.trim());
                      if (billadd === "Same") {
                        setBillingCode(e.target.value.trim());
                      }
                    }}
                    isValid={pinValid.test(Shipping_Code.trim())}
                    isInvalid={
                      Shipping_Code === ""
                        ? false
                        : !pinValid.test(Shipping_Code.trim())
                    }
                  />
                </td>
              </tr>
              <tr>
                <td style={{ borderTop: "none" }}></td>
                <td style={{ borderTop: "none", width: "200px" }}>
                  <Form.Check
                    label="Same Billing Address"
                    defaultChecked={billadd === "Same" ? true : false}
                    onChange={() => {
                      if (billadd === "Same") {
                        setbilladd("Not Same");
                      } else {
                        setBillingAdd(Shipping_Address);
                        setBillingCity(Shipping_City);
                        setBillingCode(Shipping_Code);
                        setBillingStreet(Shipping_Street);
                        setBillingState(Shipping_State);
                        setbilladd("Same");
                      }
                    }}
                  />
                </td>
              </tr>
              {billadd == "Not Same" ? (
                <>
                  <tr>
                    <td>Billing Address</td>
                    <td>
                      <Form.Control
                        size="sm"
                        placeholder={Billing_Address}
                        value={Billing_Address}
                        name="Address"
                        onChange={(e) => {
                          setBillingAdd(e.target.value.trim());
                        }}
                      />
                    </td>
                    <td>
                      <Form.Control
                        size="sm"
                        placeholder={Billing_Street}
                        value={Billing_Street}
                        name="Street"
                        onChange={(e) => {
                          setBillingStreet(e.target.value.trim());
                        }}
                      />
                    </td>
                    <td>
                      <Form.Control
                        size="sm"
                        placeholder={Billing_City}
                        value={Billing_City}
                        name="City"
                        onChange={(e) => {
                          setBillingCity(e.target.value.trim());
                        }}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td style={{ borderTop: "none" }}></td>
                    <td style={{ borderTop: "none" }}>
                      <Form.Control
                        size="sm"
                        placeholder={Billing_State}
                        value={Billing_State}
                        name="State"
                        onChange={(e) => {
                          setBillingState(e.target.value);
                        }}
                      />
                    </td>
                    <td style={{ borderTop: "none" }}>
                      <Form.Control
                        size="sm"
                        placeholder={Billing_Code}
                        value={Billing_Code}
                        name="Pincode"
                        isValid={pinValid.test(Billing_Code)}
                        isInvalid={
                          Billing_Code === ""
                            ? false
                            : !pinValid.test(Billing_Code)
                        }
                        onChange={(e) => {
                          setBillingCode(e.target.value.trim());
                        }}
                      />
                    </td>
                  </tr>
                </>
              ) : (
                <tr></tr>
              )}
            </>
          )}
        </Table>
      </Modal.Body>
      <Modal.Footer>
        <Button
          onClick={() =>
            supplierModalType === "Add" ? createSupplier() : EditSupplier()
          }
        >
          {supplierModalType}
        </Button>
        <Button onClick={props.onHide} variant="secondary">
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AddEditSupplierModal;
